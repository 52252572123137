import React from 'react'
import { Link } from 'react-router-dom'
import errorIcon from '../assets/error-icon-png-16.png';

function PaymentFailed() {
  return (
    <div className="bg-gray-100 h-screen flex justify-center items-center">
      <div className="bg-white p-6  md:mx-auto w-1/4">
        <img className="w-16 h-16 mx-auto my-6" src={errorIcon} alt='error-payment' />
        <div className="text-center">
          <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">Payment Failed!</h3>
          <p className="text-gray-600 my-2">Unfortunately payment was rejected</p>
          {/* <p> Have a great day!  </p> */}
          <div className="py-10 text-center">
            <Link to='/' className="px-12 bg-indigo-600 hover:bg-indigo-500 text-white font-semibold py-3">
              GO BACK
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentFailed