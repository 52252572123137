import { TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { ImSpinner3 } from "react-icons/im";
import Markdown from "react-markdown";
import {
  getInstanceStatus,
  getTextGeneration,
  getStatusLight,
  getInstanceStatusStopper,
} from "../../../services/api";
import { toast } from "react-toastify";
import { Context } from "../../../Context";
import Slider from "../../Slider";
import axiosInstance from "../../../api/axios";
import AddCreditModal from "../../AddCreditModal";

const TextGeneration = ({
  endpoint,
  isActive,
  ins_id,
  model_full_name,
  modelName,
}) => {
  const { setModelStatusLight } = useContext(Context);
  const [userQuery, setUserQuery] = useState("");
  const [llmNewTokens, setLlmNewTokens] = useState(500);
  const [loading, setLoading] = useState(false);
  const [initialising, setInitialising] = useState(false);
  const [response, setResponse] = useState("");
  const [temp, setTemp] = useState(0.5);
  const [top_p, setTop_p] = useState(1);
  const [rep_p, setRep_p] = useState(1);
  const [isOpen, setIsOpen] = useState(false);

  // const position = (llmNewTokens - 50) / (1000 - 50) * 100; // Calculate position based on slider value

  const checkInstance = async () => {
    setResponse("");
    if (!userQuery) {
      toast.warning("User Query Required");
      setLoading(false);
      return;
    }
    setInitialising(true);
    setModelStatusLight("initialising");

    try {
      // Step 1: Check for available credits (GET request)
      const rescheck = await axiosInstance.get(
        `payments/availableCredit/?model-name=${modelName}`
      );

      if (rescheck.data.data.status) {
        // Step 2: Credit is available, proceed to deduct it (POST request)
        const deductCredit = await axiosInstance.post(
          "payments/availableCredit/",
          {
            model_name: modelName,
            add: false,
            sub: true,
            amount: 0,
          }
        );

        if (deductCredit.data.data.status) {
          // toast.warning(deductCredit.data.message);
          // Proceed with the instance status check since credit deduction was successful
          const res = await getInstanceStatus({ instance_id: ins_id });
          console.log(res);

          if (res.status === 200) {
            setLoading(true);
            setInitialising(false);

            try {
              const responseLight = await getStatusLight({
                instance_id: ins_id,
              });
              setModelStatusLight(responseLight.data.status);
            } catch (error) {
              console.log(error.message);
              await refundCredit(); // Refund credit if something goes wrong
            }

            // Trigger handleClick for text generation
            handleClick(deductCredit);
          }
        } else {
          // Failed to deduct credit, stop further execution
          toast.error(deductCredit.data.message);
          setLoading(false);
          setInitialising(false);
          return false; // Stop further execution
        }
      } else {
        // If no credit, show warning and stop the process
        toast.error(rescheck.data.message);
        setIsOpen(true); // Open AddCreditModal when no credit
        setLoading(false);
        setInitialising(false);
        return false; // Stop further execution
      }
    } catch (error) {
      toast.error("Something Went Wrong,Please Try Again!");
      setLoading(false);
      setInitialising(false);
    }
  };

  const handleClick = async (deductCredit) => {
    setResponse("");
    if (!userQuery) {
      toast.warning("User Query Required");
      setLoading(false);
      return;
    }

    try {
      const apiResponse = await getTextGeneration(endpoint, {
        user_input: userQuery,
        max_new_tokens: parseInt(llmNewTokens),
        model_full_name: model_full_name,
      });
      
      setResponse(apiResponse.data.response);
      toast.success(deductCredit.data.message);
      if(deductCredit.data.message === "You have used 3 out of 3 free inferencing credits.") {
        infInstanceStatus()
      }
    } catch (error) {
      console.log(error);
      await refundCredit(); // Refund credit on error
    } finally {
      setLoading(false);
      const res = await getInstanceStatusStopper({ instance_id: ins_id });
      console.log(res);
    }
  };

  // Function to refund credit
  const refundCredit = async () => {
    try {
      await axiosInstance.post("payments/availableCredit/", {
        model_name: modelName,
        add: true,
        sub: false,
        amount: 0,
      });
      console.log("Credit refunded successfully");
    } catch (error) {
      console.log("Failed to refund credit:", error.message);
    }
  };

  const infInstanceStatus = async () => {
    try {
      await axiosInstance.post("payments/updateStatus/", {
        all_free_credits_used: true
      });
      console.log("Credit refunded successfully");
    } catch (error) {
      console.log("Failed to refund credit:", error.message);
    }
  };

  return (
    <div className="flex w-full h-full">
      {/* User Interaction Section */}
      <div className="w-1/2 h-full p-8">
        <p className="text-[16px] font-[600] mb-8">Generate Response</p>
        <p className="text-[15px] font-[500] -mt-6 mb-6">
          Running this model will deduct{" "}
          <span className="text-[#008B16] font-[800]">$0.01</span> / request
          from your balance.{" "}
        </p>
        <TextField
          className="w-full "
          onKeyDown={(e) => {
            if (e.key === "Enter" && e.shiftKey) {
              e.preventDefault();
              setUserQuery((prevValue) => prevValue + "\n");
            } else if (e.key === "Enter") {
              e.preventDefault();
              setUserQuery(e.target.value);
              checkInstance();
            }
          }}
          id="outlined-multiline-static"
          label="User Query"
          multiline
          rows={4}
          value={userQuery}
          onChange={(e) => setUserQuery(e.target.value)}
        />

        <div className="mb-10 mt-6">
          {/* Slider component */}
          <div className="relative mt-8">
            <Slider
              label={"Max New Tokens"}
              value={llmNewTokens}
              setValue={setLlmNewTokens}
              min={100}
              max={2000}
              step={50}
              text={
                "The maximum numbers of tokens to generate, ignoring the number of tokens in the prompt.(One token is roughly 4 characters for standard English text)"
              }
            />
            <Slider
              label={"Temperature"}
              value={temp}
              setValue={setTemp}
              min={0}
              max={2}
              step={0.01}
              text={
                "Controls randomness: Lowering results in less random completions. As the temperature approaches zero, the model will become deterministic and repetitive."
              }
            />
            <Slider
              label={"Top_P"}
              value={top_p}
              setValue={setTop_p}
              min={0}
              max={1}
              step={0.01}
              text={
                "Only tokens within the top top_p% probability are considered."
              }
            />
            <Slider
              label={"Repetition Penalty"}
              value={rep_p}
              setValue={setRep_p}
              min={1}
              max={2}
              step={0.01}
              text={
                "The parameter for repetition penalty. 1.0 means no penalty."
              }
            />
          </div>
        </div>

        {loading ? (
          <button className="loading-button w-[136px] h-[52px] mx-auto">
            <ImSpinner3 className="text-xl mr-1 animate-spin" />
            Running
          </button>
        ) : initialising ? (
          <button className="loading-button w-[136px] h-[52px] mx-auto">
            <ImSpinner3 className="text-xl mr-1 animate-spin" />
            Initializing
          </button>
        ) : (
          <button
            disabled={!isActive}
            className={`${
              isActive ? "classic-button" : "disabled-button"
            } w-[136px] h-[52px] mx-auto `}
            onClick={() => checkInstance()}
          >
            Run
          </button>
        )}
        {initialising && (
          <p className="text-gen-color mt-4 text-[14px] font-[600]">
            The Model instance is initializing, This will take 1 to 2 mins. Once
            the instance is active we will process your request
          </p>
        )}
      </div>
      {/* Response Section */}
      <div className="w-1/2 min-h-[538px] p-8 border-l-2 ">
        <p className="text-[16px] font-[600] mb-8">Response</p>
        {response && (
          <Markdown className="text-base text-gray-700 border border-gray-300 resize-y w-full p-3 bg-white rounded-lg scrollable-markdown scrollbar-width-half">
            {response}
          </Markdown>
        )}
      </div>
      {/* Add Credit Modal */}
      <AddCreditModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default TextGeneration;
