import './Model.css'
import React, { useContext, useEffect, useState } from 'react';
import ModelRun from './ModelRun'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import Footer from '../Footer/Footer'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Container } from '@mui/material';
import { MdKeyboardArrowRight } from 'react-icons/md';
// import { LiaSpinnerSolid } from "react-icons/lia";
import { getModelCategoryCards, getStatusLight } from '../../services/api';
import { BsDot } from "react-icons/bs";
import { FaExternalLinkAlt } from 'react-icons/fa';
import { Tooltip } from 'flowbite-react';
import { toast } from 'react-toastify';
import { Context } from '../../Context';
import Loader from '../../assets/aiModels/QLoader.svg'
import axiosInstance from '../../api/axios';


const ModelInfo = () => {

  const { setPlanId, setModalInfo, setUpdateCredit, setAddCredits, modelStatusLight, setModelStatusLight } = useContext(Context);
  // const PaymentapiURL = process.env.REACT_APP_PAYMENT_SERVICE_URL;
  // const InstanceapiURL = process.env.REACT_APP_MANAGE_INSTANCE_SERVICE_URL;


  const { category, modelName } = useParams()
  const [modelData, setModelData] = useState({})
  const [selectedTab, setSelectedTab] = useState(0)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    window.scrollTo(0, 0);

    (async function populateModelHomeCardList() {
      try {
        const response = await getModelCategoryCards(category)
        const data = response.data.data;
        const processedData = data.map(plan => {
          const features = plan.features;
          const processedPlan = {
            id: plan.id,
            name: plan.model_name,
            price: plan.credits,
            images: features["Image"],
            description: features['Description'],
            instance: features['Instance'],
            instancetype: features['InstanceType'],
            path: features['path'],
            is_active: features['isActive'],
            

            author: features['author'],
            modelPage: features['modelPage'],
            modelPageUrl: features['modelPageUrl'],
            termsUrl: features['termsUrl'],
            docs: features['docs'],

            api_endpoint: features['api_endpoint'],
            isActive: features['isActive'],
            model_full_name: features['model_full_name'],

            ins_id: features['ins_id'] || ''
          };
          return processedPlan;
        });

        // setCardData(processedData.reverse());
        const selectedModel = processedData.filter((item) => item.name === modelName)[0]
        setModelData(selectedModel);

      } catch (error) {
        console.log(error)
      }
    })()
  }, [category, modelName])

  useEffect(() => {
    async function statusLight() {
      // console.log(modelData.ins_id)
      if (modelData.ins_id) {
        try {
          const responseLight = await getStatusLight({ instance_id: modelData.ins_id })
          setModelStatusLight(responseLight.data.status)
        } catch (error) {
          console.log(error.message)
        }
      }
    }
    statusLight()
  }, [modelData, setModelStatusLight])


  const handleRefreshToken = async () => {
    try {
      await axiosInstance.post('/user/refresh_token');
      return true
    } catch (error) {
      console.log('refresh token')
      return false
    }
  };


  const handleOpen = async () => {
    if (modelData.is_active && modelData.notebook_url) {
      sessionStorage.setItem('jupiterUrl', modelData.notebook_url)
      // navigate('/jupyter')
    }
  };

  const checkPlan = async (ele) => {
    try {
      // Make the request to ActivatePlan
      const response = await axiosInstance.post(`/payments/checkPlanCredit`, { plan: ele.id });

      if (response.status === 200) {
        return true; // Activation successful
      } else {
        console.log('Error in ActivatePlan request',);
        toast("Your plan is either already activated or you have less credit.");
        return false; // Activation failed
      }
    } catch (error) {
      console.log('Error in ActivatePlan:', error);
      toast("Your plan is either already activated or you have less credit.");
      setAddCredits(true);
      if (error.response && error.response.status === 401) {
        const refresh = await handleRefreshToken();
        if (refresh) {
          checkPlan(ele)
        }
      } else {
        console.error('Error:', error);
      }
      return false; // Error occurred during activation
    }
  };

  const navigate = useNavigate();
  const launchInstance = async (ele) => {
    setIsLoading(true);
    const requestData = {
      "allocatePublicIp": true,
      "instanceType": "gpu",
      "resourceLevel": ele.instance,
      "modelPath": ele.path,
      "plan": ele.id,
      "plan_name": ele.name,
      "amount": ele.price
    };

    setTimeout(() => {
      setIsLoading(false);
      navigate('/myInstance');
  }, 5000);

    const secondResponse = await axiosInstance.post(`/manage/launch-instance`, requestData);

    if (secondResponse.status === 200) {
      console.log(secondResponse.data.notebookUrl);
      toast(secondResponse.data.message);
      setIsLoading(false);
      return secondResponse.data.notebookUrl; // Return the notebook URL
    } else {
      console.log('Error in launchInstance request');
      setIsLoading(false);
      return null; // Failed to launch instance or no notebook URL
    }
  };

  const activatePlan = async (ele) => {
    try {
      // Make the request to ActivatePlan
      const response = await axiosInstance.post(`/payments/ActivatePlan`, { plan: ele.id });

      if (response.status === 200) {
        setUpdateCredit(true);
        return true; // Activation successful
      } else {
        console.log('Error in ActivatePlan request');
        return false; // Activation failed
      }
    } catch (error) {
      console.log('Error in ActivatePlan:', error);
      if (error.response && error.response.status === 401) {
        const refresh = await handleRefreshToken();
        if (refresh) {
          activatePlan(ele)
        }
      } else {
        console.error('Error:', error);
      }
      return false; // Error occurred during activation
    }
  };

  const handleCPU = async (ele) => {
    setPlanId(ele.id);
    setModalInfo(ele);
    setIsLoading(true); // Indicate loading state
  
    try {
      // Attempt to activate the plan and get the notebook URL
      const notebookUrl = await launchInstance(ele);
  
      // If notebookUrl is returned, open it in a new tab
      if (notebookUrl) {
        window.open(notebookUrl, '_blank');
  
        
        
  
        // Optionally reload UI data
        // loadCard();  // Uncomment if you need to reload card data
      } else {
        throw new Error('Failed to launch instance');
      }
    } catch (error) {
      console.log('Error:', error);
    } finally {
      // Always turn off loading state
      setIsLoading(false);
    }
  };
  

  const deployAiModel = () => {
    handleCPU(modelData)
  }


  return (
    <div className='flex w-full min-h-screen'>
      <Sidebar className='flex-shrink-0 h-full fixed' />
      <div className='flex flex-col w-full'>
        <div className="ml-[90px]"><Navbar className='flex-shrink-0 fixed w-full' /></div>

        {/* page content */}
        <div className="ml-[90px] px-2 pt-[84px] mb-24">
          {/* Your main content goes here */}
          <Container sx={{ maxWidth: { xs: '100%', sm: '1252px' } }}>

            {/* Breadcrumbs */}
            <div className='flex justify-between py-4'>
              <div>
                <div className="flex items-center">
                  <Link to="/modelStudio">
                    <h2 className="text-2xl font-bold text-heading-black hover:text-blue-500">Model Studio</h2>
                  </Link>
                  <MdKeyboardArrowRight className='text-2xl' />
                  <Link to={`/modelStudio/${category}`}>
                    <h2 className="text-2xl font-bold text-heading-black hover:text-blue-500">{category}</h2>
                  </Link>
                  <MdKeyboardArrowRight className='text-2xl' />
                  <h2 className="text-2xl font-bold text-heading-black">{modelName}</h2>
                </div>
                <p className="text-base font-normal text-gray-70 pt-1">Train, Tune , Deploy AI models.</p>
              </div>
            </div>

            {/* Card Wrapper */}
            {!modelData &&
              <div className="min-h-[770px] w-full bg-white px-8 pb-8 shadow-md flex justify-center">
                {/* <LiaSpinnerSolid className='animate-spin text-gen-color text-5xl duration-[10000ms] mt-40' /> */}
                <img src={Loader} className='h-fit animate-spin text-gen-color text-5xl duration-[100000ms] mt-40' alt="" />
              </div>
            }
            {modelData &&
              <>
                {/* Model Info Section */}
                <div className="min-h-[132px] w-full bg-white pl-4 pr-8 mb-4 shadow-md flex flex-wrap justify-between items-center ">
                  {/* Image& Info */}
                  <div className="flex flex-wrap gap-x-12">
                    <img src={modelData.images} className='h-[100px] w-[140px] border rounded-md' alt="" />
                    <div className=''>
                      <span className='text-[21px] font-[600]'>{modelData.name}</span>
                      <div className="flex flex-wrap gap-x-12 mt-4">
                        <div className="">
                          <p className='text-[14px] font-[400]'>Category</p>
                          <p className='text-[14px] font-[500]'>{category}</p>
                        </div>
                        <div className="">
                          <p className='text-[14px] font-[400]'>Visibility</p>
                          <p className='text-[14px] font-[500]'>Public</p>
                        </div>
                        <div className="">
                          <p className='text-[14px] font-[400]'>Status</p>
                          {modelStatusLight === "online" ?
                            <p className='text-[14px] font-[500] flex items-center -mt-1 text-[#008b16]'> <BsDot className='text-3xl -mx-2 ' /> On</p>
                            :
                            modelStatusLight === "initialising" ?
                              <p className='text-[14px] font-[500] flex items-center -mt-1 text-blue-400'> <BsDot className='text-3xl -mx-2 ' /> Initializing</p>
                              :
                              <p className='text-[14px] font-[500] flex items-center -mt-1 text-[#ff2727]'> <BsDot className='text-3xl -mx-2 ' /> Off</p>
                          }
                        </div>
                        <div className="">
                          <p className='text-[14px] font-[400]'>Links</p>
                          {modelData.docs ?
                            <a href={modelData.docs[0][1]} target='blank' ><span className='text-[14px] font-[500] hover:text-blue-500'><u>Paper</u></span></a>
                            :
                            <span className='text-[14px] font-[500] hover:text-blue-500'><u>Paper</u></span>
                          }
                          <span className='text-[14px] font-[500]'> and </span>
                          {modelData.termsUrl ?
                            <a href={modelData.termsUrl} target='blank' ><span className='text-[14px] font-[500] hover:text-blue-500'><u>License</u></span></a>
                            :
                            <span className='text-[14px] font-[500] hover:text-blue-500'><u>License</u></span>
                          }
                        </div>
                      </div>
                    </div>
                  </div>


                  {/* Launch Notebook */}

                  {modelData.notebook_url ?
                    <button className='classic-button h-[45px] w-[90px] text-[18px] font-[600] '
                      onClick={() => handleOpen(modelData)} >
                      Open
                    </button>
                    :
                    <>
                      {isLoading ?
                        <button
                          className='flex items-center bg-[#8F27C11A] border-[#8F27C10D] text-[#8F27C1]  rounded-[12px] bordertext-lg font-semibold px-[30px] py-[10px] cursor-not-allowed'
                        >
                          <svg className="animate-spin h-5 w-5 mr-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.96 7.96 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zm5.195 2.647A8.034 8.034 0 0112 20c4.418 0 8-3.582 8-8h-4c0 2.761-2.239 5-5 5a4.998 4.998 0 01-4.805-3.653z"></path></svg>
                          <span>Launching...</span>
                        </button>
                        :
                        <Tooltip content="Launch and fine-tune the model on JupyterLab" >
                          <button className='classic-button h-[45px] w-[181px] text-[18px] font-[600] '
                            onClick={() => setShowConfirmationModal(true)}>
                            Fine Tune
                          </button>
                        </Tooltip>
                      }
                    </>

                  }



                </div>


                {/* Tab Titles */}
                <div className="min-h-[58px] px-8 w-full bg-white shadow-md flex gap-x-6 items-end justify-start">
                  <span onClick={() => setSelectedTab(0)}
                    className={`min-w-fit text-[18px] font-[700] cursor-pointer pb-3 px-1 border-b-[3px]  border-white ${selectedTab === 0 && 'text-gen-color  border-b-gen-color'} `}>
                    Run Model
                  </span>
                  <span onClick={() => setSelectedTab(1)}
                    className={`min-w-fit text-[18px] font-[700] cursor-pointer pb-3 px-1 border-b-[3px]  border-white ${selectedTab === 1 && 'text-gen-color  border-b-gen-color'} `}>
                    Description
                  </span>
                </div>
                <hr />
                {/* Tab Data */}
                {selectedTab === 0 ?
                  // Model Tab
                  <ModelRun category={category} modelName={modelName} endpoint={modelData?.api_endpoint} isActive={modelData?.isActive} ins_id={modelData?.ins_id} model_full_name={modelData?.model_full_name} />
                  :
                  // Description Tab
                  <div className="min-h-[538px] w-full bg-white shadow-md p-8 ">
                    <p className='text-justify text-[16px] font-[400]'>{modelData.description}</p>
                    <div className='mt-3'>
                      {/* references */}
                      <p className='text-[16px] font-[600] pb-1'>References:</p>
                      {/* author */}
                      <span className='text-[16px] font-[500]'>Author: </span><span>{modelData.author}</span><br />
                      {/* model page */}
                      <span className='text-[16px] font-[500]'>Model Page: </span><a className={`${modelData.modelPageUrl && 'hover:text-blue-500'}`} target='blank' href={modelData.modelPageUrl}>{modelData.modelPage}</a>
                      {/* model card */}
                      {modelData.modelCardUrl &&
                        <div className="flex items-center"><br /><span className='text-[16px] font-[500]'>Model Card &nbsp;</span><a className='text-blue-500 text-sm' target='blank' href={modelData.modelCardUrl}><FaExternalLinkAlt /></a></div>}
                      {/* Resources and Technical Documentation*/}
                      {modelData.docs && <p className='text-[16px] font-[500]'>Resources and Technical Documentation:</p>}
                      {modelData.docs &&
                        modelData.docs.map((item, key) => {
                          return (
                            <>
                              {item[1] ?
                                <div className="flex items-center ">
                                  <span className=' pl-2' >{key + 1}.</span>
                                  <a href={item[1]} target='blank' key={key} className='pl-1 text-md text-gray-800 hover:text-blue-500 cursor-pointer'>{item[0]}</a>
                                </div>
                                :
                                <div>
                                  <span className=' pl-2' >{key + 1}.</span>
                                  <a href={item.url} target='blank' key={key} className='pl-2 text-md text-gray-800 hover:text-blue-500 cursor-pointer'>{item.title}</a>
                                </div>
                              }
                            </>
                          )
                        })
                      }
                      {/* Terms Of Use */}
                      <div className="flex items-center"><span className='text-[16px] font-[600]'>Terms of Use &nbsp;</span><a className='text-blue-500 text-sm' target='blank' href={modelData.termsUrl}><FaExternalLinkAlt /></a></div>
                      {/* Disclaimer */}
                      <p className='text-[16px] font-[400]'>Third-Party Community Consideration </p>
                      <p className='text-[16px] font-[400]'> This model is not owned or developed by Qubrid AI. This model has been developed and built to a third-party's requirements for this application and use case.</p>
                    </div>
                  </div>

                }





                {/* launch notebook modal */}
                {showConfirmationModal && (
                  <div className="fixed z-[9999] inset-0 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                      <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                      </div>
                      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                      <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                        <div>
                          <div className="mt-3 text-center sm:mt-5">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Are you sure you want to launch the notebook?</h3>
                            <p className='text-red-500'>$2/hr will be deducted.</p>
                          </div>
                          <div className="mt-5 sm:mt-6 flex justify-center">
                            <button
                              type="button"
                              // className="inline-flex justify-center py-2 px-4 border border-transparent shadow-md text-sm font-medium rounded-md text-white bg-sky-500 hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
                              className='general-button '
                              onClick={() => {
                                setShowConfirmationModal(false);
                                deployAiModel();
                              }}
                            >
                              Yes, Launch
                            </button>
                            <button
                              type="button"
                              // className="inline-flex justify-center py-2 px-4 border border-transparent shadow-md text-sm font-medium rounded-md text-white bg-red-400 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 ml-3"
                              className='cancel-button  ml-5'
                              onClick={() => setShowConfirmationModal(false)}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            }

          </Container>
        </div>
        <div className={`ml-[90px]`}><Footer /></div>
      </div >
    </div >
  )
}

export default ModelInfo