import React, { useContext } from 'react'
import './EnvForm.css';
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';
import { Context } from '../../Context';
import Footer from '../Footer/Footer';
import '../../App.css';
import { Container } from '@mui/material';
import CreateAiComputeLeft from './CreateAiComputeLeft';

function EnvironmentForm() {
    const { open } = useContext(Context);
    return (
        <>
            <div className='flex w-full min-h-screen'>
                <Sidebar className='flex-shrink-0 h-full fixed' />
                <div className='flex flex-col w-full'>
                    <div className={`${open ? "ml-[228px]" : "ml-[90px]"}`}>
                        <Navbar />
                    </div>
                    {/* Page Content */}
                    <div className={`${open ? "ml-[228px]" : "ml-[90px]"} px-8 pt-16 mb-24`}>
                        <Container>
                            <CreateAiComputeLeft />
                        </Container>
                    </div>
                    {/* Footer */}
                    <div className={`${open ? "ml-[228px]" : "ml-[90px]"}`}>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    )
}

export default EnvironmentForm
