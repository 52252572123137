import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { FaRegTrashAlt } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import loader from "../../assets/ui_img/loader.svg"
import './index.css';
import { Context } from '../../Context';

function Overview() {
    const BussinessapiURL = process.env.REACT_APP_BUSINESS_SERVICE_URL;
    const [addTeam, setAddTeam] = useState(false);
    const token = localStorage.getItem("token");
    const [loading, setLoading] = useState(false);
    const { adminInfo } = useContext(Context);
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        role: '',
        credits: ''
    });
    const [user, setUser] = useState();
    const [getTeamMemberRes, setGetTeamMemberRes] = useState();

    const [selectedEmail, setSelectedEmail] = useState('')
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const addTeamMember = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${BussinessapiURL}/team-members`, JSON.stringify(formData), {
                headers: {
                    'x-access-token': token,
                    'Content-Type': 'application/json',
                }
            });

            if (response.status === 200) {
                setLoading(false)
                setAddTeam(false)
                getTeamMember()
                toast("Added Team Member");
                return true;
            } else {
                setLoading(false)
                setAddTeam(false)
                toast.error(response.data.message);
                return false;
            }
        } catch (error) {
            setLoading(false);
            setAddTeam(false)
            toast(error.response.data.message)
            return false;
        }
    };
    const getTeamMember = () => {
        const token = localStorage.getItem("token");
        const headers = {
            'x-access-token': token,
            'Content-Type': 'application/json',
        };
        axios.get(`${BussinessapiURL}/get-team-members`, { headers })
            .then(response => {
                setGetTeamMemberRes(response?.data)
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }
    useEffect(() => {
        const getUser = () => {
            const token = localStorage.getItem("token");
            const headers = {
                'x-access-token': token,
                'Content-Type': 'application/json',
            };
            axios.get(`${BussinessapiURL}/get-company`, { headers })
                .then(response => {

                    setUser(response?.data)
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
        getUser();
        const getTeamMember = () => {
            const token = localStorage.getItem("token");
            const headers = {
                'x-access-token': token,
                'Content-Type': 'application/json',
            };
            axios.get(`${BussinessapiURL}/get-team-members`, { headers })
                .then(response => {
                    console.log(response)
                    setGetTeamMemberRes(response?.data)
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
        getTeamMember();
    }, [BussinessapiURL]);


    const deleteTeamMember = async (email) => {
        const token = localStorage.getItem("token");
        try {
            const headers = {
                "x-access-token": token,
                "Content-Type": "application/json",
            };

            const data = {
                email: email
            };
            const response = await axios.post(`${BussinessapiURL}/delete-team-member`, data, { headers });
            toast(response.data.message);
            getTeamMember();
        } catch (error) {
            toast(error.response.data.message)
        }
    }


    return (
        <>
            <div className="bg-light-gray-1 p-5 rounded w-full">
                <div className="flex space-x-3">
                    <div className="w-96">
                        <h2 className="text-sm font-normal text-secondry-clr text-opacity-80">Organization Name</h2>
                        <span className="text-sm font-medium text-secondry-clr">{user?.company_name}</span>
                    </div>
                    <div className="w-96">
                        <h2 className="text-sm font-normal text-secondry-clr text-opacity-80">Admin Email Id</h2>
                        <span className="text-sm font-medium text-secondry-clr">{adminInfo?.email}</span>
                    </div>
                    <div className="w-96">
                        <h2 className="text-sm font-normal text-secondry-clr text-opacity-80">Total Members</h2>
                        <span className="text-sm font-medium text-secondry-clr">{getTeamMemberRes?.total_team_members}</span>
                    </div>
                </div>

                <div className="flex space-x-3 mt-7">
                    <div className="w-96">
                        <h2 className="text-sm font-normal text-secondry-clr text-opacity-80">Phone No</h2>
                        <span className="text-sm font-medium text-secondry-clr">{user?.phone_number}</span>
                    </div>
                    <div className="w-96">
                        <h2 className="text-sm font-normal text-secondry-clr text-opacity-80">Address</h2>
                        <span className="text-sm font-medium text-secondry-clr">{user?.street_address}, {user?.city}, {user?.state}, {user?.postcode} </span>
                    </div>
                </div>
            </div>

            <div className='flex justify-between py-5'>
                <div className='text-base font-semibold py-4 text-secondry-clr'>
                    Team Member List ({getTeamMemberRes?.total_team_members})
                </div>
                <button onClick={() => setAddTeam(true)} className='py-4 px-6 text-secondry-clr text-opacity-80 border border-secondry-clr border-opacity-80 rounded-xl font-semibold text-lg'>
                    Add Team Member
                </button>
            </div>

            <div className="">
                <table className="min-w-full">
                    <thead className='bg-table-bg-head-clr'>
                        <tr>
                            <th className="py-2 px-4 text-left text-sm font-semibold text-secondry-clr">Name</th>
                            <th className="py-2 px-4 text-left  text-sm font-semibold text-secondry-clr">Email Id</th>
                            <th className="py-2 px-4 text-left  text-sm font-semibold text-secondry-clr">Role</th>
                            <th className="py-2 px-4 text-left  text-sm font-semibold text-secondry-clr">Credit</th>
                            <th className="py-2 px-4 text-left  text-sm font-semibold text-secondry-clr"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            getTeamMemberRes?.team_members?.map((ele, i) =>
                                <tr key={i} className={i % 2 !== 0 && "bg-page-bg-color bg-opacity-50"}>
                                    <td className="py-2 px-4  text-sm font-normal text-secondry-clr">{ele?.username}</td>
                                    <td className="py-2 px-4  text-sm font-normal text-secondry-clr">{ele?.email}</td>
                                    <td className="py-2 px-4  text-sm font-normal text-secondry-clr">{ele?.role}</td>
                                    <td className="py-2 px-4  text-sm font-normal text-secondry-clr">${ele?.credits_left}</td>
                                    <td className="py-2 px-4  text-sm font-normal text-secondry-clr">
                                        <button onClick={() => {
                                            setSelectedEmail(ele?.email)
                                            setShowConfirmationModal(true)
                                        }}>
                                            <FaRegTrashAlt className='h-5 w-5' />
                                        </button>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>

            {/* launch notebook modal */}
            {showConfirmationModal && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div>
                                <div className="mt-3 text-center sm:mt-5">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Are you sure you want to Delete?</h3>

                                </div>
                                <div className="mt-5 sm:mt-6 flex justify-center">
                                    <button
                                        type="button"
                                        // className="inline-flex justify-center py-2 px-4 border border-transparent shadow-md text-sm font-medium rounded-md text-white bg-red-400 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 "
                                        className='cancel-button'
                                        onClick={() => {
                                            setShowConfirmationModal(false);
                                            deleteTeamMember(selectedEmail)
                                        }}
                                    >
                                        Yes, Delete
                                    </button>
                                    <button
                                        type="button"
                                        // className="inline-flex justify-center py-2 px-4 border border-transparent shadow-md text-sm font-medium rounded-md text-white bg-sky-500 hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 ml-3"
                                        className='general-button ml-5'
                                        onClick={() => setShowConfirmationModal(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {loading && (
                <div>
                    <div className="absolute inset-0 flex items-center justify-center z-[200]">
                        <img src={loader} alt="img_loader" className="spinner" />
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </div>
            )}

            {
                addTeam ? (
                    <>
                        <div
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                        >
                            <div className="relative my-6 mx-auto w-1/2">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                        <div>
                                            <h5 className="text-xl font-semibold">
                                                Add Team Member
                                            </h5>
                                        </div>

                                        <button
                                            className=" background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => setAddTeam(false)}
                                        >
                                            <MdClose className='w-5 h-5' />
                                        </button>

                                    </div>
                                    {/*body*/}
                                    <div className="relative p-6 flex-auto">
                                        <div className='flex justify-between gap-10'>
                                            <TextField
                                                fullWidth
                                                id="outlined-basic"
                                                label="Name"
                                                variant="outlined"
                                                name="username"
                                                value={formData.username}
                                                onChange={handleChange}
                                                className='focus:border-0'
                                            />
                                            <TextField
                                                fullWidth
                                                id="outlined-basic"
                                                label="Email"
                                                variant="outlined"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='flex justify-between gap-10 mt-8'>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={formData.role}
                                                    label="Role"
                                                    name="role"
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value="Developer">Developer</MenuItem>
                                                    <MenuItem value="Data Manager">Data Managaer</MenuItem>
                                                    <MenuItem value="AI ML Developer">AI ML Developer</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <TextField fullWidth
                                                id="outlined-basic"
                                                label="Credit"
                                                variant="outlined"
                                                name="credits"
                                                value={formData.credits}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    {/* Footer */}
                                    <div className="flex items-center justify-end p-6 rounded-b gap-x-5">
                                        <button onClick={() => setAddTeam(false)} className='py-4 px-6 text-secondry-clr text-opacity-80 border border-secondry-clr border-opacity-80 rounded-xl font-semibold text-lg'>
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className={`text-tab-active-color bg-tab-active-color bg-opacity-20 border-opacity-10 text-lg font-semibold  px-12 py-4 rounded-xl`}
                                            onClick={addTeamMember}
                                        >
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null
            }


        </>
    )
}

export default Overview