import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { MdOutlineDeleteOutline, MdVisibility, MdOutlineFileDownload, MdSync, MdContentCopy, MdOutlineTextSnippet } from 'react-icons/md';
import loader from "../../assets/ui_img/loader.svg";
import { MdClose } from 'react-icons/md';
import './loader.css';
import { Context } from "../../Context";
import { toast } from "react-toastify";

const ModalTab = () => {
    const [openTab, setOpenTab] = useState(1);
    const [deleteResourceModal, setDeleteResourceModal] = useState(false);
    const { isLoading, modalInfo, setFreeShowModal, setDeleteSuccess } = useContext(Context);
    const InstanceapiURL = process.env.REACT_APP_MANAGE_INSTANCE_SERVICE_URL;
    const PaymentapiURL = process.env.REACT_APP_PAYMENT_SERVICE_URL;
    const deleteInstance = () => {
        const token = localStorage.getItem("token");

        const requestData = {
            "plan_id": modalInfo.id,

        };

        const headers = {
            'x-access-token': token
        };

        axios.post(`${InstanceapiURL}/delete-instance`, requestData, { headers })
            .then(response => {

                toast(response?.data?.message)
                setDeleteResourceModal(false);
                setFreeShowModal(false);
                setDeleteSuccess(true);
                const cpuid = response.data.instance;
                // const cpuid = localStorage.getItem("CPU");
                // If the instance is stopped successfully, make a second API call
                axios.post(`${PaymentapiURL}/InActivatePlan`, { plan: cpuid }, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                })
                    .then(secondResponse => {
                        console.log(secondResponse.data);
                        // Handle the response from the second API call
                    })
                    .catch(secondError => {
                        console.error('Second API call error', secondError);
                        toast('Something went wrong with the second API call');
                    });
            })
            .catch(error => {
                console.error('error', error)
                toast(error.response.data.message);
            });


    }
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (isLoading) {
                const message = "Are you sure you want to leave?";
                event.returnValue = message; // Standard for most browsers
                return message; // For some older browsers
            }
        };

        // Set up the event listener
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isLoading]);

    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full">
                    <ul
                        className="flex list-none px-6 border-b space-x-8"
                        role="tablist"
                    >
                        <li className={`${openTab === 1 ? "text-tab-active-color border-b-4 border-tab-active-color" : "text-gray-60"} text-base font-bold px-1.5 pb-3`}>
                            <a
                                onClick={e => {
                                    e.preventDefault();
                                    setOpenTab(1);
                                }}

                                data-toggle="tab"
                                href="#link1"
                                role="tablist"
                            >
                                Overview
                            </a>
                        </li>

                        <li className={`${openTab === 2 ? "text-tab-active-color border-b-4 border-tab-active-color" : "text-gray-60"} text-base font-bold px-1.5 pb-3`}>
                            <a
                                onClick={e => {
                                    e.preventDefault();
                                    setOpenTab(2);
                                }}
                                data-toggle="tab"
                                href="#link2"
                                role="tablist"
                            >
                                Manage

                            </a>
                        </li>
                    </ul>

                    <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
                        <div className="px-4 py-5 flex-auto">
                            <div className="tab-content tab-space">
                                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                                    {
                                        modalInfo?.path ? <div className="bg-create-new-btn-color p-4 rounded w-full">
                                            <div className="flex space-x-3">
                                                <div className="w-64">
                                                    <h2 className="text-sm font-normal">Languages</h2>
                                                    <span className="text-sm font-medium"></span>
                                                </div>
                                                <div className="w-64">
                                                    <h2 className="text-sm font-normal">Storage</h2>
                                                    <span className="text-sm font-medium"></span>
                                                </div>
                                                <div className="w-64">
                                                    <h2 className="text-sm font-normal">Framework</h2>
                                                    <span className="text-sm font-medium"></span>
                                                </div>
                                            </div>
                                        </div> :
                                            <div className="bg-create-new-btn-color p-4 rounded w-full">
                                                <div className="flex space-x-3">
                                                    <div className="w-64">
                                                        <h2 className="text-sm font-normal">Languages</h2>
                                                        <span className="text-sm font-medium">{modalInfo?.languages}</span>
                                                    </div>
                                                    <div className="w-64">
                                                        <h2 className="text-sm font-normal">Storage</h2>
                                                        <span className="text-sm font-medium">{modalInfo?.hardware?.Storage}</span>
                                                    </div>
                                                    <div className="w-64">
                                                        <h2 className="text-sm font-normal">Framework</h2>
                                                        <span className="text-sm font-medium">Scikit-learn, PyTorch, Tensorflow, Jupyter</span>
                                                    </div>
                                                </div>

                                                <div className="flex space-x-3 mt-4">
                                                    <div className="w-64">
                                                        <h2 className="text-sm font-normal">Network</h2>
                                                        <span className="text-sm font-medium">{modalInfo?.hardware?.Network}</span>
                                                    </div>
                                                    {
                                                        modalInfo?.hardware?.CPUs ?
                                                            <div className="w-64">
                                                                <h2 className="text-sm font-normal">CPU</h2>
                                                                <span className="text-sm font-medium">{modalInfo?.hardware?.CPUs}</span>
                                                            </div>
                                                            : ''
                                                    }
                                                    {
                                                        modalInfo?.hardware?.GPUs ?
                                                            <div className="w-64">
                                                                <h2 className="text-sm font-normal">GPU</h2>
                                                                <span className="text-sm font-medium">{modalInfo?.hardware?.GPUs}</span>
                                                            </div>
                                                            : ''
                                                    }
                                                </div>
                                            </div>
                                    }
                                    <div className="flex items-center justify-center py-10">
                                        {
                                            isLoading ? (<div><img src={loader} alt="img_loader" className="spinner" /> <span className="text-sm font-medium text-stop-color -ml-20 mt-2.5">Wait {modalInfo?.Instance?.split('')[0] === 'n' ? '10' : '3'} Min Have a Cup Of Coffee</span></div>) : (null)
                                        }
                                        {/* {
                                            stopInstanceLoader ? (<div><img src={loader} alt="img_loader" className="spinner" /> <span className="text-sm font-medium text-stop-color -ml-20 mt-2.5">Wait 2 Min Successfully Stopped Instance</span></div>) : (null)
                                        } */}
                                    </div>

                                </div>


                                <div className={`${openTab === 2 ? "block" : "hidden"} h-380 overflow-y-auto`} id="link2">
                                    <div className="flex justify-between p-2.5 bg-modal-bg-color rounded">
                                        <div>
                                            <h2 className="text-lg font-medium">Delete Resource</h2>
                                            <span className="text-sm font-normal">This will permanently delete the resource and attached disk.</span>
                                        </div>
                                        <div className="flex space-x-2 cursor-pointer border border-card-li-color py-3 px-7 rounded-xl" onClick={() => setDeleteResourceModal(true)}>
                                            <MdOutlineDeleteOutline className="w-5 h-5 mt-1" />
                                            <h2 className="text-lg font-medium">Delete</h2>
                                        </div>
                                    </div>
                                    <div className="blur">
                                        <div className="flex justify-between p-2.5 my-2.5 bg-modal-bg-color  rounded">
                                            <div>
                                                <h2 className="text-lg font-medium">Visibility</h2>
                                                <span className="text-sm font-normal">Control who can access the running resource.</span>
                                            </div>
                                            <div className="flex space-x-2 cursor-pointer border border-card-li-color py-3 px-7 rounded-full">
                                                <MdVisibility className="w-5 h-5 mt-1" />
                                                <h2 className="text-lg font-medium">View Access</h2>
                                            </div>
                                        </div>

                                        <div className="flex justify-between p-2.5 bg-modal-bg-color  rounded">
                                            <div>
                                                <h2 className="text-lg font-medium">Download Recipe</h2>
                                                <span className="text-sm font-normal">A recipe contains the exact configuration to recreate a resource. </span>
                                            </div>
                                            <div className="flex space-x-2 cursor-pointer border border-card-li-color py-3 px-7 rounded-full">
                                                <MdOutlineFileDownload className="w-5 h-5 mt-1" />
                                                <h2 className="text-lg font-medium">Download Recipe</h2>
                                            </div>
                                        </div>


                                        <div className="p-2.5 bg-modal-bg-color my-2.5 rounded">
                                            <div className="flex justify-between ">
                                                <div>
                                                    <h2 className="text-lg font-medium">API Token</h2>
                                                    <span className="text-sm font-normal">API Token for the resource</span>
                                                </div>
                                                <div className="flex space-x-2 cursor-pointer border border-card-li-color py-3 px-7 rounded-full">
                                                    <MdSync className="w-5 h-5 mt-1" />
                                                    <h2 className="text-lg font-medium">Replace Key</h2>
                                                </div>
                                            </div>
                                            <div className="relative mt-2">
                                                <input type="password" id="input-group-1" className="bg-modal-bg-color border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="*******************" />
                                                <div className="absolute inset-y-0 right-0 flex items-center pr-3.5 pointer-events-none">
                                                    <MdVisibility className="w-5 h-5" />
                                                </div>
                                                <div className="absolute inset-y-0 right-0 flex items-center pr-10 pointer-events-none">
                                                    <MdContentCopy className="w-5 h-5" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="my-2.5 p-2.5 bg-modal-bg-color rounded">
                                            <div className="flex justify-between ">
                                                <div>
                                                    <h2 className="text-lg font-medium">Share Resource</h2>
                                                    <span className="text-sm font-normal">Allow other users in your organization to clone this resource by publishing it as a template.</span>
                                                </div>
                                                <div className="flex space-x-2 cursor-pointer border border-card-li-color py-3 px-7 rounded-full">
                                                    <MdOutlineTextSnippet className="w-5 h-5 mt-1" />
                                                    <h2 className="text-lg font-medium">Update Template</h2>
                                                </div>
                                            </div>
                                            <div>
                                                <span className="text-sm font-normal">Copy & Share link</span>
                                            </div>
                                            <div className="relative my-2">
                                                <input type="text" id="input-group-2" className="bg-modal-bg-color border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="*******************" />
                                                <div className="absolute inset-y-0 right-0 flex items-center pr-3.5 pointer-events-none">
                                                    <MdVisibility className="w-5 h-5" />
                                                </div>
                                                <div className="absolute inset-y-0 right-0 flex items-center pr-10 pointer-events-none">
                                                    <MdContentCopy className="w-5 h-5" />
                                                </div>
                                            </div>
                                            <div className="relative">
                                                <input type="text" id="input-group-3" className="bg-modal-bg-color border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="*******************" />
                                                <div className="absolute inset-y-0 right-0 flex items-center pr-3.5 pointer-events-none">
                                                    <MdVisibility className="w-5 h-5" />
                                                </div>
                                                <div className="absolute inset-y-0 right-0 flex items-center pr-10 pointer-events-none">
                                                    <MdContentCopy className="w-5 h-5" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="my-2.5 p-2.5 bg-modal-bg-color  rounded">
                                            <div className="flex justify-between ">
                                                <div>
                                                    <h2 className="text-lg font-medium">Clone Resource</h2>
                                                    <span className="text-sm font-normal">Create a new resource with the same settings as this one.</span>
                                                </div>
                                            </div>

                                            <div className="flex space-x-5 my-2">
                                                <div className="h-20 w-64 flex justify-center items-center bg-modal-bg-color">
                                                    <span className="font-medium text-sm">Clone as a Python Server</span>
                                                </div>
                                                <div className="h-20 w-64 flex justify-center items-center bg-modal-bg-color">
                                                    <span className="font-medium text-sm">Clone as a Deployment</span>
                                                </div>
                                                <div className="h-20 w-64 flex justify-center items-center bg-modal-bg-color">
                                                    <span className="font-medium text-sm">Clone as a job</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Free Modal */}
            {deleteResourceModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative my-6 mx-auto">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-6 border-b border-solid border-slate-200 rounded-t">
                                    <div>
                                        <h5 className="text-xl font-semibold">
                                            Confirmation
                                        </h5>
                                    </div>

                                    <button
                                        className=" background-transparent font-bold uppercase pt-1.5 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setDeleteResourceModal(false)}
                                    >
                                        <MdClose className='w-5 h-5' />
                                    </button>

                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className="text-xl font-medium text-black">
                                        <span>Are you sure you want to delete this Jupyter Server?</span>
                                    </div>
                                </div>
                                {/* Footer */}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b gap-x-3">
                                    <button onClick={() => setDeleteResourceModal(false)} className='py-4 px-6 text-secondry-clr text-opacity-80 border border-secondry-clr border-opacity-80 rounded-xl font-semibold text-lg'>
                                        No
                                    </button>
                                    <button
                                        type="button"
                                        className={`text-tab-active-color bg-tab-active-color bg-opacity-20 border-opacity-10 text-lg font-semibold  px-12 py-4 rounded-xl`}
                                        onClick={deleteInstance}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );
};


export default ModalTab;
