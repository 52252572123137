import React, { useContext, useState } from 'react';
import axios from "axios";
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';
import { Context } from '../../Context';
import Footer from '../Footer/Footer';
import EnvironmentForm from '../EnvironmentForm/EnvironmentForm';
import { MdClose, MdKeyboardArrowRight } from 'react-icons/md';
import LaunchCpuGpu from '../RunAIMlCpuGpu/LaunchCpuGpu';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container } from '@mui/material';

function AiCompute() {
    const { open, addCredits, setAddCredits } = useContext(Context);
    const [customShowModal, customSetShowModal] = useState(false);
    const PaymentapiURL = process.env.REACT_APP_PAYMENT_SERVICE_URL;

    const addCredit = (credit) => {
        const token = localStorage.getItem("token");
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        };

        const data = {
            amount: credit,
        };

        axios.post(`${PaymentapiURL}/create-checkout-session/`, data, { headers })
            .then(response => {
                window.open(response.data.msg.checkout_url, "_blank");
                setAddCredits(false);
            })
            .catch(error => {
                console.error('Error:', error);
                toast(error.response.data.message);
            });
    }

    return (
        <>
            <div className='flex w-full min-h-screen'>
                <Sidebar className='flex-shrink-0 h-full fixed' />
                <div className='flex flex-col w-full'>
                    <div className={`${open ? "ml-[228px]" : "ml-[90px]"}`}>
                        <Navbar />
                    </div>
                    {/* Page Content */}
                    <div className={`${open ? "ml-[228px]" : "ml-[90px]"} px-1 pt-24`}>
                        {/* Your main content goes here */}
                        <Container>
                            <div className='flex justify-between py-4'>
                                <div>
                                    <div className='flex items-center mb-1'>
                                        <Link to='/'>
                                            <h2 className="text-2xl font-bold text-heading-black">AI Hub</h2>
                                        </Link>
                                        <MdKeyboardArrowRight className='text-2xl' />
                                        {/* <NavigateNextIcon className='mt-2' /> */}
                                        <span className='text-2xl font-bold text-heading-black'>AI Compute</span>
                                    </div>
                                    <span className="text-[16px]  text-gray-70">Select infrastructure and a programming environment.</span>
                                </div>
                                {/* className='px-[32px] py-[18px] bg-[#8F27C10D] rounded-[12px] cursor-pointer' */}
                                {/* className='text-tab-active-color text-base font-semibold' */}
                                <div
                                    className='h-[52px] flex items-center hover:bg-[#8F27C11A] hover:border-[#8F27C10D] hover:text-[#8F27C1]  text-[#10001ACC] rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-[32px]  cursor-pointer'
                                    onClick={() => setAddCredits(true)}>
                                    <span>Add Credits</span>
                                </div>
                            </div>
                            <LaunchCpuGpu />
                        </Container>
                    </div>
                    <div className={`${open ? "ml-[228px]" : "ml-[90px]"}`}>
                        <Footer />
                    </div>
                </div>
            </div>

            {/* Custum Modal */}
            {customShowModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-6 border-b border-solid border-slate-200 rounded-t">
                                    <div>
                                        <h5 className="text-base font-bold text-heading-black">
                                            Create New Program
                                        </h5>
                                        <p className='text-sm font-semibold text-gray-70'>Choose any one AI Package</p>
                                    </div>
                                    <button
                                        className=" background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => customSetShowModal(false)}
                                    >
                                        <MdClose className='w-5 h-5 mt-1' />
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <EnvironmentForm />
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b gap-x-3">
                                    <button
                                        type="button"
                                        className={`bg-page-bg-color text-tab-active-color  text-base font-semibold px-12 py-4 rounded-full`}
                                        onClick={() => customSetShowModal(false)}
                                    >
                                        Cancel
                                    </button>

                                    <button
                                        type="button"
                                        className={`bg-gradient-pink-purple text-white text-base font-semibold  px-12 py-4 rounded-full`}
                                        onClick={() => customSetShowModal(false)}
                                    >
                                        Create
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
            {/* Add Credit Modal */}
            {addCredits ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative my-6 mx-auto">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <div>
                                        <h5 className="text-xl font-semibold">
                                            Add Credits
                                        </h5>
                                        <p className='text-sm'>Choose any one option</p>
                                    </div>

                                    <button
                                        className=" background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setAddCredits(false)}
                                    >
                                        <MdClose className='w-5 h-5 mt-2' />
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="flex justify-between gap-6 p-6">

                                    <div className="flex group items-center justify-center w-64 p-6 border-2 bg-[#feffff] border-gray-100 rounded-lg  hover:bg-[#F9F4FC]">
                                        <div className='text-center'>
                                            <p className="text-Quantum-pre-color text-2xl font-semibold pb-5">$10</p>
                                            <h5 className='text-heading-black text-4xl font-semibold'>10</h5>
                                            <p className="text-Quantum-pre-color text-base font-medium mt-2">Credits</p>
                                            <div className="text-center mt-5">
                                                <button className="hover:bg-[#8F27C11A] hover:border-[#8F27C10D] hover:text-[#8F27C1]      text-[#10001ACC]   rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-[40px] py-[18px] " onClick={() => addCredit(10)}>Continue</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex group items-center justify-center w-64 p-6 border-2 bg-[#feffff] border-gray-100 rounded-lg  hover:bg-[#F9F4FC]">
                                        <div className='text-center'>
                                            <p className="text-Quantum-pre-color text-2xl font-semibold pb-5">$30</p>
                                            <h5 className='text-heading-black text-4xl font-semibold'>30</h5>
                                            <p className="text-Quantum-pre-color text-base font-medium mt-2">Credits</p>
                                            <div className="text-center mt-5">
                                                <button className="hover:bg-[#8F27C11A] hover:border-[#8F27C10D] hover:text-[#8F27C1]      text-[#10001ACC]   rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-[40px] py-[18px] " onClick={() => addCredit(30)}>Continue</button>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="flex group items-center justify-center w-64 p-6 border-2 bg-[#feffff] border-gray-100 rounded-lg  hover:bg-[#F9F4FC]">
                                        <div className='text-center'>
                                            <p className="text-Quantum-pre-color text-2xl font-semibold pb-5">$50</p>
                                            <h5 className='text-heading-black text-4xl font-semibold'>50</h5>
                                            <p className="text-Quantum-pre-color text-base font-medium mt-2">Credits</p>
                                            <div className=" text-center mt-5">
                                                <button
                                                    className='hover:bg-[#8F27C11A] hover:border-[#8F27C10D] hover:text-[#8F27C1]      text-[#10001ACC]   rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-[40px] py-[18px] '
                                                    onClick={() => addCredit(50)}>
                                                    Continue
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    )
}

export default AiCompute;
