import axiosInstance from "../api/axios";

//
//
//
//
// AI MODELS Page -> ModelHome.jsx
export const getModelHomeCards = async () => {
  return await axiosInstance.get(`/payments/ai-model-studio/`);
};
// AI MODELS Page -> ModelCategory.jsx
export const getModelCategoryCards = async (payload) => {
  return await axiosInstance.get(`/payments/combined-plans/`, {
    params: { plan_type: payload },
  });
};
// AI MODELS Page -> ModelInfo.jsx -> Launch Notebook
export const getStatusLight = async (payload) => {
  return await axiosInstance.post(`/manage/instance_status`, payload);
};
//
//
//
// AI MODELS Page -> ModelRun.jsx
export const getCodeGeneration = async (endpoint, payload) => {
  return await axiosInstance.post(`/${endpoint}`, payload);
};
export const getTextGeneration = async (endpoint, payload) => {
  return await axiosInstance.post(`/${endpoint}`, payload);
};
export const getTextToVideo = async (endpoint, payload) => {
  return await axiosInstance.post(`/${endpoint}`, payload);
};
export const getObjectDetection = async (endpoint, payload) => {
  return await axiosInstance.post(`/${endpoint}`, payload, { responseType: 'blob' });
};
export const getSpeechToText = async (endpoint, payload) => {
  return await axiosInstance.post(`/${endpoint}`, payload);
};
export const getImageGeneration = async (endpoint, payload) => {
  return await axiosInstance.post(`/${endpoint}`, payload);
};
export const getImageToText = async (endpoint, payload) => {
  return await axiosInstance.post(`/${endpoint}`, payload);
};
export const getTextTo3D = async (endpoint, payload) => {
  return await axiosInstance.post(`/${endpoint}`, payload);
};
export const getInstanceStatus = async (payload) => {
  return await axiosInstance.post(`/manage/instance`, payload);
};
export const getInstanceStatusStopper = async (payload) => {
  return await axiosInstance.post(`/manage/instance_stopper`, payload);
};