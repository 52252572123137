
import React, { useContext } from 'react'
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';
import Footer from '../Footer/Footer';
import { Context } from '../../Context';
import { Link, useNavigate } from 'react-router-dom';
import { Container } from '@mui/material';

function ApplicationHome() {
    const { open } = useContext(Context);
    const navigate = useNavigate();
    const home = [
        {
            id: 1,
            name: 'Synthetic Data Generation (Early Access)',
            title: 'Create Synthetic Data from your datapoints',

        },
        // {
        //     id: 2,
        //     name: 'Predictive Modeling',
        //     title: 'User will use for feature selection pre-dominantly in predictive modelling.',
        // },
        // {
        //     id: 3,
        //     name: 'Generative AI ',
        //     title: 'Image Generation using Quantum Classical Hybrid Text-to-Image model',
        // },
        // {
        //     id: 4,
        //     name: 'Portfolio Optimization',
        //     title: 'Maximize Return for a given level of Risk',
        // },
    ];

    const handleButtonClick = (item) => {
        navigate('/uploadfiles');
        localStorage.setItem('activeTab', 1);
    }
    return (
        <div className='flex w-full min-h-screen'>
            <Sidebar className='flex-shrink-0 h-full fixed' />
            <div className='flex flex-col w-full'>
                <div className={`${open ? "ml-[228px]" : "ml-[90px]"}`}>
                    <Navbar className='flex-shrink-0 fixed w-full' />
                </div>
                {/* page content */}
                <div className={`${open ? "ml-[228px]" : "ml-[90px]"} px-1 pt-28 mb-24`}>
                    {/* Your main content goes here */}
                    <Container>
                        <div className='flex justify-between pb-6'>
                            <div>
                                <Link to='/aimodel'>
                                    <h2 className="text-2xl font-bold text-footer-text-color">AI Data Services</h2>
                                </Link>
                                <p className="text-base font-normal text-gray-70 pt-1">
                                    Efficiently create vast and accurate datasets from minimal initial samples, surmounting data scarcity and tailoring datasets for specialized needs.
                                </p>
                            </div>
                        </div>

                        <div className="w-full bg-white py-6 px-8">
                            <div className="grid grid-cols-3 gap-4">
                                {home.map((item, index) => (
                                    <div key={index} className="bg-white shadow rounded-xl cursor-pointer" onClick={() => handleButtonClick(item.id)}>
                                        <div className='pt-6 px-6 pb-3 border-b border-gray-100'>
                                            <h2 className="font-semibold text-lg text-card-li-color">{item.name}</h2>
                                        </div>

                                        <div className='pb-6 px-6 pt-3'>
                                            <p className="text-card-li-color font-normal text-base">{item.title}</p>
                                        </div>

                                        <div className='text-center pb-6 pt-10'>
                                            <button type="button" className="bg-instance-bg text-btn-not-hover-color hover:bg-gradient-pink-purple min-w-[100px] hover:text-white text-lg font-semibold  px-12 py-4 rounded-lg">Run</button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Container>
                </div>
                <div className={`${open ? "ml-[228px]" : "ml-[90px]"}`}>
                    <Footer />
                </div>
            </div>
        </div >
    )
}

export default ApplicationHome