import React from 'react'

function Nec({ listQpu }) {
    return (
        <div className='flex gap-5'>
            <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-2xl shadow">
                <p className='text-sm font-semibold text-tab-active-color'>
                    Qubits: 12
                </p>

                <h5 className='text-card-li-color text-lg font-semibold'>NEC</h5>

                <p className='text-sm font-normal text-Quantum-pre-color'>NEC’s multi-chip quantum processor.</p>

                <div className="text-center mt-5">
                    <button className="hover:bg-[#8F27C11A] hover:border-[#8F27C10D] hover:text-[#8F27C1]  text-[#10001ACC]   rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-[40px] py-[18px] ">Coming Soon</button>
                </div>
            </div>
        </div>
    )
}

export default Nec