import React, { useState } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Slider from './Slider';
import { toast } from 'react-toastify';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import AuthBtn from '../AuthBtn';
import login_or_img from './../../assets/ui_img/login-or-line-img.svg';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import qubrid_ai_logo from '../../assets/white-ai-logo.svg';
function NewPass() {
  const { token: resetToken } = useParams();
  const { control, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const [showNewPassword, setShowNewPassword] = useState(false);

  const handleNewPassword = async (data) => {
    const headers = {
      'Content-Type': 'application/json',
    };

    if (resetToken) {
      const requestData = {
        reset_token: resetToken,
        new_password: data.newPassword,
      };

      try {
        const UserapiURL = process.env.REACT_APP_USER_SERVICE_URL;
        const response = await axios.post(`${UserapiURL}/reset_password/${resetToken}`, requestData, { headers });

        toast(response?.data?.message);
        navigate('/login');
      } catch (error) {
        console.error('Error As:', error);
        toast('An error occurred while resetting the password.');
      }
    }
  };

  return (
    <div className="bg-gray-200 flex items-center justify-center h-screen">
      <div className='w-1200px h-height-xl'>
        <div className="flex bg-white shadow rounded-3xl w-full h-full">
          <form onSubmit={handleSubmit(handleNewPassword)}>
            <div className='px-20 pt-10'>
              <Link to='/forgetpass'>
                <ArrowBackIcon />
              </Link>
            </div>
            <div className="text-center mt-8">
              <h5 className="text-3xl font-bold text-card-li-color">New Password</h5>
              <p className="text-base font-medium text-card-li-color py-3">Please enter your New Password</p>
            </div>

            <div className="px-20">
              <AuthBtn />
              <div className="py-2">
                <img src={login_or_img} className='m-auto' alt='login_or_img' />
              </div>

              <div>
                <Controller
                  name="newPassword"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: 'New Password is required',
                    minLength: {
                      value: 6,
                      message: 'New Password must be at least 6 characters',
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="outlined-basic"
                      label="New Password"
                      type={showNewPassword ? 'text' : 'password'}
                      autoComplete="current-password"
                      sx={{ width: '100%', marginTop: 2 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowNewPassword(!showNewPassword)}
                              edge="end"
                            >
                              {showNewPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                {errors.newPassword && <p className='text-sm font-semibold text-red-600'>{errors.newPassword.message}</p>}
              </div>

              <div className="w-full lg:mt-24">
                <button
                  className="w-full bg-gradient-pink-purple text-white text-base font-semibold py-5 rounded-full"
                >
                  Save Password
                </button>
              </div>
            </div>
          </form>

          <div className="relative w-1/2 bg-img-login">
            <div className="w-full h-2/3 flex flex-col justify-center items-center">
              <img src={qubrid_ai_logo} alt="qubrid_login_img" />
            </div>

            <div className="absolute bottom-10 w-full">
              <Slider />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewPass;
