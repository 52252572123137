import React, { useContext } from 'react'
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';
import { Link } from 'react-router-dom';
import Footer from '../Footer/Footer';
import { Context } from '../../Context';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Container, Tooltip } from '@mui/material';
import { BsInfo } from "react-icons/bs";


function ApplicationResult() {
    const { open, preditionRes } = useContext(Context);

    const handleDownload = (url) => {

        window.open(url, '_blank')
    };

    return (
        <>

            <div className='flex w-full min-h-screen'>
                <Sidebar className='flex-shrink-0 h-full fixed' />
                <div className='flex flex-col w-full'>
                    <div className={`${open ? "ml-[228px]" : "ml-[90px]"}`}>
                        <Navbar />
                    </div>
                    {/* Page Content */}
                    <div className={`${open ? "ml-[228px]" : "ml-[90px]"} px-8 pt-24 mb-24`}>
                        <Container>
                            {/* Your main content goes here */}
                            <div className='flex justify-between py-4'>
                                <div>
                                    <div className="flex items-center">
                                        <Link to={'/'}>
                                            <h2 className="text-2xl font-bold text-heading-black">AI Hub</h2>
                                        </Link>
                                        <MdKeyboardArrowRight className='text-2xl' />
                                        <Link to='/uploadfiles'>
                                            <h2 className="text-2xl font-bold text-heading-black">Synthetic Data Generation</h2>
                                        </Link >
                                    </div>
                                    <span className="text-base font-normal text-gray-70">Mimicking real-world data patterns and structures, for effective training of AI models.</span>
                                </div>
                            </div>
                            
                            {/* Synthetic Graphs */}
                            {/* <SyntheticGraph /> */}
                            <div className="bg-white shadow-md rounded-lg my-3 p-8">
                                <div className='flex justify-between'>
                                    <div>
                                        <span className='font-semibold text-base'>Overall Accuracy</span>
                                        <div>
                                            <span className='font-semibold text-4xl'>{preditionRes?.overall}</span><span className="font-medium text-2xl"></span>
                                        </div>
                                    </div>

                                    <button
                                        className='hover:bg-[#8F27C11A] hover:border-[#8F27C10D] hover:text-[#8F27C1] text-[#10001ACC]  rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-[24px] py-[18px]'
                                        onClick={() => handleDownload(preditionRes?.generated_report_path)}
                                    >
                                        Download Report
                                    </button>

                                </div>
                                <div className='flex justify-between pt-8'>
                                    <div className='flex justify-between gap-10'>
                                        <div>
                                            <div className="flex items-center">
                                                <span className='font-medium whitespace-nowrap text-base'>Univariant Accuracy &nbsp;</span>
                                                <Tooltip placement='top' arrow title="Univariate accuracy measures how well the synthetic data matches the real data in terms of individual feature distributions.">
                                                    <span><BsInfo className='text-2xl border-2 rounded-full bg-gray-50' /></span>
                                                </Tooltip>
                                            </div>
                                            <p className='font-semibold text-2xl text-[#239A36]'>{preditionRes?.univariant}</p>
                                        </div>
                                        <div>
                                            <div className="flex items-center">
                                                <p className='font-medium whitespace-nowrap text-base'>Bivariant Accuracy &nbsp;</p>
                                                <Tooltip placement='top' arrow title="Bivariate accuracy assesses how well the relationships between pairs of features in the synthetic data match those in the real data.">
                                                    <span><BsInfo className='text-2xl border-2 rounded-full bg-gray-50' /></span>
                                                </Tooltip>
                                            </div>

                                            <p className={`font-semibold  text-[#239A36] "text-2xl"`}>{preditionRes?.bivariant}</p>
                                        </div>
                                    </div>
                                    <div className='border-r-2 mx-8'></div>
                                    <div>
                                        <span className='font-medium'>The Accuracy of synthetic data is measured by how well it matches original data statistically.</span>

                                        {/* Univariate accuracy measures how well the synthetic data matches the real data in terms of individual feature distributions. */}
                                        {/* Bivariate accuracy assesses how well the relationships between pairs of features in the synthetic data match those in the real data. */}

                                    </div>
                                </div>
                            </div>
                            {/* <Tabs /> */}
                            <div className='w-full bg-white rounded-xl shadow p-8'>
                                <div className='flex justify-between py-3'>
                                    <div className='text-base font-semibold py-4'>
                                        Generated data
                                    </div>
                                    <button
                                        className='hover:bg-[#8F27C11A] hover:border-[#8F27C10D] hover:text-[#8F27C1]  text-[#10001ACC]  rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-[24px] py-[18px]'
                                        onClick={() => handleDownload(preditionRes?.output_filepath)}
                                    >
                                        Download Data
                                    </button>
                                </div>

                                <div className="flex gap-12">
                                    {
                                        preditionRes?.gen_data &&
                                        (
                                            <div className="overflow-x-auto w-full">
                                                <table className="min-w-full">
                                                    <thead className='bg-table-bg-head-clr'>
                                                        <tr>
                                                            {Object.keys(preditionRes?.gen_data).map((key) => (
                                                                <th className="py-2 px-4 text-left text-sm font-semibold text-secondry-clr" key={key}>{key}</th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {[...Array(10).keys()].map((index) => (
                                                            <tr key={index} className={index % 2 !== 0 && 'bg-page-bg-color bg-opacity-50'}>
                                                                {Object.keys(preditionRes?.gen_data).map((column) => (
                                                                    <td className="py-2 px-4  text-sm font-normal text-secondry-clr" key={column}>{preditionRes?.gen_data[column][index]}</td>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )
                                    }

                                    {/* {filesDataRes && (
                                        <div className="overflow-x-auto border border-gray-100">
                                            <table className="min-w-full">
                                                <thead className='bg-table-bg-head-clr'>
                                                    <tr>
                                                        {Object.keys(filesDataRes).map((key) => (
                                                            <th className="py-2 px-4 text-left text-sm font-semibold text-secondry-clr" key={key}>{key}</th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {[...Array(10).keys()].map((index) => (
                                                        <tr key={index} className={index % 2 !== 0 && 'bg-page-bg-color bg-opacity-50'}>
                                                            {Object.keys(filesDataRes).map((column) => (
                                                                <td className="py-2 px-4  text-sm font-normal text-secondry-clr min-w-fit" key={column}>{filesDataRes[column][index]}</td>
                                                            ))}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )} */}
                                </div>
                            </div>
                        </Container>
                    </div>
                    <div className={`${open ? "ml-[228px]" : "ml-[90px]"}`}>
                        <Footer />
                    </div>
                </div>
            </div >

            {/* {loading && (
                <div>
                    <div className="absolute inset-0 flex items-center justify-center z-[200]">
                        <img src={loader} alt="img_loader" className="spinner" />
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </div>
            )} */}
        </>
    )
}

export default ApplicationResult