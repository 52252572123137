// import React, { useState } from 'react'
// import { ImSpinner3 } from 'react-icons/im';

const ComingSoon = () => {

    // const [loading, setLoading] = useState(false); 




    // const handleClick = () => { }

    return (
        <div className='flex w-full h-full'>
            {/* User Interaction Section */}
            <div className="w-1/2 h-full p-8">
                {/* <p className='text-[16px] font-[600] mb-8'>Generate Response</p> */}



                <p className='text-[16px] font-[600] mb-8'>Coming Soon...</p>

                {/* 
                {loading ?
                    <button className='loading-button w-[136px] h-[52px] mx-auto'>
                        <ImSpinner3 className='text-xl mr-1 animate-spin' />
                        Running
                    </button>
                    :
                    <button className='classic-button w-[136px] h-[52px] mx-auto ' onClick={() => handleClick()}>
                        Run
                    </button>
                } */}
            </div>
            {/* Response Section */}
            <div className="w-1/2 min-h-[538px] p-8 border-l-2 ">
                {/* <p className='text-[16px] font-[600] mb-8'>Response</p> */}


            </div>
        </div>
    )
}

export default ComingSoon