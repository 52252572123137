import React, { useContext, useEffect, useState } from 'react'
import Footer from '../Footer/Footer';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import { Context } from '../../Context';
import { toast } from "react-toastify";
import { FaRegTrashAlt } from "react-icons/fa";
import { TbTrashOff } from "react-icons/tb";
import { Container, Tooltip } from '@mui/material';
import { GoLinkExternal } from "react-icons/go";
import { TbExternalLinkOff } from "react-icons/tb";
import loader from '../../assets/ui_img/loader.svg';
import { InfoOutlined, PlayCircleOutlineOutlined, StopCircleOutlined } from '@mui/icons-material';
import moment from 'moment-timezone';
import { GrDocumentCsv } from "react-icons/gr";
import { GrDocumentPdf } from "react-icons/gr";
import axiosInstance from '../../api/axios';

function MyInstance() {
    const [openTab, setOpenTab] = useState(1);
    const { open } = useContext(Context);
    const [myinstance, setMyinstance] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [synthHisRes, setSynthHisRes] = useState([]);
    const handleRefreshToken = async () => {
        try {
            await axiosInstance.post('/user/refresh_token');
            return true
        } catch (error) {
            console.log('refresh token')
            return false
        }
    };

    useEffect(() => {
        const loadInstance = async () => {
            const url = `/user/synth/synth-history`
            try {
                const response = await axiosInstance.get(url)
                const data = response?.data?.response
                setSynthHisRes(data);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    const refresh = await handleRefreshToken();
                    if (refresh) {
                        loadInstance()
                    }
                } else {
                    console.error('Error:', error);
                }
            }
        }
        loadInstance();
    }, []);

    const convertToLocalTime = (gmtTimeStr) => {
        return moment.tz(gmtTimeStr, "ddd, DD MMM YYYY HH:mm:ss [GMT]", 'GMT').tz('Your/LocalTimezone').format('YYYY-MM-DD HH:mm:ss z');
    };

    console.log(convertToLocalTime('2024-07-02 16:05:08 UTC'))

    const loadInstance = async () => {
        const url = `/manage/user_instances?&page=1&page_size=10&`
        try {
            const response = await axiosInstance.get(url)
            const data = response.data;
            setMyinstance(data.instances);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                const refresh = await handleRefreshToken();
                if (refresh) {
                    loadInstance();
                }
            } else {
                console.error('Error:', error);
            }
        }
    }

    useEffect(() => {
        const loadInstance = async () => {
            const url = `/manage/user_instances?&page=1&page_size=10&`
            try {
                const response = await axiosInstance.get(url)
                const data = response.data;
                setMyinstance(data.instances);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    const refresh = await handleRefreshToken();
                    if (refresh) {
                        loadInstance();
                    }
                } else {
                    console.error('Error:', error);
                }
            }
        }
        loadInstance();
    }, []);

    const handleOpen = async (ele, token) => {

        // const new_public_ip = ele.public_ip;
        const jupyter_token = ele.jupyter_token;
        const notebookUrl = jupyter_token;
        // const notebookUrl = `http://${new_public_ip}:8888/lab?token=${jupyter_token}`;
        // notebook_url = f"http://{new_public_ip}:8888/lab?token={jupyter_token}"
        window.open(notebookUrl, '_blank');
        // sessionStorage.setItem('jupiterUrl', notebookUrl)
        // navigate('/jupyter')
        // }
    };

    const [launchingInstance, setLaunchingInstance] = useState(false);

    const deleteInstance = async (ele) => {
        setLoading(true)
        sessionStorage.clear()
        const requestData = {
            "plan_id": ele.plan_id,
            "instance_id": ele.instance_id
        };

        try {
            const response = await axiosInstance.post(`/manage/delete-instance`, requestData)
            toast(response?.data?.message)
            // setDeleteResourceModal(false);
            // setFreeShowModal(false);
            // setDeleteSuccess(true);
            const cpuid = response.data.instance;
            // const cpuid = localStorage.getItem("CPU");
            // If the instance is stopped successfully, make a second API call
            try {
                await axiosInstance.post(`/manage/InActivatePlan`, { plan: cpuid })
                loadInstance();
            } catch (secondError) {
                console.error('Second API call error', secondError);
                // toast('Something went wrong with the second API call');
            }
            setLoading(false)
        }
        catch (error) {
            if (error.response && error.response.status === 401) {
                const refresh = await handleRefreshToken();
                if (refresh) {
                    deleteInstance(ele)
                }
            } else {
                console.error('error', error)
                // toast('something went wrong')
                setLoading(false)
                toast(error.response.data.message);
            }
        }
        setTimeout(() => {
            loadInstance()
        }, 1500)
    }

    const handleStart = async (ele) => {
        setLaunchingInstance(true);
        setTimeout(() => {
            loadInstance()
            setLaunchingInstance(false);
        }, 1500)
        try {
            const response = await axiosInstance.post(`/manage/relaunch`,
                {
                    instance_id: ele.instance_id
                },
            )
            loadInstance()
            toast(response.data.message)
        } catch (error) {
            if (error.response && error.response.status === 401) {
                const refresh = await handleRefreshToken();
                if (refresh) {
                    handleStart()
                }
            } else {
                console.error('Error:', error);
                toast(error.response.data.message)
            }
        };
    }

    const handleStop = async (ele) => {
        setLaunchingInstance(true);
        setTimeout(() => {
            loadInstance()
            setLaunchingInstance(false);
        }, 1500)
        try {
            const response = await axiosInstance.post(`/manage/stop-instance`,
                {
                    instance_id: ele.instance_id
                },
            )
            loadInstance()
            toast(response.data.message)
        } catch (error) {
            if (error.response && error.response.status === 401) {
                const refresh = await handleRefreshToken();
                if (refresh) {
                    handleStop(ele);
                }
            } else {
                console.error('Error:', error);
                toast(error.response.data.message)
            }
        }
    }

    const handleDownload = (url) => {
        window.open(url, '_blank')
    };
    const [deleteIns, setDeleteIns] = useState(null);
    return (
        <div className='flex w-full min-h-screen'>
            <Sidebar className='flex-shrink-0 h-full fixed' />
            <div className='flex flex-col w-full'>
                <div className={`${open ? "ml-[228px]" : "ml-[90px]"}`}>
                    <Navbar />
                </div>
                {/* Page Content */}
                <div className={`${open ? "ml-[228px]" : "ml-[90px]"} px-8 pt-24 mb-24`}>
                    {/* Your main content goes here */}
                    <Container>
                        <div className='pb-6 text-secondry-clr text-base'>
                            <span className='font-semibold'>Dashboard</span>
                        </div>
                        <div className="flex flex-wrap">
                            <div className="w-full">
                                <ul
                                    className="flex list-none bg-white pt-4 border-b space-x-8"
                                    role="tablist"
                                >
                                    <li className={`${openTab === 1 ? "text-tab-active-color border-b-4 border-tab-active-color" : "text-gray-60"} text-base font-bold px-1.5 pb-3`}>
                                        <a
                                            onClick={e => {
                                                e.preventDefault();
                                                setOpenTab(1);
                                            }}

                                            data-toggle="tab"
                                            href="#link1"
                                            role="tablist"
                                        >
                                            Instance
                                        </a>
                                    </li>

                                    <li className={`${openTab === 2 ? "text-tab-active-color border-b-4 border-tab-active-color" : "text-gray-60"} text-base font-bold px-1.5 pb-3`}>
                                        <a
                                            onClick={e => {
                                                e.preventDefault();
                                                setOpenTab(2);
                                            }}
                                            data-toggle="tab"
                                            href="#link2"
                                            role="tablist"
                                        >
                                            Synthetic Data
                                        </a>
                                    </li>
                                </ul>

                                <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
                                    <div className="flex-auto">
                                        <div className="tab-content tab-space">
                                            <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                                                <table className="w-full">
                                                    <thead className='bg-table-header-bg-color'>
                                                        <tr>
                                                            <th className="py-4 px-10 text-sm font-semibold text-left">Instance Type</th>
                                                            <th className="py-4 px-10 text-sm font-semibold text-left">Type</th>
                                                            <th className="py-4 px-10 text-sm font-semibold text-left">Date & Time</th>
                                                            <th className="py-4 px-10 text-sm font-semibold text-left">Status</th>
                                                            <th className="py-4 px-10 text-sm font-semibold text-left">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            myinstance?.map((ele, i) =>
                                                                <tr key={i} className={`${i % 2 === 0 ? 'bg-white' : 'bg-light-gray-1'} w-full`}>
                                                                    <td className="py-4 px-10 text-sm font-normal">{ele?.plan_name}</td>
                                                                    <td className="py-4 px-10 text-sm font-normal">{ele?.instance_type.toUpperCase()}</td>
                                                                    <td className="py-4 px-10 text-sm font-normal">{convertToLocalTime(ele?.instance_launch_time)}</td>
                                                                    <td className="py-4 px-10 text-sm font-normal">
                                                                        {ele?.status}
                                                                    </td>
                                                                    {ele?.status === "running" ? (
                                                                        <td className="py-2 px-10 flex">
                                                                            <div className='mr-4'>
                                                                                <Tooltip title="Stop" placement='left' arrow>
                                                                                    <button className='mt-1' disabled={launchingInstance} onClick={() => handleStop(ele)}>
                                                                                        <StopCircleOutlined className='h-5 w-5 stroke-[0.5]' />
                                                                                    </button>
                                                                                </Tooltip>
                                                                            </div>

                                                                            <div className='mr-4'>
                                                                                <Tooltip title="View" placement='left' arrow>
                                                                                    <button className='mt-2 ' onClick={() => handleOpen(ele)}>
                                                                                        <GoLinkExternal className='h-5 w-5 stroke-[0.5]' />
                                                                                    </button>
                                                                                </Tooltip>
                                                                            </div>
                                                                            <Tooltip title="Delete" placement='right' arrow>
                                                                                <button className=' ' onClick={() => { setShowConfirmationModal(true); setDeleteIns(ele) }}>
                                                                                    <FaRegTrashAlt className='h-5 w-5' />
                                                                                </button>
                                                                            </Tooltip>
                                                                            {/* launch notebook modal */}
                                                                            {showConfirmationModal && (
                                                                                <div className="fixed z-10 inset-0 overflow-y-auto">
                                                                                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                                                                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                                                                                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                                                                                        </div>
                                                                                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                                                                                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                                                                                            <div>
                                                                                                <div className="mt-3 text-center sm:mt-5">
                                                                                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Are you sure you want to delete?</h3>
                                                                                                </div>
                                                                                                <div className="mt-5 sm:mt-6 flex justify-center">
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className='cancel-button'
                                                                                                        onClick={() => {
                                                                                                            setShowConfirmationModal(false);
                                                                                                            deleteInstance(deleteIns);
                                                                                                        }}
                                                                                                    >
                                                                                                        Delete
                                                                                                    </button>
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className='general-button ml-5'
                                                                                                        onClick={() => setShowConfirmationModal(false)}
                                                                                                    >
                                                                                                        Cancel
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </td>
                                                                    ) : ele?.status === "stopped" ? (
                                                                        <td className="py-2 px-10 flex items-center">

                                                                            <div className='mr-4'>
                                                                                <Tooltip title="Start" placement='left' arrow>
                                                                                    <button disabled={launchingInstance} onClick={() => handleStart(ele)}>
                                                                                        <PlayCircleOutlineOutlined className='h-5 w-5 stroke-[0.5]' />
                                                                                    </button>
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div className="mr-4">
                                                                                <button className=' mt-2'>
                                                                                    <TbExternalLinkOff className='h-5 w-5 text-gray-400' />
                                                                                </button>
                                                                            </div>
                                                                            <Tooltip title="Delete" placement='right' arrow>
                                                                                <button className=' ' onClick={() => { setShowConfirmationModal(true); setDeleteIns(ele) }}>
                                                                                    <FaRegTrashAlt className='h-5 w-5' />
                                                                                </button>
                                                                            </Tooltip>
                                                                            {showConfirmationModal && (
                                                                                <div className="fixed z-10 inset-0 overflow-y-auto">
                                                                                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                                                                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                                                                                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                                                                                        </div>
                                                                                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                                                                                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                                                                                            <div>
                                                                                                <div className="mt-3 text-center sm:mt-5">
                                                                                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Are you sure you want to delete?</h3>
                                                                                                </div>
                                                                                                <div className="mt-5 sm:mt-6 flex justify-center">
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className='cancel-button'
                                                                                                        onClick={() => {
                                                                                                            setShowConfirmationModal(false);
                                                                                                            deleteInstance(deleteIns);
                                                                                                        }}
                                                                                                    >
                                                                                                        Delete
                                                                                                    </button>
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className='general-button ml-5'
                                                                                                        onClick={() => setShowConfirmationModal(false)}
                                                                                                    >
                                                                                                        Cancel
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </td>
                                                                    ) : (
                                                                        <td className="py-2 px-10 flex items-center">
                                                                            <div className='mr-4'>
                                                                                <Tooltip title="Unknown" placement='left' arrow>
                                                                                    <button className=''>
                                                                                        <InfoOutlined className='h-5 w-5 text-gray-400' />
                                                                                    </button>
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div className="mr-4">
                                                                                <button className='mt-2'>
                                                                                    <TbExternalLinkOff className='h-5 w-5 text-gray-400' />
                                                                                </button>
                                                                            </div>
                                                                            <Tooltip title="Deleted" placement='right' arrow>
                                                                                <button className=''>
                                                                                    <TbTrashOff className='h-5 w-5 text-gray-400' />
                                                                                </button>
                                                                            </Tooltip>
                                                                        </td>
                                                                    )}
                                                                </tr>
                                                            )}
                                                    </tbody>
                                                </table>

                                                {loading && (
                                                    <div>
                                                        <div className="absolute inset-0 flex flex-col items-center justify-center z-[200]">
                                                            <img src={loader} alt="img_loader" className="spinner" />
                                                            <p className='font-medium text-lg text-white block'>Please wait...</p>
                                                        </div>
                                                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                                    </div>
                                                )}

                                            </div>

                                            <div className={`${openTab === 2 ? "block" : "hidden"}`} id="link2">
                                                <table className="w-full">
                                                    <thead className='bg-table-header-bg-color'>
                                                        <tr>
                                                            <th className="py-4 px-10 text-sm font-semibold text-left">Sample File</th>
                                                            <th className="py-4 px-10 text-sm font-semibold text-left">Generated Date</th>
                                                            <th className="py-4 px-10 text-sm font-semibold text-left">Generated File</th>
                                                            <th className="py-4 px-10 text-sm font-semibold text-left">Generated File Size</th>
                                                            <th className="py-4 px-10 text-sm font-semibold text-left">Report</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            synthHisRes
                                                                ?.sort((a, b) => new Date(b.generate_date_time) - new Date(a.generate_date_time))
                                                                .map((ele, i) => (
                                                                    <tr key={i} className={`${i % 2 === 0 ? 'bg-white' : 'bg-light-gray-1'} w-full`}>
                                                                        <td className="py-4 px-10 text-sm font-normal">{ele?.filename}</td>
                                                                        <td className="py-4 px-10 text-sm font-normal">{ele?.generate_date_time.toUpperCase()}</td>
                                                                        <td className="py-4 px-10 text-sm font-normal flex cursor-pointer" onClick={() => handleDownload(ele?.generated_output_file_link)}>
                                                                            Generated File<GrDocumentCsv className='mt-0.5 ml-0.5' />
                                                                        </td>
                                                                        <td className="py-4 px-10 text-sm font-normal">
                                                                            {ele?.generated_csv_file_size}
                                                                        </td>
                                                                        <td className="py-4 px-10 text-sm font-normal flex cursor-pointer" onClick={() => handleDownload(ele?.report_file_link)}>
                                                                            Download Report <GrDocumentPdf className='mt-0.5 ml-0.5' />
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
                {/* Footer */}
                <div className={`${open ? "ml-[228px]" : "ml-[90px]"}`}>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default MyInstance
