import React, { useContext, useEffect, useState } from 'react'
import Ionq from './Providers/Ionq'
import Rigetti from './Providers/Rigetti';
import Nec from './Providers/Nec';
import Oqc from './Providers/Oqc';
import Quera from './Providers/Quera';
import loader from "../../assets/ui_img/loader.svg"
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';
import { Container } from '@mui/material';
import Footer from '../Footer/Footer';
import { Context } from '../../Context';
import axiosInstance from '../../api/axios';

function TryQuantum() {
    const { open } = useContext(Context);
    const [openTab, setOpenTab] = useState(1);
    const [listQpu, setListQpu] = useState([]);
    const [loading, setLoading] = useState(false);
    const handleRefreshToken = async () => {
        try {
            await axiosInstance.post('/user/refresh_token');
            return true
        } catch (error) {
            console.log('refresh token')
            return false
        }
    };

    useEffect(() => {
        setLoading(true);
        const url = `/payments/other-plans/`;
        const params = {
            type: "quantum devices",
        };
        const getDevicePlan = async () => {
            try {
                const response = await axiosInstance.get(url, {
                    params: params,
                })
                const data = response.data.data;
                console.log(data);
                
                setListQpu(data);
                setLoading(false);
            }
            catch (error) {
                setLoading(false);
                if (error.response && error.response.status === 401) {
                    const refresh = await handleRefreshToken();
                    if (refresh) {
                        getDevicePlan()
                    }
                } else {
                    console.error('Error:', error);
                }
            }
        }
        getDevicePlan()
    }, []);

    return (
        <div className='flex w-full min-h-screen'>
            <Sidebar className='flex-shrink-0 h-full fixed' />
            <div className='flex flex-col w-full'>
                <div className={`${open ? "ml-[228px]" : "ml-[90px]"}`}>
                    <Navbar />
                </div>
                {/* Page Content */}
                <div className={`${open ? "ml-[228px]" : "ml-[90px]"} px-1 pt-24`}>
                    <Container>
                        {/* Your main content goes here */}
                        <div className='flex w-full bg-white shadow h-80vh'>
                            <div className='w-1/5 bg-tab-not-active-color'>
                                <ul
                                    className="mb-0 list-none"
                                    role="tablist"
                                >
                                    <li className="-mb-px mr-2 last:mr-0 flex-auto w-full text-center">
                                        <p
                                            className={
                                                "text-lg font-semibold text-gray-60  py-4  rounded block  "
                                            }
                                            onClick={e => {
                                                e.preventDefault();
                                                setOpenTab(1);
                                            }}
                                            data-toggle="tab"
                                            href="#link1"
                                            role="tablist"
                                        >
                                            Providers
                                        </p>
                                    </li>
                                    <li className="-mb-px mr-2 last:mr-0 flex-auto w-full text-center">
                                        <a
                                            className={
                                                "text-lg font-semibold  py-4  rounded block  " +
                                                (openTab === 1
                                                    ? "text-tab-active-color bg-white border-l-4 border-tab-active-color"
                                                    : "text-gray-60 tab-not-active-color")
                                            }
                                            onClick={e => {
                                                e.preventDefault();
                                                setOpenTab(1);
                                            }}
                                            data-toggle="tab"
                                            href="#link1"
                                            role="tablist"
                                        >
                                            IONQ
                                        </a>
                                    </li>

                                    <li className="-mb-px mr-2 last:mr-0 w-full flex-auto text-center">
                                        <a
                                            className={
                                                "text-lg font-semibold py-4   rounded block  " +
                                                (openTab === 2
                                                    ? "text-tab-active-color bg-white border-l-4 border-tab-active-color"
                                                    : "text-gray-60 tab-not-active-color")
                                            }
                                            onClick={e => {
                                                e.preventDefault();
                                                setOpenTab(2);
                                            }}
                                            data-toggle="tab"
                                            href="#link2"
                                            role="tablist"
                                        >
                                            Rigetti
                                        </a>
                                    </li>

                                    <li className="-mb-px mr-2 last:mr-0 w-full flex-auto text-center">
                                        <a
                                            className={
                                                "text-lg font-semibold  py-4 rounded block  " +
                                                (openTab === 3
                                                    ? "text-tab-active-color bg-white border-l-4 border-tab-active-color"
                                                    : "text-gray-60 tab-not-active-color")
                                            }
                                            onClick={e => {
                                                e.preventDefault();
                                                setOpenTab(3);
                                            }}
                                            data-toggle="tab"
                                            href="#link2"
                                            role="tablist"
                                        >
                                            NEC
                                        </a>
                                    </li>

                                    {/* <li className="-mb-px mr-2 last:mr-0 w-full flex-auto text-center">
                                        <a
                                            className={
                                                "text-lg font-semibold  py-4 rounded block  " +
                                                (openTab === 4
                                                    ? "text-tab-active-color bg-white border-l-4 border-tab-active-color"
                                                    : "text-gray-60 tab-not-active-color")
                                            }
                                            onClick={e => {
                                                e.preventDefault();
                                                setOpenTab(4);
                                            }}
                                            data-toggle="tab"
                                            href="#link2"
                                            role="tablist"
                                        >
                                            OQC
                                        </a>
                                    </li> */}

                                    <li className="-mb-px mr-2 last:mr-0 w-full flex-auto text-center">
                                        <a
                                            className={
                                                "text-lg font-semibold  py-4 rounded block  " +
                                                (openTab === 5
                                                    ? "text-tab-active-color bg-white border-l-4 border-tab-active-color"
                                                    : "text-gray-60 tab-not-active-color")
                                            }
                                            onClick={e => {
                                                e.preventDefault();
                                                setOpenTab(5);
                                            }}
                                            data-toggle="tab"
                                            href="#link2"
                                            role="tablist"
                                        >
                                            Quera
                                        </a>
                                    </li>

                                </ul>
                            </div>

                            <div className='w-full mx-5 my-5'>
                                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                                    <Ionq listQpu={listQpu} />
                                </div>
                                <div className={openTab === 2 ? "block" : "hidden"} id="link1">
                                    <Rigetti listQpu={listQpu} />
                                </div>
                                <div className={openTab === 3 ? "block" : "hidden"} id="link1">
                                    <Nec listQpu={listQpu} />
                                </div>
                                <div className={openTab === 4 ? "block" : "hidden"} id="link1">
                                    <Oqc listQpu={listQpu} />
                                </div>
                                <div className={openTab === 5 ? "block" : "hidden"} id="link1">
                                    <Quera listQpu={listQpu} />
                                </div>
                            </div>
                        </div>
                        {loading && (
                            <div>
                                <div className="absolute inset-0 flex flex-col items-center justify-center z-[200]">
                                    <img src={loader} alt="img_loader" className="spinner" />
                                    <p className='font-medium text-lg text-white block'>Please wait while fetching...</p>
                                </div>
                                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                            </div>
                        )}
                    </Container>
                </div>
                <div className={`${open ? "ml-[228px]" : "ml-[90px]"}`}>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default TryQuantum
