import React, { useContext, useEffect, useState } from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import { Container, IconButton } from '@mui/material'
import { Context } from '../../Context';
import { DeleteOutlined, FileDownload, FolderOutlined } from '@mui/icons-material';
import axiosInstance from '../../api/axios';
import Footer from '../Footer/Footer';
import { Link, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

function UserFolder() {
  const { open } = useContext(Context);
  const [recentFiles, setRecentFiles] = useState([]);
  const [searchParams] = useSearchParams(); // Notice the correct variable name
  const folderName = searchParams.get('folderName');
  const [explor, setExplor] = useState('');

  const folderInFolder = (ele) => {
    setExplor(prevExplor => `${prevExplor}/${ele}`);
  }

  useEffect(() => {
    const recentFile = () => {
      const config = {
        method: 'get',
        url: `/manage/folder-content?folder=${folderName}${explor}`,
      };
      axiosInstance(config)
        .then((response) => {
          setRecentFiles(response.data)
        })
        .catch((error) => {
          console.error(error);
        });
    }
    recentFile();
  }, [explor, folderName])

  const handleDelete = (ele) => {
    console.log(`${folderName}${explor}/${ele}`)
    axiosInstance.delete('/manage/delete', {
      data: {
        target: `${folderName}${explor}/${ele}`
      }
    })
      .then(response => {
        console.log(response.data.message);
        toast(response.data.message)
      })
      .catch(error => {
        console.error(error);
      });
  }

  const handleDownload = (url) => {
    window.open(url, '_blank')
  };
 
  return (
    <div className='flex w-full min-h-screen'>
      <Sidebar />
      <div className='flex flex-col w-full'>
        <div className={`${open ? "ml-[228px]" : "ml-[90px]"}`}>
          <Navbar />
        </div>
        {/* Page Content */}
        <div className={`${open ? "ml-[228px]" : "ml-[90px]"} px-8 pt-24 mb-24`}>
          {/* Your main content goes here */}
          <Container>
            <div>
              <p className='font-semibold text-base text-secondry-clr'><Link to="/userData">User Data</Link> {'>'} {folderName}</p>
              <p className='text-base font-normal text-gray-70'>All of project file in one place</p>
            </div>
            <div className='grid grid-cols-3 gap-3 py-6'>
              {
                recentFiles?.folders?.map((ele, i) =>
                  <div className='bg-white px-3 py-3 flex items-center justify-between min-w-[240px] h-[60px] shadow cursor-pointer' onClick={() => folderInFolder(ele.name)}>
                    <div className='flex items-center gap-3'>
                      <div className='w-10 h-10 rounded-full p-1 bg-[#F5F7FF] flex items-center justify-center'>
                        <FolderOutlined />
                      </div>
                      <span className='text-sm font-semibold text-secondry-clr'>{ele.name}</span>
                    </div>
                  </div>
                )
              }
            </div>
 
            <div className='flex justify-between pb-4'>
              <span className='font-semibold text-base'>Files({recentFiles?.files?.length})</span>
              {/* <div>Filter By:</div> */}
            </div>
            <table className="w-full">
              <thead className='bg-table-header-bg-color'>
                <tr>
                  <th className="py-4 px-10 text-sm font-semibold text-left">File Name</th>
                  <th className="py-4 px-10 text-sm font-semibold text-left">Size</th>
                  <th className="py-4 px-10 text-sm font-semibold text-left">Last Modification</th>
                  <th className="py-4 px-10 text-sm font-semibold text-left">Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  recentFiles?.files?.map((ele, i) =>
                    <tr key={i} className={`${i % 2 === 0 ? 'bg-white' : 'bg-light-gray-1'} w-full`}>
                      <td className="py-2 px-10 text-sm font-normal">{ele?.name}</td>
                      <td className="py-2 px-10 text-sm font-normal">{ele?.size}</td>
                      <td className="py-2 px-10 text-sm font-normal">
                        {ele?.last_modified}
                      </td>
                      <td className="py-2 px-10 text-sm font-normal cursor-pointer">
                        <IconButton onClick={() => handleDownload(ele?.download_link)}>
                          <FileDownload />
                        </IconButton>
                        <IconButton onClick={() => handleDelete(ele?.name)}>
                          <DeleteOutlined />
                        </IconButton>
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
          </Container>
        </div>
        <div className={`${open ? "ml-[228px]" : "ml-[90px]"}`}>
          <Footer />
        </div>
      </div>
    </div>
  )
}
 
export default UserFolder