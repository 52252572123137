import axios from 'axios';
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

function CompanyVerification() {
    const { token } = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        const verifyEmailFunction = async () => {
            try {
                const BussinessapiURL = process.env.REACT_APP_BUSINESS_SERVICE_URL;
                const response = await axios.get(`${BussinessapiURL}/company-email-verify/${token}`);
                // Handle the response as needed
                if (response.status === 200) {
                    console.log("Email verified successfully");
                }
            } catch (error) {
                // Handle errors
                console.error("Verification failed:", error);
            }
        };
        verifyEmailFunction();
    }, [token]);

    const home = () => {
        navigate('/myorganization')
    };

    return (
        <div className="email-verify-container flex flex-col items-center justify-center h-screen bg-gray-100">
            <div className="email-verify-content bg-white p-8 rounded-md shadow-md">
                <h1 className="text-3xl font-semibold text-primaryColor mb-4">
                    Your Company has been verified
                </h1>
                <p className="text-lg text-gray-700 mb-6">
                    Thank you for verifying your Company Details.
                </p>
                <button
                    style={{ background: 'linear-gradient(45deg, #FF4081, #FF5252)', color: 'white' }}
                    className="font-semibold py-2 px-4 rounded-full"
                    onClick={home}
                >
                    Continue to My Organization
                </button>
            </div>
        </div>
    );
}

export default CompanyVerification