import React, { useContext, useEffect, useState } from 'react'
import Footer from '../Footer/Footer';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import { Context } from '../../Context';
import { Link, useNavigate } from 'react-router-dom';
import { Container } from '@mui/material';
import { MdKeyboardArrowRight } from 'react-icons/md';
import moment from 'moment-timezone';
import { LuPlus } from "react-icons/lu";
import { GrDocumentCsv } from "react-icons/gr";
import axiosInstance from '../../api/axios';

function DataHistroy() {
    const { open } = useContext(Context);
    const [myinstance, setMyinstance] = useState([]);
    const navigate = useNavigate()
    const convertToLocalTime = (gmtTimeStr) => {
        return moment.tz(gmtTimeStr, "ddd, DD MMM YYYY HH:mm:ss [GMT]", 'GMT').tz('Your/LocalTimezone').format('YYYY-MM-DD HH:mm:ss z');
    };

    console.log(convertToLocalTime('2024-07-02 16:05:08 UTC'))

    useEffect(() => {
        const loadInstance = () => {

            // const url = `${UserapiURL}/my-instance`;
            const url = `/synth-history`

            axiosInstance.get(url)
                .then(response => {
                    // Handle the successful response
                    const data = response.data.response
                    setMyinstance(data);
                });
        }
        loadInstance();
    }, []);

    return (
        <div className='flex w-full min-h-screen'>
            <Sidebar className='flex-shrink-0 h-full fixed' />
            <div className='flex flex-col w-full'>
                <div className={`${open ? "ml-[228px]" : "ml-[90px]"}`}>
                    <Navbar />
                </div>
                {/* Page Content */}
                <div className={`${open ? "ml-[228px]" : "ml-[90px]"} px-8 pt-24 mb-24`}>
                    {/* Your main content goes here */}
                    <Container>
                        <div className='flex justify-between py-6'>
                            <div>
                                <div className="flex items-center">
                                    <Link to={'/'}>
                                        <h2 className="text-2xl font-bold text-heading-black">AI Hub</h2>
                                    </Link>
                                    <MdKeyboardArrowRight className='text-2xl' />
                                    <Link to='/uploadfiles'>
                                        <h2 className="text-2xl font-bold text-heading-black">Synthetic Data Generation</h2>
                                    </Link >
                                </div>
                                <span className="text-base font-normal text-gray-70">Pick your applications and deploy in our platform</span>
                            </div>
                            <div
                                className='h-[52px] flex items-center hover:bg-[#8F27C11A] hover:border-[#8F27C10D] hover:text-[#8F27C1]  text-[#10001ACC] rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-[32px]  cursor-pointer'
                                onClick={() => navigate("/uploadfiles")}
                            >
                                <LuPlus />
                                <span>Add generation</span>
                            </div>
                        </div>

                        <table className="w-full">
                            <thead className='bg-table-header-bg-color'>
                                <tr>
                                    <th className="py-4 px-10 text-sm font-semibold text-left">Sample File</th>
                                    <th className="py-4 px-10 text-sm font-semibold text-left">Generated Date</th>
                                    <th className="py-4 px-10 text-sm font-semibold text-left">Generated File</th>
                                    <th className="py-4 px-10 text-sm font-semibold text-left">Generated File Size</th>
                                    <th className="py-4 px-10 text-sm font-semibold text-left">Report</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    myinstance?.map((ele, i) =>
                                        <tr key={i} className={`${i % 2 === 0 ? 'bg-white' : 'bg-light-gray-1'} w-full`}>
                                            <td className="py-4 px-10 text-sm font-normal">{ele?.filename}</td>
                                            <td className="py-4 px-10 text-sm font-normal">{ele?.generate_date_time.toUpperCase()}</td>
                                            <td className="py-4 px-10 text-sm font-normal flex gap-1">{ele?.generated_output_file_link}<GrDocumentCsv /></td>
                                            <td className="py-4 px-10 text-sm font-normal">
                                                {ele?.generated_csv_file_size}
                                            </td>
                                            <td className="py-4 px-10 text-sm font-normal flex gap-1">
                                                {ele?.report_file_link}<GrDocumentCsv />
                                            </td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                    </Container>
                </div>
                {/* Footer */}
                <div className={`${open ? "ml-[228px]" : "ml-[90px]"}`}>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default DataHistroy
