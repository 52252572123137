import { TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { ImSpinner3 } from "react-icons/im";
import { toast } from "react-toastify";
import { HiDownload } from "react-icons/hi";
import {
  getInstanceStatus,
  getInstanceStatusStopper,
  getStatusLight,
  getTextTo3D,
} from "../../../services/api";
import { Context } from "../../../Context";
import Slider from "../../Slider";
import axiosInstance from "../../../api/axios";
import AddCreditModal from "../../AddCreditModal";

const ThreeDimensional = ({ endpoint, isActive, ins_id, modelName }) => {
  const { setModelStatusLight } = useContext(Context);

  const [userQuery, setUserQuery] = useState("");
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [initialising, setInitialising] = useState(false);
  const [guidanceScale, setGuidanceScale] = useState(15);
  const [isOpen, setIsOpen] = useState(false);

  const checkInstance = async () => {
    setResponse("");
    if (!userQuery) {
      toast.warning("Query Required!");
      return;
    }
    setInitialising(true);
    setModelStatusLight("initialising");

    try {
      // Step 1: Check for available credits (GET request)
      const rescheck = await axiosInstance.get(
        `payments/availableCredit/?model-name=${modelName}`
      );

      if (rescheck.data.data.status) {
        // Step 2: Credit is available, proceed to deduct it (POST request)
        const deductCredit = await axiosInstance.post(
          "payments/availableCredit/",
          {
            model_name: modelName,
            add: false,
            sub: true,
            amount: 0,
          }
        );

        if (deductCredit.data.data.status) {
          // Proceed with the instance status check since credit deduction was successful
          const res = await getInstanceStatus({ instance_id: ins_id });
          console.log(res);

          if (res.status === 200) {
            setLoading(true);
            setInitialising(false);

            try {
              const responseLight = await getStatusLight({
                instance_id: ins_id,
              });
              setModelStatusLight(responseLight.data.status);
            } catch (error) {
              console.log(error.message);
              await refundCredit(); // Refund credit if something goes wrong
            }

            // Trigger handleClick for text generation
            handleClick(deductCredit);
          }
        } else {
          // Failed to deduct credit, stop further execution
          toast.error(deductCredit.data.message);
          setLoading(false);
          setInitialising(false);
          return false; // Stop further execution
        }
      } else {
        // If no credit, show warning and stop the process
        toast.error(rescheck.data.message);
        setIsOpen(true); // Open AddCreditModal when no credit

        setLoading(false);
        setInitialising(false);
        return false; // Stop further execution
      }
    } catch (error) {
      toast.error("Something Went Wrong,Please Try Again!");
      setLoading(false);
      setInitialising(false);
    }
  };

  // Function to refund credit
  const refundCredit = async () => {
    try {
      await axiosInstance.post("payments/availableCredit/", {
        model_name: modelName,
        add: true,
        sub: false,
        amount: 0,
      });
      console.log("Credit refunded successfully");
    } catch (error) {
      console.log("Failed to refund credit:", error.message);
    }
  };

  const handleClick = async (deductCredit) => {
    setResponse("");
    if (!userQuery) {
      toast.warning("Query Required!");
      return;
    }
    setResponse("");

    try {
      const response = await getTextTo3D(endpoint, {
        text_prompt: userQuery,
        guidance_scale: guidanceScale,
      });
      setResponse(response.data.html_file);
      toast.success(deductCredit.data.message);
      if (
        deductCredit.data.message ===
        "You have used 3 out of 3 free inferencing credits."
      ) {
        infInstanceStatus();
      }
    } catch (error) {
      console.error("Error generating model:", error);
      await refundCredit(); // Refund credit on error
    } finally {
      setLoading(false);
      const res = await getInstanceStatusStopper({ instance_id: ins_id });
      console.log(res);
    }
  };

  const infInstanceStatus = async () => {
    try {
      await axiosInstance.post("payments/updateStatus/", {
        all_free_credits_used: true,
      });
      console.log("Credit refunded successfully");
    } catch (error) {
      console.log("Failed to refund credit:", error.message);
    }
  };

  return (
    <div className="flex w-full h-full">
      {/* User Interaction Section */}
      <div className="relative w-1/2 h-full p-8">
        <p className="text-[16px] font-[600] mb-8">Generate Response</p>
        <p className="text-[15px] font-[500] -mt-6 mb-6">
          Running this model will deduct{" "}
          <span className="text-[#008B16] font-[800]">$0.01</span> / request
          from your balance.{" "}
        </p>
        <TextField
          className="w-full"
          sx={{ marginBottom: "5px" }}
          inputProps={{
            style: {
              height: "80px",
            },
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && e.shiftKey) {
              e.preventDefault();
              setUserQuery((prevValue) => prevValue + "\n");
            } else if (e.key === "Enter") {
              e.preventDefault();
              setUserQuery(e.target.value);
              checkInstance();
            }
          }}
          id="outlined-multiline-static"
          label="User Query"
          multiline
          rows={1}
          placeholder="shark"
          value={userQuery}
          onChange={(e) => setUserQuery(e.target.value)}
        />

        <Slider
          label={"Guidance Scale"}
          value={guidanceScale}
          setValue={setGuidanceScale}
          min={1}
          max={15}
          step={1}
          text={
            "This parameter controls how strongly the generated output should adhere to the provided prompt. Higher values lead to more pronounced influence of the prompt on the generated output."
          }
        />

        {loading ? (
          <button className="absolute -bottom-24 left-1/2 transform -translate-x-1/2 loading-button w-[136px] h-[52px] mx-auto text-center">
            <ImSpinner3 className="text-xl mr-1 animate-spin" />
            Running
          </button>
        ) : initialising ? (
          <button className="absolute -bottom-24 left-1/2 transform -translate-x-1/2 loading-button w-[136px] h-[52px] mx-auto">
            <ImSpinner3 className="text-xl mr-1 animate-spin" />
            Initializing
          </button>
        ) : (
          <button
            disabled={!isActive}
            className={`${
              isActive ? "classic-button" : "disabled-button"
            } w-[136px] h-[52px] mx-auto absolute -bottom-24 left-1/2 transform -translate-x-1/2`}
            onClick={() => checkInstance()}
          >
            Run
          </button>
        )}
        {initialising && (
          <p className="text-gen-color mt-4 text-[14px] font-[600] absolute -bottom-[150px] text-center p-1">
            The Model instance is initializing, This will take 1 to 2 minutes.
            Once the instance is active we will process your request
          </p>
        )}
      </div>
      {/* Response Section */}
      <div className="w-1/2 min-h-[538px] p-8 border-l-2 ">
        <p className="text-[16px] font-[600] mb-8">Response</p>
        {response && (
          <a href={response} download="result.html" className="">
            <button
              className={`mx-auto flex items-center justify-center hover:bg-[#8F27C11A] hover:border-[#8F27C10D] hover:text-[#8F27C1]  text-[#10001ACC]  rounded-[12px] border border-[#10001ACC] text-lg font-semibold min-w-[100px] mt-20 max-w-fit px-[30px] py-[10px] ${
                loading ? " cursor-not-allowed" : ""
              } `}
              disabled={loading}
            >
              Download
              <HiDownload className="text-purple-900 text-2xl ml-2" />
            </button>
          </a>
        )}
      </div>
      {/* Add Credit Modal */}
      <AddCreditModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default ThreeDimensional;
