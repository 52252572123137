import React, { useContext } from 'react'
// import { useNavigate } from 'react-router-dom';
import { Context } from '../../Context';
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';
import Footer from '../Footer/Footer';
import { Container } from '@mui/material';

function HelpHome() {
    const { open } = useContext(Context);
    // const navigate = useNavigate();
    const home = [
        {
            id: 1,
            name: 'AI Models Demo video',
            discription: 'Your visual guide to using our AI Models with ease. Lets dive in and explore the incredible features that make your experience truly exceptional.',
        },
        {
            id: 6,
            name: 'Launching an Instance',
            discription: 'A Guide on how to launch an instance on Qubrid AI Platform.',
        },
        {
            id: 4,
            name: 'Viewing And Deleting Instances',
            discription: 'Guide on how to View and Delete your Instances in Qubrid AI platform.',
        },
        {
            id: 7,
            name: 'Synthetic Data Generation',
            discription: 'A visual Guide on how you can use our Synthetic data generation application to create meaningful data to feed to your AI models.',
        },
        {
            id: 5,
            name: 'Enterprise Model Demo',
            discription: 'A visual guide on how to use Enterprise model in Qubrid AI platform.',
        },
        {
            id: 2,
            name: 'Cuquantum On Qubrid AI',
            discription: 'A Comprehensive Guide to Running CuQuantum on Our Platform',
        },
        {
            id: 3,
            name: 'Qubrid AI Platform Tutorial Docs',
            discription: 'Your Docs guide to mastering our platform with ease Lets dive in and explore the incredible features that make your experience truly exceptional.',
        },
    ];

    const handleButtonClick = (item) => {
        if (item === 1) {
            window.open('https://dihunicom-my.sharepoint.com/:v:/g/personal/nabhesh_somkuwar_qubrid_com/EdCz9ogJBiVCogbS2p2tTHMBquP3h3v9p72APL4oDA-_yA?nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJPbmVEcml2ZUZvckJ1c2luZXNzIiwicmVmZXJyYWxBcHBQbGF0Zm9ybSI6IldlYiIsInJlZmVycmFsTW9kZSI6InZpZXciLCJyZWZlcnJhbFZpZXciOiJNeUZpbGVzTGlua0NvcHkifX0&e=exIgHV', '_blank');
        } else if (item === 2) {
            window.open('https://helpversion.s3.us-east-2.amazonaws.com/cuQuantum+documentation.pdf', '_blank');

        } else if (item === 3) {
            window.open('https://dihunicom-my.sharepoint.com/:w:/g/personal/nabhesh_somkuwar_qubrid_com/Eb2ZEZnD9yJMuKF8qo3eiuQBTgo1Srzci7SBD1oAodMwew?e=NYO4hi', '_blank');
        } else if (item === 4) {
            window.open('https://dihunicom-my.sharepoint.com/:w:/g/personal/nabhesh_somkuwar_qubrid_com/EYsYxq1VmvpJpiJahaB3xcwBI8ZPqQzkjP96Jv1vfRZhoA?e=05ZdLz', '_blank')
        }
        else if (item === 5) {
            window.open('https://dihunicom-my.sharepoint.com/:v:/g/personal/nabhesh_somkuwar_qubrid_com/EXOjFRAVPLJGqgOLb-SCiE8B_IUVpu3Dx03vOVSqeNJnpA?nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJPbmVEcml2ZUZvckJ1c2luZXNzIiwicmVmZXJyYWxBcHBQbGF0Zm9ybSI6IldlYiIsInJlZmVycmFsTW9kZSI6InZpZXciLCJyZWZlcnJhbFZpZXciOiJNeUZpbGVzTGlua0NvcHkifX0&e=9GtcJ6', '_blank')
        }
        else if (item === 6) {
            window.open('https://dihunicom-my.sharepoint.com/:w:/g/personal/nabhesh_somkuwar_qubrid_com/EfAXRPFoEfFKg2jAojgcA6kBkciQ6uq3QAUBSw9NxZfNQg?e=W5r8sA', '_blank')
        }
        else if (item === 7) {
            window.open('https://dihunicom-my.sharepoint.com/:v:/g/personal/nabhesh_somkuwar_qubrid_com/EdwLcryJFGxEu9w1oZSLEB8BqBBlSbsL6o6NzXiEdbjURQ?nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJPbmVEcml2ZUZvckJ1c2luZXNzIiwicmVmZXJyYWxBcHBQbGF0Zm9ybSI6IldlYiIsInJlZmVycmFsTW9kZSI6InZpZXciLCJyZWZlcnJhbFZpZXciOiJNeUZpbGVzTGlua0NvcHkifX0&e=CYFVg7', '_blank')
        }
    }

    return (
        <div className='flex w-full min-h-screen'>
            <Sidebar className='flex-shrink-0 h-full fixed' />
            <div className='flex flex-col w-full'>
                <div className={`${open ? "ml-[228px]" : "ml-[90px]"}`}>
                    <Navbar className='flex-shrink-0 fixed w-full' />
                </div>
                {/* page content */}
                <div className={`${open ? "ml-[228px]" : "ml-[90px]"} px-8 pt-24 mb-24`}>
                    <Container>
                        {/* Your main content goes here */}
                        {/* <div className='flex justify-between pb-4'>
                        <div>
                            <h2 className="text-2xl font-bold text-heading-black">Help</h2>
                            <p className="text-base font-normal text-gray-70 pt-1">
                                Accelerate the Development and Deployment of your AI and Quantum Applications using Qubrid’s Hybrid Quantum-Classical Cloud Platform and Tools. Whether you are a New or Existing AI/ML Professional or a Quantum Researcher/Developer, we offer you the flexibility of developing and Running Applications on Classical CPU/GPU Infrastructure or Quantum Computing Infrastructure or a Hybrid Infrastructure. Here you will find a collection of CPU/GPU and Quantum Compute instances, Algorithms and Applications to help you move fast with your use cases.
                            </p>
                        </div>
                        </div> */}

                        <div className='text-center items-center  bg-gradient-pink-purple rounded-lg mb-9'>
                            <h4 className='font-bold text-2xl text-white pt-11'>Help Center</h4>
                            <p className='pt-3 font-medium text-xl text-white'>Service for knowledge & Service</p>
                            <div className='py-6'>
                                {/* <form className='w-1/2 m-auto'>
                                <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
                                <div className="relative">
                                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                        <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                        </svg>
                                    </div>
                                    <input type="search" id="default-search" className="block w-full p-4 pl-10 text-sm text-gray-900  rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500" placeholder="How can we help?" required />
                                </div>
                            </form> */}
                            </div>
                        </div>

                        {/* <Tabs /> */}
                        <div className="grid grid-cols-3 gap-4">
                            {home.map((item, index) => (
                                <div key={index} className="bg-white shadow rounded-lg flex flex-col justify-between cursor-pointer">
                                    <div>
                                        <div className='bg-modal-bg-color p-5'>
                                            <h2 className="font-semibold text-lg text-card-li-color">{item.name}</h2>
                                        </div>
                                        <p className="text-card-li-color p-5 font-normal text-base">{item.discription}</p>
                                    </div>
                                    <button
                                        type="button"
                                        className={`hover:bg-[#8F27C11A] hover:border-[#8F27C10D] hover:text-[#8F27C1]  text-[#10001ACC] mb-5 mx-auto   rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-[40px] py-[18px] `}

                                        onClick={() => handleButtonClick(item.id)}
                                    >
                                        Read
                                    </button>
                                </div>
                            ))}
                        </div>
                    </Container>
                </div>
                <div className={`${open ? "ml-[228px]" : "ml-[90px]"}`}>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default HelpHome;