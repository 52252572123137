import React from 'react'
import { getAuth, GoogleAuthProvider, signInWithPopup, GithubAuthProvider } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import google_login from './../assets/ui_img/google-login-img.png';
import github_login from './../assets/ui_img/github-login-img.png';
import { toast } from 'react-toastify';
import axiosInstance from '../api/axios';

function AuthBtn() {
  const navigate = useNavigate();
  const signInWithGoogle = async () => {
    const auth = getAuth();

    try {
      // Sign in with Google using Firebase
      const response = await signInWithPopup(auth, new GoogleAuthProvider());
      // Obtain the Firebase ID token from the response
      const idToken = response.user && (await response.user.getIdToken());

      // If you have the Firebase ID token, send it to your Flask backend
      if (idToken) {
        // Send the Firebase ID token to your Flask backend

        const backendResponse = await axiosInstance.post(`/user/login`, { idToken });
        // Handle the response from your Flask backend
        localStorage.setItem("token", backendResponse.data.token);
        // Navigate or perform other actions as needed
        navigate('/');
      }
    } catch (error) {
      console.error(error);
      toast(error.response.data.message);
    }
  };

  const signInWithGitHub = async () => {
    const auth = getAuth();

    try {
      // Sign in with Google using Firebase
      const response = await signInWithPopup(auth, new GithubAuthProvider());

      // Obtain the Firebase ID token from the response
      const idToken = response.user && (await response.user.getIdToken());

      // If you have the Firebase ID token, send it to your Flask backend
      if (idToken) {
        // Send the Firebase ID token to your Flask backend
        const backendResponse = await axiosInstance.post(`/user/login`, { idToken });

        // Handle the response from your Flask backend
        localStorage.setItem("token", backendResponse.data.token);
        // Navigate or perform other actions as needed
        navigate('/');
      }
    } catch (error) {
      console.error(error);
      toast(error.response.data.message);
    }
  };

  return (
    <div className='flex cursor-pointer justify-center w-max m-auto'>
      <img src={google_login} alt='google-login' onClick={() => signInWithGoogle()} />
      <img src={github_login} alt='github-login' onClick={() => signInWithGitHub()} />
    </div>
  )
}

export default AuthBtn
