import { Container } from '@mui/material'
import React, { useEffect } from 'react'
import AiCompute from './AiCompute';
import AiDataService from './AiDataService';
import AiModelStudio from './AiModelStudio';
import NowAvailable from './NowAvailable';
import AiQuantumEngine from './AiQuantumEngine';

function Index() {
    useEffect(() => {
        localStorage.removeItem('activeTab')
    }, []);
    return (
        <Container>
            <NowAvailable />
            <AiModelStudio />
            <AiCompute />
            <div className='flex gap-5 mt-3 w-full'>
                <div className='w-1/2'>
                    <AiDataService />
                </div>
                <div className='w-1/2'>
                    <AiQuantumEngine />
                </div>
            </div>
        </Container>
    )
}

export default Index