import React, { useState } from 'react';
import axiosInstance from '../../api/axios';
import { useForm, Controller } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { InputAdornment, IconButton, TextField, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import login_or_img from './../../assets/ui_img/login-or-line-img.svg';
import Slider from './Slider';
import AuthBtn from '../AuthBtn';
import qubrid_ai_logo from '../../assets/white-ai-logo.svg';
function Login() {
    const navigate = useNavigate();
    // const UserapiURL = process.env.REACT_APP_USER_SERVICE_URL;
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const [showPassword, setShowPassword] = useState(false);

    const onSubmit = async (data) => {
        try {
            const url = `/user/login`;
            console.log(url)
            const response = await axiosInstance.post(url, data);
            console.log(response)
            const {success} = response.data;

            if (success) {
                localStorage.setItem('token', '123456789');
                const loginTime = Date.now().toString();
                localStorage.setItem('loginTime', loginTime);
                navigate('/');
                // toast('User Login Success');
            } else {
                localStorage.setItem('token', '123456789');
                const loginTime = Date.now().toString();
                localStorage.setItem('loginTime', loginTime);
                navigate('/');
                // toast(message);
            }
        } catch (error) {
            toast("Login failed. Check email and/or password.");
        }
    };

    return (
        <div className="bg-gray-200 flex items-center justify-center h-screen">
            <div className='w-1200px h-height-xl'>
                <div className="flex bg-white shadow rounded-3xl w-full h-full">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='h-full flex flex-col justify-between'>
                            <div className="text-center mt-8">
                                <h5 className="text-3xl font-bold text-card-li-color">Sign in to your account</h5>
                                <p className="text-base font-medium text-card-li-color py-3">Please enter your details to sign in</p>
                            </div>

                            <div className="px-20">
                                <AuthBtn />
                                <div className="py-2">
                                    <img src={login_or_img} className='m-auto' alt='login_or_img' />
                                </div>

                                <div>
                                    <Controller
                                        name="email"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: 'Email is required',
                                            pattern: {
                                                value: /\S+@\S+\.\S+/,
                                                message: 'Invalid email address',
                                            },
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                id="outlined-basic"
                                                label="Email"
                                                variant="outlined"
                                                sx={{ width: '100%', }}
                                            />
                                        )}
                                    />
                                    {errors.email && <p className='text-sm font-semibold text-red-600'>{errors.email.message}</p>}

                                    <Controller
                                        name="password"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: 'Password is required',
                                            minLength: {
                                                value: 6,
                                                message: 'Password must be at least 6 characters',
                                            },
                                        }}

                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                id="outlined-password-input"
                                                label="Password"
                                                type={showPassword ? 'text' : 'password'}
                                                autoComplete="current-password"
                                                sx={{ width: '100%', marginTop: 2 }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => setShowPassword(!showPassword)}
                                                                edge="end"
                                                            >
                                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.password && <p className='text-sm font-semibold text-red-600'>{errors.password.message}</p>}

                                    <div className="flex justify-between">
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox defaultChecked />} label="Keep me signed in" />
                                        </FormGroup>
                                        <Link to="/forgetpass">
                                            <p className="py-2 text-base font-medium text-card-hover-text-color cursor-pointer underline">
                                                Forgot Password?
                                            </p>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <div className="bottom-0 px-20 pb-8">
                                <button className="general-button w-full">
                                    Sign In
                                </button>
                                <div className="flex justify-center gap-2.5">
                                    <p className="py-2 text-base font-medium text-card-li-color">New to Qubrid ?</p>
                                    <Link to="/signup" className="cursor-pointer">
                                        <p className="py-2 text-base font-medium text-card-hover-text-color underline">Create account</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="relative w-1/2 bg-img-login h-auto">
                        <div className="w-full h-2/3 flex flex-col justify-center items-center">
                            <img src={qubrid_ai_logo} alt="qubrid_login_img" />
                        </div>

                        <div className="absolute bottom-10 w-full">
                            <Slider />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
