import React from 'react';

const DialogueCard = ({ show, text }) => {

  return (
    <div className={`${show ? 'block' : 'hidden'} relative bg-white p-4 rounded-lg shadow-lg w-64 z-40`}>
      {/* Arrow pointing to the left */}
      <div className="absolute left-[-15px] top-1/2 transform -translate-y-1/2 w-0 h-0 border-t-[10px] border-t-transparent border-b-[10px] border-b-transparent border-r-[15px] border-r-white"></div>
      
      {/* Dialogue Text */}
      <p className="text-gray-700 font-medium text-sm">{text}</p>
    </div>
  );
};

export default DialogueCard;