import React, { useContext, useState } from 'react'
import { Context } from '../../../Context';
import { toast } from 'react-toastify';
import axios from 'axios';
import { MdClose } from 'react-icons/md';
import ModalTab from '../../Tabs/ModalTab';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../api/axios';

function Ionq({ listQpu }) {
  const { isLoading, setIsLoading, setPlanId, setModalInfo, freeShowModal, setFreeShowModal, setUpdateCredit } = useContext(Context);
  const [contactModal, setContactModal] = useState(false);
  const [contactMessage, setContactMessage] = useState('');
  const navigate = useNavigate();

  const handleOpen = async (ele) => {
    if (ele.is_active && ele.notebook_url) {
      setModalInfo(ele);
      // setFreeShowModal(true);
      setIsLoading(false);
      window.open(ele.notebook_url, '_blank');
      // sessionStorage.setItem('jupiterUrl', ele.notebook_url)
      // navigate('/jupyter')
    }
  };

  const handleRefreshToken = async () => {
    try {
      await axiosInstance.post('/user/refresh_token');
    } catch (error) {
      console.log('refresh token')
    }
  };

  const checkPlan = async (ele) => {
    try {
      // Make the request to ActivatePlan
      const response = await axiosInstance.post(`/payments/checkPlanCredit`, { plan: ele?.plan?.id });

      if (response.status === 200) {
        return true; // Activation successful
      } else {
        console.log('Error in ActivatePlan request');
        toast("Your plan is either already activated or you have less credit.");
        return false; // Activation failed
      }
    } catch (error) {
      console.log('Error in ActivatePlan:', error);
      toast("Your plan is either already activated or you have less credit.");
      if (error.response && error.response.status === 401) {
        const refreshed = await handleRefreshToken();
        if (refreshed) {
          checkPlan(ele)
        }
      } else {
        console.error('Error:', error);
      }
      return false; // Error occurred during activation
    }
  };

  const activatePlan = async (ele) => {
    try {
      // Make the request to ActivatePlan
      const response = await axiosInstance.post(`/payments/ActivatePlan`, { plan: ele?.plan?.id });

      if (response.status === 200) {
        setUpdateCredit(true);
        return true; // Activation successful
      } else {
        console.log('Error in ActivatePlan request');
        return false; // Activation failed
      }
    } catch (error) {
      console.log('Error in ActivatePlan:', error);
      if (error.response && error.response.status === 401) {
        const refreshed = await handleRefreshToken();
        if (refreshed) {
          activatePlan(ele)
        }
      } else {
        console.error('Error:', error);
      }
      return false; // Error occurred during activation
    }
  };

  const launchInstance = async (ele) => {
    // setIsLoading(true);
    setTimeout(() => {
      navigate('/myInstance');
    }, 1000);
    const requestData = {
      "allocatePublicIp": true,
      "instanceType": "qpu",
      "resourceLevel": 'q1',
      "plan": ele?.id,
      "plan_name": ele?.name,
      "arn": ele.feature.arn,
      "amount": ele?.credits
    };
    try {
      const secondResponse = await axiosInstance.post(`/manage/launch-instance`, requestData);

      if (secondResponse.status === 200) {
        console.log(secondResponse.data.notebookUrl);
        toast(secondResponse.data.message);
        // if (!secondResponse.data.success) {
        //   setFreeShowModal(false);
        // }
        // setIsLoading(false);
        // setFreeShowModal(false);
        // navigate('/myInstance');
        return secondResponse.data.notebookUrl; // Return the notebook URL
      } else {
        console.log('Error in launchInstance request');
        return null; // Failed to launch instance or no notebook URL
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const refreshed = await handleRefreshToken();
        if (refreshed) {
          launchInstance(ele)
        }
      } else {
        console.error('Error:', error);
      }
    }
  };

  const handleCPU = async (ele) => {
    setPlanId(ele.id);
    setModalInfo(ele);
    setIsLoading(true); // Indicate loading state
  
    try {
      // Attempt to activate the plan and get the notebook URL
      const notebookUrl = await launchInstance(ele);
  
      // If notebookUrl is returned, open it in a new tab
      if (notebookUrl) {
        window.open(notebookUrl, '_blank');
  
        
        
  
        // Optionally reload UI data
        // loadCard();  // Uncomment if you need to reload card data
      } else {
        throw new Error('Failed to launch instance');
      }
    } catch (error) {
      console.log('Error:', error);
    } finally {
      // Always turn off loading state
      setIsLoading(false);
    }
  };



  return (
    <>
      <div className='flex flex-wrap gap-5 pb-20'>
        {listQpu?.map((ele, i) => (
          ele.feature.provider_name === "IonQ" && (
            <div key={i} className="max-w-sm p-6 bg-white border border-gray-200 rounded-2xl shadow">
              <div className='flex'>
                <p className='text-sm font-semibold text-tab-active-color pr-2'>
                  Credits: {ele?.credits}
                </p>

                <div
                  className={`rounded-full ${ele.is_device_online === true ? "bg-online-bg" : "bg-ofline-bg"} p-1 w-1 h-1 mt-1.5 ml-1`}
                ></div>
              </div>

              <h5 className='text-card-li-color text-lg font-semibold'>{ele?.name}</h5>

              <p className='text-sm font-normal text-Quantum-pre-color'>IonQ’s aria trapped-ion quantum computer.</p>

              <div className="text-center mt-5">
                {ele.is_active ?
                  <button className="hover:bg-[#8F27C11A] hover:border-[#8F27C10D] hover:text-[#8F27C1]  text-[#10001ACC]   rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-[40px] py-[18px] " onClick={() => handleOpen(ele)}>
                    OPEN
                  </button> :
                  <button className="hover:bg-[#8F27C11A] hover:border-[#8F27C10D] hover:text-[#8F27C1]  text-[#10001ACC]   rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-[40px] py-[18px] " onClick={() => handleCPU(ele)}>
                    Launch Notebook
                  </button>
                }
              </div>

              {/* <div className="text-center mt-5">
                <button className="hover:bg-[#8F27C11A] hover:border-[#8F27C10D] hover:text-[#8F27C1]  text-[#10001ACC]   rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-[40px] py-[18px] ">Coming Soon</button>
              </div> */}
            </div>
          )
        ))}
      </div>

      {
        freeShowModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative my-6 mx-auto">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <div>
                      <h5 className="text-xl font-semibold">
                        QPU
                      </h5>
                    </div>

                    <button
                      className=" background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setFreeShowModal(false)}
                      disabled={isLoading}
                    >
                      <MdClose className='w-5 h-5' />
                    </button>

                  </div>
                  {/*body*/}
                  <div className="relative py-6 flex-auto">
                    <ModalTab />
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null
      }

      {contactModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative my-6 mx-auto">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-6 border-b border-solid border-slate-200 rounded-t">
                  <div>
                    <h5 className="text-xl font-semibold">
                      Contact
                    </h5>
                  </div>

                  <button
                    className=" background-transparent font-bold uppercase pt-1.5 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setContactModal(false)}
                  >
                    <MdClose className='w-5 h-5' />
                  </button>

                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <div className="text-xl font-medium text-black">
                    <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900">Your message</label>
                    <textarea
                      id="message"
                      rows="4"
                      value={contactMessage}
                      onChange={(event) => setContactMessage(event.target.value)}
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Write your thoughts here..."
                    >
                    </textarea>
                  </div>
                </div>
                {/* Footer */}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b gap-x-3">
                  <button
                    type="button"
                    className={`bg-page-bg-color text-tab-active-color  text-base font-semibold px-12 py-3 rounded-full`}
                    onClick={() => setContactModal(false)}
                  >
                    Cancel
                  </button>

                  <button
                    type="button"
                    className={`bg-gradient-pink-purple text-white text-base font-semibold px-12 py-3 rounded-full`}
                  // onClick={SendContactMessage}
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  )
}

export default Ionq
