import React, { useEffect, useState } from 'react';
import axiosInstance from '../../api/axios';

function AwsSimulator() {
  const [listCard, setListCard] = useState([]);
  const [error, setError] = useState(null);
  // const [isHovered, setIsHovered] = useState('');


  useEffect(() => {
    const PaymentapiURL = process.env.REACT_APP_PAYMENT_SERVICE_URL;
    const url = `${PaymentapiURL}/other-plans/`;
    const params = {
      type: 'braket simulator',
    };

    // Make the GET request using Axios
    axiosInstance
      .get(url, { params })
      .then((response) => {
        // Handle the successful response
        const data = response.data.data; // Assuming the response is an array
        console.log(data);
        
        // Process the data here and set it to the listCard state
        const processedData = data.map((plan) => {
          // Process plan data as needed
          const features = plan.features;
          const processedPlan = {
            id: plan.id,
            name: plan.name,
            price: plan.credits,
            // Add other properties as needed
            operatingSystem: features['Operating System'],
            aiPackages: features['Packages'],
            Instance: features["Instance"],
          };

          return processedPlan;
        });

        // Update the state with the processed data
        setListCard(processedData);
      })
      .catch((error) => {
        // Handle any errors that occur during the request
        console.error('API Request Error:', error);
        setError(error);
      });
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="grid grid-cols-3 gap-8 px-5">
      {listCard?.map((ele, index) => {
        return (
          <div
            key={ele.id} // Added a unique key prop for React rendering
            className="py-6 group bg-white w-full  rounded-3xl shadow"
          // onMouseEnter={() => setIsHovered(ele.id)}
          // onMouseLeave={() => setIsHovered('')}
          >
            <p className="text-sm font-semibold text-tab-active-color px-6 pb-6">
              {ele.name}
            </p>

            {/* <div className={`${isHovered === ele.id ? "bg-card-hover-color text-[#8F27C1]" : "bg-page-bg-color"} py-5 mb-5 text-center`}>
              <div>
               
                <span className='text-4xl font-bold'>{ele.price}</span>
              </div>
              <span className='text-base font-semibold'>Credits/min</span>
            </div> */}

            <div className={`group-hover:bg-[#8624C505] group-hover:text-[#8F27C1] bg-[#F8F9FD] py-5 mb-5 flex text-center justify-center`}>
              <div className='relative w-fit'>
                <p className='absolute top-1 text-2xl font-semibold'>$</p>
                <p className='text-[42px] px-5 font-bold'>{ele.price}</p>
                <p className='text-[16px] -mt-2 font-semibold'>Per Minute</p>
              </div>
            </div>



            <p className="text-sm font-normal text-Quantum-pre-color px-6">
              {ele.operatingSystem}
            </p>

            <p className="text-sm font-normal text-Quantum-pre-color px-6">
              {ele.aiPackages}
            </p>

            <div className="text-center mt-5">
              <button
                className='group-hover:bg-[#8F27C11A] group-hover:border-[#8F27C10D] group-hover:text-[#8F27C1] my-3 text-[#10001ACC]   rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-[40px] py-[18px] '
              >
                Coming Soon
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default AwsSimulator;
