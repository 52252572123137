import React, { useContext, useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md';
import axios from 'axios';
import { Context } from '../../Context';
import ModalTab from '../Tabs/ModalTab';
import { toast } from 'react-toastify';

function Cpu() {
    // const [isHovered, setIsHovered] = useState('');
    const { isLoading, setIsLoading, setPlanId, setModalInfo, freeShowModal, setFreeShowModal, deleteSuccess, setUpdateCredit, setAddCredits } = useContext(Context);
    const [listCard, setListCard] = useState([]);
    const [contactModal, setContactModal] = useState(false);
    const [contactMessage, setContactMessage] = useState('');
    const [contactInstanceID, setContactInstanceID] = useState();

    // const [openId, setOpenId] = useState('');
    // const [clickedId, setClickedId] = useState('');

    const apiURL = process.env.REACT_APP_BASE_URL;
    const PaymentapiURL = process.env.REACT_APP_PAYMENT_SERVICE_URL;
    const InstanceapiURL = process.env.REACT_APP_MANAGE_INSTANCE_SERVICE_URL;



    const loadCard = () => {
        // Define the URL with query parameters
        const token = localStorage.getItem('token');
        const url = `${PaymentapiURL}/combined-plans/`;
        const params = {
            plan_type: 'CPU'
        };
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        };

        // Make the GET request using Axios
        axios.get(url, {
            params: params,
            headers: headers,
        })
            .then(response => {
                // Handle the successful response
                const data = response.data;

                // Process the data here and set it to the listCard state
                const processedData = data.map(plan => {
                    // Process plan data as needed
                    const features = JSON.parse(plan.plan.features);
                    const processedPlan = {
                        id: plan.plan.id,
                        uuid: plan.plan.uuid,
                        name: plan.plan.name,
                        price: plan.plan.credits,
                        // Add other properties as needed
                        operatingSystem: features["Operating System"],
                        aiPackages: features["AI Packages"].join(', '),
                        languages: features["Languages"].join(', '),
                        Instance: features["Instance"],
                        available: features['available'],
                        // Add hardware details as needed
                        hardware: {
                            CPUs: features["Hardware"].CPUs,
                            RAM: features["Hardware"].RAM,
                            Storage: features["Hardware"].Storage,
                            GPUs: features["Hardware"].GPUs,
                            Network: features["Hardware"].Network,
                        },
                        is_active: plan.is_active,
                        notebook_url: plan.notebook_url,
                        is_Loding: plan.is_loading
                    };

                    return processedPlan;
                });
                // Update the state with the processed data
                setListCard(processedData);
                // sessionStorage.clear()
            })
            .catch(error => {
                // Handle any errors that occur during the request
                console.error('Error:', error);
            });
    }

    useEffect(() => {
        const loadCard = () => {
            // Define the URL with query parameters
            const token = localStorage.getItem('token');
            const url = `${PaymentapiURL}/combined-plans/`;
            const params = {
                plan_type: 'CPU'
            };
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            // Make the GET request using Axios
            axios.get(url, {
                params: params,
                headers: headers,
            })
                .then(response => {
                    // Handle the successful response
                    const data = response.data;

                    // Process the data here and set it to the listCard state
                    const processedData = data.map(plan => {
                        // Process plan data as needed
                        const features = JSON.parse(plan.plan.features);
                        const processedPlan = {
                            id: plan.plan.id,
                            uuid: plan.plan.uuid,
                            name: plan.plan.name,
                            price: plan.plan.credits,
                            // Add other properties as needed
                            operatingSystem: features["Operating System"],
                            aiPackages: features["AI Packages"].join(', '),
                            languages: features["Languages"].join(', '),
                            Instance: features["Instance"],
                            available: features['available'],
                            // Add hardware details as needed
                            hardware: {
                                CPUs: features["Hardware"].CPUs,
                                RAM: features["Hardware"].RAM,
                                Storage: features["Hardware"].Storage,
                                GPUs: features["Hardware"].GPUs,
                                Network: features["Hardware"].Network,
                            },
                            is_active: plan.is_active,
                            notebook_url: plan.notebook_url,
                            is_Loding: plan.is_loading
                        };
                        return processedPlan;
                    });
                    // Update the state with the processed data
                    setListCard(processedData);
                    // sessionStorage.clear()
                })
                .catch(error => {
                    // Handle any errors that occur during the request
                    console.error('Error:', error);
                });
        }
        loadCard();
    }, [PaymentapiURL, apiURL, deleteSuccess]);


    const handleOpen = async (ele, token) => {
        if (ele.is_active && ele.notebook_url) {
            setModalInfo(ele);
            // setFreeShowModal(true);
            setIsLoading(false);
            window.open(ele.notebook_url, '_blank');
            // sessionStorage.setItem('jupiterUrl', ele.notebook_url)
            // navigate('/jupyter')
        }
    };

    const handleStart = async (ele) => {
        setFreeShowModal(true);
        setModalInfo(ele);
        setIsLoading(true);
        try {
            const token = localStorage.getItem('token');
            // Make the request to ActivatePlan
            const response = await axios.post(`${InstanceapiURL}/start-instance`, null, {
                headers: {
                    'x-access-token': token
                }
            });

            if (response.status === 200) {
                toast(response.data.message);
                setIsLoading(false);
                // console.log(response)
                // console.log(response.data.notebookUrl)
                window.open(response.data.notebookUrl, '_blank');
                // sessionStorage.setItem('jupiterUrl', response.data.notebookUrl)
                // navigate('/jupyter')
                // return true; // Activation successful
                axios.post(`${PaymentapiURL}/save-notebook-url`, { plan: ele.id, notebook_url: response.data.notebookUrl }, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                })
                    .then(secondResponse => {
                        // console.log(secondResponse.data);
                        // Handle the response from the second API call
                    })
                    .catch(secondError => {
                        console.error('Second API call error', secondError);
                        // toast('Something went wrong with the second API call');
                    });
            } else {
                console.log('Error in ActivatePlan request');
                return false; // Activation failed
            }
        } catch (error) {
            console.log('Error in ActivatePlan:', error);
            return false; // Error occurred during activation
        }
    };

    const checkPlan = async (ele, token) => {
        // setDeployId(ele.id);
        try {
            // Make the request to ActivatePlan
            const response = await axios.post(`${PaymentapiURL}/checkPlanCredit`, { plan: ele.id }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });

            if (response.status === 200) {
                // setDeploy(true);
                return true; // Activation successful
            } else {
                console.log('Error in ActivatePlan request');
                toast("Your plan is either already activated or you have less credit.");
                // setDeploy(false);
                return false; // Activation failed
            }
        } catch (error) {
            console.log('Error in ActivatePlan:', error);
            toast("Your plan is either already activated or you have less credit.");
            setAddCredits(true);
            return false; // Error occurred during activation
        }
    };

    const activatePlan = async (ele, token) => {
        try {
            // Make the request to ActivatePlan
            const response = await axios.post(`${PaymentapiURL}/ActivatePlan`, { plan: ele.id }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });

            if (response.status === 200) {
                setUpdateCredit(true);
                return true; // Activation successful
            } else {
                console.log('Error in ActivatePlan request');
                return false; // Activation failed
            }
        } catch (error) {
            console.log('Error in ActivatePlan:', error);
            return false; // Error occurred during activation
        }
    };

    const launchInstance = async (ele, token) => {
        setIsLoading(true);
        const requestData = {
            "allocatePublicIp": true,
            "instanceType": "cpu",
            "resourceLevel": ele.Instance,
            "plan": ele.id,
            "plan_name": ele.name,
        };

        const secondResponse = await axios.post(`${InstanceapiURL}/launch-instance`, requestData, {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json',
            }
        });

        if (secondResponse.status === 200) {
            // console.log(secondResponse.data.notebookUrl);
            toast(secondResponse.data.message);
            if (!secondResponse.data.success) {
                setFreeShowModal(false);
            }
            setFreeShowModal(false);
            setIsLoading(false);
            // setDeploy(false);
            return secondResponse.data.notebookUrl; // Return the notebook URL
        } else {
            console.log('Error in launchInstance request');
            // setDeploy(false);
            return null; // Failed to launch instance or no notebook URL
        }
    };

    const handleCPU = async (ele) => {
        setPlanId(ele.id);
        sessionStorage.setItem('Clicked_id', ele.id)
        // setModalInfo(ele);
        try {
            const token = localStorage.getItem('token');

            // Check if the plan is active and has a notebook URL
            // console.log(ele.is_active && ele.notebook_url)
            // if (ele.is_active && ele.notebook_url) {
            //     console.log('gggggggggg')
            //     window.open(ele.notebook_url, '_blank');
            // } else 
            {
                // Attempt to activate the plan
                const isCheckCredit = await checkPlan(ele, token)
                // const isActivated = await activatePlan(ele, token);
                if (!isCheckCredit) {
                    sessionStorage.removeItem('Clicked_id')
                }

                if (isCheckCredit) {
                    // setFreeShowModal(true);
                    // Launch the instance
                    const notebookUrl = await launchInstance(ele, token);
                    localStorage.setItem('Cpu', ele.id)
                    if (notebookUrl) {
                        sessionStorage.removeItem('Clicked_id')
                        // Open the notebook URL
                        window.open(notebookUrl, '_blank');
                        setFreeShowModal(false)
                        // sessionStorage.setItem('jupiterUrl', notebookUrl)
                        // navigate('/jupyter')
                        await activatePlan(ele, token);
                        // console.log(notebookUrl, 'sending to save notebook url')
                        // Save the notebook URL on the backend
                        const saveNoteBookUrl = await axios.post(`${PaymentapiURL}/save-notebook-url`, { plan: ele.id, notebook_url: notebookUrl }, {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            }
                        });
                        if (saveNoteBookUrl.status === 200) {
                            loadCard();
                        }
                    }
                }
            }

            setIsLoading(false);

        } catch (error) {
            console.log('Error:', error);
            setIsLoading(false);
        }
    };

    // Function to handle "Reserve" action
    const handleContactUs = (ele) => {
        setContactModal(true);
        // console.log(ele.id);
        setContactInstanceID(ele.id);
    };

    const SendContactMessage = async () => {
        try {
            // Make the request to ActivatePlan
            const token = localStorage.getItem('token');

            const response = await axios.post(`${PaymentapiURL}/contact-us`, { plan: contactInstanceID, message: JSON.stringify(contactMessage) }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });

            if (response.status === 200) {
                setContactMessage('');
                setContactModal(false);
                toast("Succesfully Send Your Query");
                return true; // Activation successful
            } else {
                console.log('Error in ActivatePlan request');
                return false; // Activation failed
            }
        } catch (error) {
            console.log('Error in ActivatePlan:', error);
            toast('Something Wrong Message Not Sent');
            setContactMessage('');
            return false; // Error occurred during activation
        }
    };

    useEffect(() => {
        const getClickedId = sessionStorage.getItem('Clicked_id');
        listCard.forEach(ele => {
            if (+getClickedId === ele?.id) {
                if (!ele?.is_Loding) {
                    sessionStorage.removeItem('Clicked_id')
                }
            }
        });
    }, [listCard]);

    return (
        <>
            <div className="grid grid-cols-3 gap-8 px-5">
                {listCard?.map((ele, index) => {
                    return (
                        <div className="group bg-white w-full md:h-instance-card-height rounded-3xl shadow relative"
                            // onMouseEnter={() => setIsHovered(ele.id)}
                            // onMouseLeave={() => setIsHovered('')}
                            key={ele.id}

                        >
                            <div
                                // className={`${isHovered === ele.id ? " text-card-hover-text-color" : ""} py-8 text-center`}
                                className='py-8 text-center'
                            >
                                <span className="font-semibold text-lg">{ele.name}</span>
                            </div>

                            <div className={`group-hover:bg-[#8624C505] group-hover:text-[#8F27C1] bg-[#F8F9FD] py-5 mb-5 flex text-center justify-center`}>
                                <div className='relative w-fit'>
                                    <p className='absolute top-1 text-2xl font-semibold'>$</p>
                                    <p className='text-[42px] px-5 font-bold'>{ele.price}</p>
                                    <p className='text-[16px] -mt-2 font-semibold'>Per Hour</p>
                                </div>
                            </div>

                            <ul className="space-y-3 px-6 my-2 text-card-li-color">

                                <li className="flex space-x-3 items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                        <circle cx="3" cy="3" r="3" fill="#2E1F15" />
                                    </svg>
                                    <span className="text-[14px] font-semibold">{ele.hardware.CPUs}</span>
                                </li>
                                <li className="flex space-x-3 items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                        <circle cx="3" cy="3" r="3" fill="#2E1F15" />
                                    </svg>
                                    <span className="text-[14px] font-semibold">{ele.hardware.Storage}</span>
                                </li>
                                <li className="flex space-x-3 items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                        <circle cx="3" cy="3" r="3" fill="#2E1F15" />
                                    </svg>
                                    <span className="text-sm font-semibold">{ele.hardware.Network}</span>
                                </li>
                                <li className="flex space-x-3 items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                        <circle cx="3" cy="3" r="3" fill="#2E1F15" />
                                    </svg>
                                    <span className="text-[14px] font-semibold">{ele.operatingSystem}</span>
                                </li>
                                <li className="flex space-x-3 items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                        <circle cx="3" cy="3" r="3" fill="#2E1F15" />
                                    </svg>
                                    <span className="text-[14px] font-semibold">{ele.aiPackages}</span>
                                </li>
                                <li className="flex space-x-3 items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                        <circle cx="3" cy="3" r="3" fill="#2E1F15" />
                                    </svg>
                                    <span className="text-[14px] font-semibold">{ele.languages}</span>
                                </li>
                            </ul>
                            {/* <div className='flex justify-center items-center'>
                                {ele.available === 'yes' ? (
                                    <>
                                        {ele.notebook_url === 'stop' ? (
                                            <button className={`${isHovered === ele.id ? "bg-gradient-pink-purple text-white" : "bg-page-bg-color"
                                                } absolute bottom-0 my-5 text-base font-semibold px-16 py-5 rounded-full`} onClick={() => handleStart(ele)}>
                                                <span>START</span>
                                            </button>
                                        ) : (
                                            ele.notebook_url ? (
                                                <button className={`${isHovered === ele.id ? "bg-gradient-pink-purple text-white" : "bg-page-bg-color"
                                                    } absolute bottom-0 my-5 text-base text-xl font-semibold px-16 py-5 rounded-full`} onClick={() => handleOpen(ele)}>
                                                    <span>OPEN</span>
                                                </button>
                                            ) : (
                                                <button className={`${isHovered === ele.id ? "bg-gradient-pink-purple text-white" : "bg-page-bg-color"
                                                    } absolute bottom-0 my-5 text-base text-xl font-semibold px-16 py-5 rounded-full`} onClick={() => handleCPU(ele)}>
                                                    <span>{deployId === ele.id && deploy ? 'Launching...' : 'Launch Notebook'}</span>
                                                </button>
                                            )
                                        )}
                                    </>
                                ) : (
                                    <button className={`${isHovered === ele.id ? "bg-gradient-pink-purple text-white" : "bg-page-bg-color"
                                        } absolute bottom-0 my-5 text-base font-semibold px-16 py-5 rounded-full`} onClick={() => handleContactUs(ele)}>
                                        Reserve
                                    </button>
                                )}
                            </div> */}

                            <div className='flex justify-center items-center'>
                                <>
                                    {ele.available === 'yes' ?
                                        <>
                                            {ele.notebook_url === 'stop' ?
                                                (
                                                    <button
                                                        className='group-hover:bg-[#8F27C11A] group-hover:border-[#8F27C10D] group-hover:text-[#8F27C1]  text-[#10001ACC] absolute bottom-0 my-5 rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-[40px] py-[18px] '
                                                        onClick={() => handleStart(ele)}>
                                                        <span>START</span>
                                                    </button>
                                                )
                                                :
                                                <>
                                                    {ele.notebook_url ?
                                                        (
                                                            <button
                                                                className='group-hover:bg-[#8F27C11A] group-hover:border-[#8F27C10D]   group-hover:text-[#8F27C1] text-[#10001ACC] absolute bottom-0 my-5 rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-[40px] py-[18px] '
                                                                onClick={() => handleOpen(ele)}>
                                                                <span>Open</span>
                                                            </button>
                                                        )
                                                        :
                                                        <>
                                                            {ele.id === parseInt(sessionStorage.getItem('Clicked_id')) ?
                                                                (
                                                                    <button
                                                                        className='bg-[#8F27C11A] border-[#8F27C10D] text-[#8F27C1]  absolute bottom-0 my-5 rounded-[12px] text-lg font-semibold px-[40px] py-[18px] '
                                                                        onClick={() => handleCPU(ele)}
                                                                        disabled={sessionStorage.getItem('Clicked_id') ? true : false}>
                                                                        <div className="flex items-center justify-center ">
                                                                            <svg className="animate-spin h-5 w-5 mr-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.96 7.96 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zm5.195 2.647A8.034 8.034 0 0112 20c4.418 0 8-3.582 8-8h-4c0 2.761-2.239 5-5 5a4.998 4.998 0 01-4.805-3.653z"></path></svg>
                                                                            {sessionStorage.getItem('Clicked_id') && <span>Launching...</span>}
                                                                        </div>
                                                                    </button>
                                                                )
                                                                :
                                                                <>
                                                                    {
                                                                        <button
                                                                            className={`${!sessionStorage.getItem('Clicked_id') ? "group-hover:bg-[#8F27C11A] group-hover:border-[#8F27C10D] group-hover:text-[#8F27C1]" : ""}   text-[#10001ACC] absolute bottom-0 my-5 rounded-[12px]  border border-[#10001ACC] text-lg font-semibold px-[40px] py-[18px] `}
                                                                            onClick={() => handleCPU(ele)}
                                                                            disabled={sessionStorage.getItem('Clicked_id') ? true : false}>
                                                                            <span>Launch Notebook</span>
                                                                        </button>
                                                                    }
                                                                </>


                                                            }
                                                        </>
                                                    }
                                                </>
                                            }
                                        </>
                                        :
                                        (
                                            <button
                                                className='group-hover:bg-[#8F27C11A] group-hover:border-[#8F27C10D] group-hover:text-[#8F27C1]  text-[#10001ACC] absolute bottom-0 my-5 rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-[40px] py-[18px] '
                                                onClick={() => handleContactUs(ele)}>
                                                <span>Reserve</span>
                                            </button>
                                        )
                                    }
                                </>
                            </div>


                        </div>
                    )
                })}
            </div>
            {/* <iframe src="https://18.212.112.134:8888/lab" width="100%" height="450"></iframe> */}
            {
                freeShowModal ? (
                    <>
                        <div
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                        >
                            <div className="relative my-6 mx-auto">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                        <div>
                                            <h5 className="text-xl font-semibold">
                                                CPU
                                            </h5>
                                        </div>

                                        <button
                                            className=" background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => setFreeShowModal(false)}
                                            disabled={isLoading}
                                        >
                                            <MdClose className='w-5 h-5' />
                                        </button>

                                    </div>
                                    {/*body*/}
                                    <div className="relative py-6 flex-auto">
                                        <ModalTab />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null
            }

            {
                contactModal ? (
                    <>
                        <div
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                        >
                            <div className="relative my-6 mx-auto">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-6 border-b border-solid border-slate-200 rounded-t">
                                        <div>
                                            <h5 className="text-xl font-semibold">
                                                Contact
                                            </h5>
                                        </div>

                                        <button
                                            className=" background-transparent font-bold uppercase pt-1.5 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => setContactModal(false)}
                                        >
                                            <MdClose className='w-5 h-5' />
                                        </button>

                                    </div>
                                    {/*body*/}
                                    <div className="relative p-6 flex-auto">
                                        <div className="text-xl font-medium text-black">
                                            <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900">Your message</label>
                                            <textarea
                                                id="message"
                                                rows="4"
                                                value={contactMessage}
                                                onChange={(event) => setContactMessage(event.target.value)}
                                                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                                placeholder="Write your thoughts here..."
                                            >
                                            </textarea>
                                        </div>
                                    </div>
                                    {/* Footer */}
                                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b gap-x-3">
                                        <button
                                            type="button"
                                            className={`bg-page-bg-color text-tab-active-color  text-base font-semibold px-12 py-3 rounded-full`}
                                            onClick={() => setContactModal(false)}
                                        >
                                            Cancel
                                        </button>

                                        <button
                                            type="button"
                                            className={`bg-gradient-pink-purple text-white text-base font-semibold px-12 py-3 rounded-full`}
                                            onClick={SendContactMessage}
                                        >
                                            Send
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null
            }
        </>
    )
}

export default Cpu
