import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { Context } from '../../Context'
import { MdClose } from 'react-icons/md';

function Footer() {
    const { open } = useContext(Context);
    const [agreement, setAgreement] = useState(false);
    return (
        <>
            <footer
                className={`${open ? "nav-width-open" : "nav-width-close"} fixed z-50 bottom-0 px-7 bg-white`}
            >

                <div className="w-full mx-auto py-5 flex justify-between">
                    <span className="text-base font-normal text-footer-text-color">
                        Copyright © {new Date().getFullYear()} Qubrid AI - Train | Tune | Deploy
                    </span>
                    <ul className="flex gap-x-8 text-base font-normal text-footer-text-color">
                        <li className='hover:text-card-hover-text-color'>
                            <a href='https://www.qubrid.com/about/' target="_blank" rel="noopener noreferrer">
                                About
                            </a>
                        </li>
                        <li className='hover:text-card-hover-text-color'>
                            <a href=' https://www.qubrid.com/contact/' target="_blank" rel="noopener noreferrer">
                                Contact
                            </a>
                        </li>

                        <li className='hover:text-card-hover-text-color'>
                            <Link onClick={() => setAgreement(true)}>Terms & conditions</Link>
                        </li>
                    </ul>
                </div>
            </footer>
            {
                agreement ? (
                    <>
                        <div
                            className="justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none w-2/3 m-auto"
                        >
                            <div className="relative my-6 mx-auto">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-6 border-b border-solid border-slate-200 rounded-t">
                                        <div>
                                            <h5 className="text-xl font-semibold">
                                                Terms & conditions
                                            </h5>
                                        </div>

                                        <button
                                            className=" background-transparent font-bold uppercase pt-1.5 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => setAgreement(false)}
                                        >
                                            <MdClose className='w-5 h-5' />
                                        </button>
                                    </div>
                                    {/*body*/}
                                    <div className="relative p-6 flex-auto h-70vh overflow-y-auto">
                                        <div className="text-xl font-semibold text-black text-center mb-4">
                                            <span>Qubrid End User Agreement</span>
                                        </div>
                                        <p>This Qubrid End User Agreement is between the End User (“Customer”), and Qubrid. By accepting this Agreement, Customer accepts the terms of this Agreement:</p>
                                        <div className='my-2'>
                                            <div className="text-xl font-semibold text-black">
                                                <span>Services Offered</span>
                                            </div>
                                            <p>Qubrid will offer cloud services to the Customer. Such Cloud Services will consist of but not limited to Cloud based CPU and GPU instances, access to Quantum Computing systems and the hardware, software, and applications required to use them, programming tools, Quantum Computing software development kits SDK, application programming interfaces (“API”), Quantum Simulators and other related services.</p>
                                        </div>
                                        <div>
                                            <div className="text-xl font-semibold text-black">
                                                <span>Order Acceptance and Payment Terms</span>
                                            </div>
                                            <p>When you place an order with Qubrid, it does not constitute an automatic commitment or confirmation of our offer to sell. Qubrid reserves the right at any time after receipt of your order to accept or decline your order for any reason. All orders will be fulfilled only after confirmation and agreement on acceptable method of payment by our credit and finance department. Qubrid can cancel any order at any time due to product short supply, recognition of fraudulent transactions or for other reasons deemed suitable by the company. In situations where Qubrid cancels the order prior to fulfillment, except for suspected fraudulent transactions, Qubrid will promptly refund the order amount through the payment method that was used during ordering. Qubrid offers multiple methods of payments such as credit card, wire transfer, net terms etc depending on type of entity, credit profile etc. Customer shall bear all applicable federal, state, municipal and other government taxes (such as sales, use, and similar taxes). We accept major credit cards, including Visa, MasterCard, Discover and American Express. Government, Education and Company Purchase Orders are accepted, subject to account verification. </p>
                                        </div>
                                        <div className='my-2'>
                                            <div className="text-xl font-semibold text-black">
                                                <span>License</span>
                                            </div>
                                            <p>For the term of this Agreement, Qubrid grants the Customer a limited, nonexclusive, nontransferable license to access and use Qubrid Cloud Platform services.</p>
                                        </div>
                                        <div>
                                            <div className="text-xl font-semibold text-black">
                                                <span>Cloud Credits</span>
                                            </div>
                                            <p>All cloud credits offered by Qubrid or purchased by Customers will have a maximum validity of 12 months unless there is a separate agreement with the Customer for longer term reserved cloud instances.  Credits have no refund value and are only to be used to deploy cloud or other Qubrid instances and applications and are revocable for abuse, misrepresentation of account information, unauthorized transfer, or illegal conduct. </p>
                                        </div>
                                        <div className='my-2'>
                                            <div className="text-xl font-semibold text-black">
                                                <span>Intellectual Property</span>
                                            </div>
                                            <p className='my-2'>Customer retains all rights to the Intellectual Property as developed by the Customer on Qubrid platform including algorithms and applications and as related to patents, copyright, trademark or other intellectual property rights. All intellectual property rights and title to pre-existing materials are unaffected by this Agreement. </p>
                                            <p>Qubrid retains all right, title, and interest in and to the Qubrid Intellectial Property, including cloud architecture, pricing structure, software code, algorithms developed by Qubrid etc and Customer will have no title or ownership to Qubrid Intellectual Property.</p>
                                        </div>
                                        <div>
                                            <div className="text-xl font-semibold text-black">
                                                <span>Data Protection and Platform Use</span>
                                            </div>
                                            <p className='my-2'>Customer acknowledges and agrees that Qubrid will be hosting cloud services in United States or other geographies using it’s own or partner datacenters including that of public cloud providers. Customer agrees to the hosting of their applications, algorithms and data in the United States. Qubrid may transfer such data to other geographic regions or jurisdictions at its sole Customer represents and warrants that such hosting and transfer are adequately consented to by Customer and permissible pursuant to applicable laws, regulations, rules, and third party rights. Qubrid will implement reasonable and appropriate measures to secure the Customer data against accidental or unauthorized access, transmission, loss or disclosure. Qubrid will not take any responsibility of loss of Customer data due to bad actors or Act of God. Unless otherwise expressly specified by an applicable Order, Qubrid shall have no obligation to maintain Customer’s property, backup on behalf of Customer or any user.</p>
                                            <p>Customer shall ensure that the Cloud Services are properly configured for its purposes and shall take reasonable steps for securing and protecting Customer data. Customer shall be responsible and liable for the activities of any individual or entity who gains access to Customer Data or the Qubrid Services as a result of its failure to comply with the obligations of this clause. Customer shall be responsible for ensuring that all third-party tools, content and otherwise components added by Customer to the Cloud Services shall conform with the terms of the Agreement. </p>
                                        </div>

                                        <div className='my-2'>
                                            <div className="text-xl font-semibold text-black">
                                                <span>Termination</span>
                                            </div>
                                            <p className='my-2'>Either Party may terminate this Agreement and/or any Order by providing written notice to the other Party in the event the other Party materially breaches any of its duties, obligations or responsibilities under this Agreement and fails to cure such breach within thirty days after receipt by the breaching Party of written notice specifying the breach.</p>
                                        </div>

                                        <div className='my-2'>
                                            <div className="text-xl font-semibold text-black">
                                                <span>Privacy Policy</span>
                                            </div>
                                            <p className='my-2'>At Qubrid we collect Buyer’s Name, Contact Information, Billing Address, Shipping Address, Order Details from our Customers. Any information shared with our suppliers and technology providers is for the sole intention of order fulfillment. If you choose to create an online account, your user name and password remain confidential and please DO NOT share this information with anyone. If you elect to receive our newsletter or special promotions, your contact information will be used for the delivery of these items. For some social proof/credibility applications for marketing purposes, we may use logos, first name and locations of Customers without ever using full address and confidential information. For advertising and fulfillment purposes, we may allow third-party companies to collect non-personally identifiable information when Customers visit our site.</p>
                                        </div>

                                        <div>
                                            <div className="text-xl font-semibold text-black">
                                                <span>Limitation of Liability</span>
                                            </div>
                                            <p className='my-2'>In no event will Qubrid be liable for direct, special, incidental, or consequential damages however caused and regardless of the theory of liability including, but not limited to, lost profits, fraud of any type, downtime, goodwill, damage to or replacement of equipment and property, and any costs of recovering, reprogramming, or reproducing any document or data stored in or used with a system containing these services.</p>
                                        </div>

                                        <div>
                                            <div className="text-xl font-semibold text-black">
                                                <span>Applicable Law</span>
                                            </div>
                                            <p className='my-2'>All sales, transactions, and/or disputes between Qubrid and the Customer shall be governed by and construed in accordance with laws of the state of Virginia, U.S.A.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null
            }
        </>
    )
}

export default Footer