import React, { useEffect, useState } from 'react';
import SpeechToText from './Categories/SpeechToText';
import ObjectDetection from './Categories/ObjectDetection';
import ImageGeneration from './Categories/ImageGeneration';
import TextGeneration from './Categories/TextGeneration';
import ThreeDimensional from './Categories/ThreeDimensional';
import TextToVideo from './Categories/TextToVideo';
import ComingSoon from './Categories/ComingSoon';
import ImageToText from './Categories/ImageToText';
import TextToCode from './Categories/TextToCode';

const ModelRun = ({ category, modelName, endpoint, isActive, ins_id, model_full_name }) => {
    const [showModel, setShowModel] = useState(null);

    useEffect(() => {
        async function fetchModelComponent() {
            switch (category) {
                case "Speech to Text":
                    setShowModel(<SpeechToText endpoint={endpoint} isActive={isActive} ins_id={ins_id} modelName={modelName} />);
                    break;
                case "Object Detection":
                    setShowModel(<ObjectDetection endpoint={endpoint} isActive={isActive} ins_id={ins_id} modelName={modelName} />);
                    break;
                case "Image Generation":
                    setShowModel(<ImageGeneration endpoint={endpoint} isActive={isActive} ins_id={ins_id} modelName={modelName} />);
                    break;
                case "Text Generation":
                    setShowModel(<TextGeneration endpoint={endpoint} isActive={isActive} ins_id={ins_id} model_full_name={model_full_name} modelName={modelName} />);
                    break;
                case "Text to 3D":
                    setShowModel(<ThreeDimensional endpoint={endpoint} isActive={isActive} ins_id={ins_id} modelName={modelName} />);
                    break;
                case "Text To Video":
                    setShowModel(<TextToVideo modelName={modelName} endpoint={endpoint} isActive={isActive} ins_id={ins_id} />);
                    break;
                case "Image-Text to Text":
                    setShowModel(<ImageToText endpoint={endpoint} isActive={isActive} ins_id={ins_id} modelName={modelName} />);
                    break;
                case "Code Generation":
                    setShowModel(<TextToCode endpoint={endpoint} isActive={isActive} ins_id={ins_id} model_full_name={model_full_name} modelName={modelName} />);
                    break;
                default:
                    setShowModel(<ComingSoon />);
                    break;
            }
        }

        fetchModelComponent();

    }, [category, endpoint, isActive, ins_id, model_full_name, modelName]);

    return (
        <div className="min-h-[538px] w-full bg-white shadow-md">
            {showModel}
        </div>
    );
};

export default ModelRun;
