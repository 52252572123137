import React, { useEffect, useState } from 'react'
import NvidiaGpuSimulater from './NvidiaGpuSimulater';
import AwsSimulater from './AwsSimulater';

function LaunchSimulater() {
    const [activeTab, setActiveTab] = useState(parseInt(localStorage.getItem('activeTabSimulater')) || 1);
    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber);
        localStorage.setItem('activeTabSimulater', tabNumber);
        window.location.hash = `#tab_${tabNumber}`;
    };
    useEffect(() => {
        const handleHashChange = () => {
            const hash = window.location.hash.slice(5);
            const tabNumber = parseInt(hash) || 1;
            setActiveTab(tabNumber);
            // Save the active tab to localStorage
            localStorage.setItem('activeTabSimulater', tabNumber);
        };

        window.addEventListener('hashchange', handleHashChange);

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);

    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full">
                    <ul
                        className="flex list-none flex-wrap pt-3  flex-row m-auto w-2/4"
                        role="tablist"
                    >
                        <li className="-mb-px  last:mr-0 flex-auto text-center cursor-pointer">
                            <div
                                className={
                                    "text-lg font-semibold  py-7  rounded-t-xl " +
                                    (activeTab === 1 ? 'active text-tab-active-color bg-white z-50 ' : 'text-gray-60 bg-[#f2f3f8] translate-y-[10%] rounded-tr-none  z-10 ')
                                }
                                onClick={() => handleTabClick(1)}
                            >
                                NVIDIA CuQuantum GPU Simulator
                            </div>
                        </li>


                        <li className="-mb-px  last:mr-0 flex-auto text-center cursor-pointer">
                            <div
                                className={
                                    "text-lg font-semibold py-7 rounded-t-xl " +
                                    (activeTab === 2 ? 'active text-tab-active-color bg-white z-50 ' : 'text-gray-60 bg-[#f2f3f8] translate-y-[10%] rounded-tl-none   z-10 ')
                                }
                                onClick={() => handleTabClick(2)}
                            >
                                AWS Braket Simulator
                            </div>
                        </li>
                    </ul>

                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                        <div className="px-4 py-5 flex-auto mb-14">
                            <div className="tab-content tab-space">
                                <div className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}>
                                    <NvidiaGpuSimulater />
                                </div>
                                <div className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}>
                                    <AwsSimulater />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LaunchSimulater