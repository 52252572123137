import React from 'react'
import Carousel from 'react-material-ui-carousel'
import { Paper } from '@mui/material'

function Slider() {
    var items = [
        {
            name: "AI Model Studio",
            description: "Dive into our Creative Canvas for AI Innovation. Build, train, and deploy AI models seamlessly. Explore our extensive collection of pre-trained industry models like Google's Gemma , Stable Diffusion ,etc. and accelerate your projects from inception to fruition."
        },
        {
            name: "AI Compute Platform",
            description: "Unleash unparalleled processing power with GPU and CPU cloud. Access latest GPUs and CPUs from NVIDIA, Intel and AMD. Experience unmatched flexibility with a diverse array of compute instances tailored to your specific workload requirements."
        },
        {
            name: "AI Data Services",
            description: "Fuel your models for success with our engine room of AI innovation. Access high-quality data from trusted industry sources or utilize our Synthetic Data generation application to create your own tailored datasets and seamlessly aggregate data to create comprehensive datasets tailored to your AI projects."
        },
        {
            name: "AI Quantum Engine",
            description: "Combine Quantum Computing with Classical Computing to address intricate challenges and pioneer innovative applications. Utilize NVIDIA Cuquantum to simulate quantum circuits."
        },
    ]
    return (
        <Carousel>
            {items.map((e) => {
                return (
                    <Paper key={e} sx={{ background: 'none', boxShadow: 'none', padding: '20px' }}>
                        <h1 className='text-center text-white font-semibold text-base pb-6'>{e.name}</h1>
                        <p className='text-center text-white font-normal text-sm'>{e.description}</p>
                    </Paper>
                );
            })}
        </Carousel>
    )
}

export default Slider;