import React from 'react'
import Credit from './Billing/Credit';

function BillingPayment() {
    // const [openTab, setOpenTab] = useState(1);
    return (
        // <div className='flex w-full bg-white h-80vh'>
        //     <div className='w-1/5 bg-tab-not-active-color'>
        //         <ul
        //             className="mb-0 list-none"
        //             role="tablist"
        //         >
        //             <li className="-mb-px mr-2 last:mr-0 flex-auto w-full text-center">
        //                 <a
        //                     className={
        //                         "text-lg font-semibold  py-4  rounded block  " +
        //                         (openTab === 1
        //                             ? "text-tab-active-color bg-white border-l-4 border-tab-active-color"
        //                             : "text-gray-60 tab-not-active-color")
        //                     }
        //                     onClick={e => {
        //                         e.preventDefault();
        //                         setOpenTab(1);
        //                     }}
        //                     data-toggle="tab"
        //                     href="#link1"
        //                     role="tablist"
        //                 >
        //                     Overview
        //                 </a>
        //             </li>

        //             <li className="-mb-px mr-2 last:mr-0 w-full flex-auto text-center">
        //                 <a
        //                     className={
        //                         "text-lg font-semibold py-4   rounded block  " +
        //                         (openTab === 2
        //                             ? "text-tab-active-color bg-white border-l-4 border-tab-active-color"
        //                             : "text-gray-60 tab-not-active-color")
        //                     }
        //                     onClick={e => {
        //                         e.preventDefault();
        //                         setOpenTab(2);
        //                     }}
        //                     data-toggle="tab"
        //                     href="#link2"
        //                     role="tablist"
        //                 >
        //                     Billing
        //                 </a>
        //             </li>

        //             <li className="-mb-px mr-2 last:mr-0 w-full flex-auto text-center">
        //                 <a
        //                     className={
        //                         "text-lg font-semibold  py-4 rounded block  " +
        //                         (openTab === 3
        //                             ? "text-tab-active-color bg-white border-l-4 border-tab-active-color"
        //                             : "text-gray-60 tab-not-active-color")
        //                     }
        //                     onClick={e => {
        //                         e.preventDefault();
        //                         setOpenTab(3);
        //                     }}
        //                     data-toggle="tab"
        //                     href="#link2"
        //                     role="tablist"
        //                 >
        //                     Credit
        //                 </a>
        //             </li>
        //             <li className="-mb-px mr-2 last:mr-0 w-full flex-auto text-center">
        //                 <a
        //                     className={
        //                         "text-lg font-semibold  py-4 rounded block  " +
        //                         (openTab === 4
        //                             ? "text-tab-active-color bg-white border-l-4 border-tab-active-color"
        //                             : "text-gray-60 tab-not-active-color")
        //                     }
        //                     onClick={e => {
        //                         e.preventDefault();
        //                         setOpenTab(4);
        //                     }}
        //                     data-toggle="tab"
        //                     href="#link2"
        //                     role="tablist"
        //                 >
        //                     Purchase Order
        //                 </a>
        //             </li>
        //         </ul>
        //     </div>

        //     <div className='w-full mx-5 my-5'>
        //         <div className={openTab === 1 ? "block" : "hidden"} id="link1">
        //             <Overview />
        //         </div>
        //         <div className={openTab === 2 ? "block" : "hidden"} id="link1">
        //             <Billing />
        //         </div>
        //         <div className={openTab === 3 ? "block" : "hidden"} id="link1">
        //             <Credit />
        //         </div>
        //         <div className={openTab === 4 ? "block" : "hidden"} id="link1">
        //             <PurchaseOrder />
        //         </div>
        //     </div>
        // </div>
        <Credit />
    )
}

export default BillingPayment