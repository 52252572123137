import React, { useContext, useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Footer from "./Footer/Footer";
import { Context } from "../Context";
import loader from "../assets/ui_img/loader.svg";
import '../component/Tabs/loader.css';
import { Container } from "@mui/material";

const Jupyter = () => {
    const { open } = useContext(Context);
    const [jupiterUrl, setJupiterUrl] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const url = sessionStorage.getItem('jupiterUrl');
        setJupiterUrl(url);
    }, []);

    const handleLoad = () => {
        setLoading(false);
    };

    return (
        <div className='flex w-full min-h-screen'>
            <Sidebar className='flex-shrink-0 h-full fixed' />
            <div className='flex flex-col w-full '>
                <div className={`${open ? "ml-[228px]" : "ml-[90px]"}`}>
                    <Navbar className='flex-shrink-0 fixed w-full' />
                </div>
                {/* page content */}
                <div className={`${open ? "ml-[228px]" : "ml-[90px]"} px-8 pt-16 mb-16 rounded-md relative`}>
                    <Container>
                        {/* Loader */}
                        {loading && (
                            <div className="absolute inset-0 flex items-center justify-center z-50">
                                <img src={loader} alt="img_loader" className="spinner" />
                                {/* <div className="loader">loading...</div> */}
                            </div>
                        )}
                        {/* Your main content goes here */}
                        <iframe
                            title="jupyter-route"
                            src={jupiterUrl}
                            className="jupyter-page-hieght w-full py-3"
                            onLoad={handleLoad}
                        />
                    </Container>
                </div>
                <div className={`${open ? "ml-[228px]" : "ml-[90px]"}`}>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default Jupyter;
