import React, { useEffect, useState } from 'react'
import Carousel from 'react-material-ui-carousel'
import { Paper } from '@mui/material'
import axios from 'axios';
import axiosInstance from '../../api/axios';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

function NowAvailable() {
    const [aiModel, setAiModel] = useState([]);
    const handleRefreshToken = async () => {
        try {
            await axiosInstance.post('/user/refresh_token');
            return true
        } catch (error) {
            console.log('refresh token')
            // handleLogOut()
            return false
        }
    };

    useEffect(() => {
        const apiURL = process.env.REACT_APP_HOMEPAGE_URL
        const aiModelStudio = async () => {
            try {
                const response = await axiosInstance.get(`${apiURL}/now-available/`);
                console.log(response)
                console.log(response.data)
                console.log(response.data.data)
                setAiModel(response.data.data);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    const isRefresh = await handleRefreshToken();
                    if (isRefresh) {
                        aiModelStudio()
                    }
                } else {
                    console.error('Error:', error);
                    toast(error.response.data.message);
                }
            } finally {

            }
        }
        aiModelStudio();
    }, []);

    const items = [];
    for (let i = 0; i < aiModel?.length; i += 4) {
        items.push(aiModel.slice(i, i + 4));
    }

    const handleTabPage = (ele) => {
        if (ele?.categories === 'NVIDIA L4') {
            localStorage.setItem('activeTab', 2)
        }
    }

    return (
        <>
            {/* <div className='flex justify-between py-8'>
                <div>
                    <p className="text-base font-normal text-gray-70 pt-1">
                        All-in-one platform designed to supercharge your enterprise AI development, from ideation to deployment.
                    </p>
                </div>
            </div> */}
            <div className="bg-white shadow-md rounded-lg flex flex-col justify-between cursor-pointer mt-9">
                <div className='px-5 pt-5'>
                    <div className='flex gap-2'>
                        <h2 className="font-bold text-xl text-card-li-color">{aiModel[0]?.title}</h2>
                        <span className='font-medium text-sm text-opacity-60 text-secondry-clr pt-1.5'>
                            | {aiModel?.length} Categories
                        </span>
                    </div>
                    <p className="text-card-li-color font-normal text-sm">{aiModel[0]?.heading}</p>
                </div>
                <div>
                    <Carousel>
                        {items?.map((group, index) => (
                            <Paper key={index} sx={{ background: 'none', boxShadow: 'none', padding: '20px' }}>
                                <div className='flex gap-5'>
                                    {group?.map((item, idx) => (
                                        <div key={idx} style={{ height: '200px' }}>
                                            <Link to={item?.redirect_link} onClick={() => handleTabPage(item)}>
                                                <img src={item?.image_url} alt='abcIndiaHavePass' className="h-[200px]" />
                                                <div className='bg-[#1A1A1A] bg-opacity-75 text-white py-3 -mt-11 w-full rounded-b-xl relative'>
                                                    <p className='text-center font-semibold text-sm'>{item?.categories}</p>
                                                </div>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </Paper>
                        ))}
                    </Carousel>
                </div>
            </div>
        </>
    )
}

export default NowAvailable;