import React, { useContext, useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md';
import axios from 'axios';
import { Context } from '../../Context';
import ModalTab from '../Tabs/ModalTab';
import { toast } from 'react-toastify';
import axiosInstance from '../../api/axios';
import { useNavigate } from 'react-router-dom';

function NvidiaGpuSimulater() {
    // const [isHovered, setIsHovered] = useState('');
    const { isLoading, setIsLoading, setPlanId, setModalInfo, freeShowModal, setFreeShowModal, deleteSuccess, setUpdateCredit, setAddCredits } = useContext(Context);
    const [listCard, setListCard] = useState([]);
    const [contactModal, setContactModal] = useState(false);
    const [contactInstanceID, setContactInstanceID] = useState();

    const apiURL = process.env.REACT_APP_BASE_URL;
    const PaymentapiURL = process.env.REACT_APP_PAYMENT_SERVICE_URL;
    const [error, setError] = useState('');
    const [checkError, setCheckError] = useState(false);
    const [formData, setFormData] = useState({
        startDate: '',
        endDate: '',
        firstName: '',
        lastName: '',
        company: '',
        phone: '',
        companyAddress: '',
        email: '',
    });

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    };

    const validateForm = () => {
        for (const key in formData) {
            if (formData[key] === '') {
                setError(key);
                setCheckError(true);
                return false;
            }
        }
        return true;
    };

    const handleRefreshToken = async () => {
        try {
            await axiosInstance.post('/user/refresh_token');
            return true
        } catch (error) {
            console.log('refresh token')
            return false
        }
    };

    const loadCard = async () => {
        const url = `/payments/other-plans/`;
        const params = {
            type: "cuquantum"
        };
        // Make the GET request using Axios
        try {
            const response = await axiosInstance.get(url, { params: params })

            const data = response.data.data;

            // Process the data here and set it to the listCard state
            const processedData = data.map(plan => {
                // Process plan data as needed
                const features = plan.features;
            
                
                const processedPlan = {
                    id: plan.id,
                    name: plan.name,
                    price: plan.credits,
                    // Add other properties as needed
                    // operatingSystem: features["Operating System"],
                    // aiPackages: features["AI Packages"].join(', '),
                    // languages: features["Languages"].join(', '),
                    Instance: features["Instance"],
                    available: features['available'],
                    // Add hardware details as needed
                    hardware: {
                        CPUs: features["Hardware"].CPUs,
                        RAM: features["Hardware"].RAM,
                        Storage: features["Hardware"].Storage,
                        GPUs: features["Hardware"].GPUs,
                        Network: features["Hardware"].Network,
                    },
                    is_active: plan.is_active,
                    notebook_url: plan.notebook_url,
                };

                return processedPlan;
            });
            // Update the state with the processed data
            setListCard(processedData);
        }
        catch (error) {
            if (error.response && error.response.status === 401) {
                const refresh = await handleRefreshToken();
                if (refresh) {
                    loadCard()
                }
            } else {
                console.error('Error:', error);
            }
        }
    }

    useEffect(() => {
        loadCard()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteSuccess, apiURL, PaymentapiURL]);

    const handleOpen = async (ele) => {
        if (ele.is_active && ele.notebook_url) {
            setModalInfo(ele);
            setFreeShowModal(true);
            setIsLoading(false);
            window.open(ele.notebook_url, '_blank');
            // sessionStorage.setItem('jupiterUrl', ele.notebook_url)
            // navigate('/jupyter')
        }
    };

    const handleStart = async (ele) => {
        setFreeShowModal(true);
        setModalInfo(ele);
        setIsLoading(true);
        try {
            // Make the request to ActivatePlan
            const response = await axiosInstance.post(`/manage/start-instance`, null);

            if (response.status === 200) {
                toast(response.data.message);
                setIsLoading(false);
                console.log(response)
                console.log(response.data.notebookUrl)
                window.open(response.data.notebookUrl, '_blank');
                // sessionStorage.setItem('jupiterUrl', response.data.notebookUrl)
                // navigate('/jupyter')
                // return true; // Activation successful
                axiosInstance.post(`/payment/save-notebook-url`, { plan: ele.id, notebook_url: response.data.notebookUrl }
                )
                    .then(secondResponse => {
                        console.log(secondResponse.data);
                        // Handle the response from the second API call
                    })
                    .catch(secondError => {
                        console.error('Second API call error', secondError);
                        // toast('Something went wrong with the second API call');
                    });
            } else {
                console.log('Error in ActivatePlan request');
                return false; // Activation failed
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                const refresh = await handleRefreshToken();
                if (refresh) {
                    handleStart(ele)
                }
            } else {
                console.error('Error:', error);
            }
            return false; // Error occurred during activation
        }
    };

    const checkPlan = async (ele) => {
        try {
            // Make the request to ActivatePlan
            const response = await axiosInstance.post(`/payments/checkPlanCredit`, { plan: ele.id });

            if (response.status === 200) {
                return true; // Activation successful
            } else {
                console.log('Error in ActivatePlan request');
                toast("Your plan is either already activated or you have less credit.");
                return false; // Activation failed
            }
        } catch (error) {
            console.log('Error in ActivatePlan:', error);
            toast("Please Buy More Credit To Activate this Plan.");
            setAddCredits(true);
            if (error.response && error.response.status === 401) {
                const refresh = await handleRefreshToken();
                if (refresh) {
                    checkPlan(ele)
                }
            } else {
                console.error('Error:', error);
            }
            return false; // Error occurred during activation
        }
    };

    const activatePlan = async (ele) => {
        try {
            // Make the request to ActivatePlan
            const response = await axiosInstance.post(`/payments/ActivatePlan`, { plan: ele.id });

            if (response.status === 200) {
                setUpdateCredit(true);
                return true; // Activation successful
            } else {
                console.log('Error in ActivatePlan request');
                return false; // Activation failed
            }
        } catch (error) {
            console.log('Error in ActivatePlan:', error);
            if (error.response && error.response.status === 401) {
                const refresh = await handleRefreshToken();
                if (refresh) {
                    activatePlan(ele)
                }
            } else {
                console.error('Error:', error);
            }
            return false; // Error occurred during activation
        }
    };
    const navigate = useNavigate();
    const launchInstance = async (ele) => {
        const requestData = {
            "allocatePublicIp": true,
            "instanceType": "nvidia",
            "resourceLevel": ele.Instance,
            "plan": ele.id,
            "plan_name": ele.name,
            "amount": ele.price
        };

        setTimeout(() => {
            setFreeShowModal(false);
            navigate('/myInstance');
        }, 5000);

        try {
            const secondResponse = await axiosInstance.post(`/manage/launch-instance`, requestData);

            if (secondResponse.status === 200) {
                console.log(secondResponse.data.notebookUrl);
                toast(secondResponse.data.message);
                //window.open(secondResponse.data.notebookUrl, '_blank')
                if (!secondResponse.data.success) {
                    setFreeShowModal(false);
                }
                return secondResponse.data.notebookUrl; // Return the notebook URL
            } else {
                console.log('Error in launchInstance request');
                return null; // Failed to launch instance or no notebook URL
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                const refresh = await handleRefreshToken();
                if (refresh) {
                    launchInstance(ele)
                }
            } else {
                console.error('Error:', error);
            }
        }
    };

    const handleCPU = async (ele) => {
        setPlanId(ele.id);
        setIsLoading(true); // Set loading state to true at the start
      
        try {
          // Only attempt to launch the instance
          const notebookUrl = await launchInstance(ele);
      
          if (notebookUrl) {
            // Open the notebook URL if launchInstance returns a valid URL
            window.open(notebookUrl, '_blank');
          }
        } catch (error) {
          console.error('Error launching instance:', error);
        } finally {
          // Ensure that the loading state is turned off
          setIsLoading(false);
        }
      };
      
    // Function to handle "Reserve" action
    const handleContactUs = (ele) => {
        setContactModal(true);
        console.log(ele.id);
        setContactInstanceID(ele.id);
    };

    const SendContactMessage = async () => {
        if (validateForm()) {
            formData.plan = contactInstanceID;
            try {
                const response = await axios.post(`${PaymentapiURL}/contact-us`, formData);
                if (response.status === 200) {
                    setContactModal(false);
                    toast("Succesfully Send Your Query");
                    return true; // Activation successful
                } else {
                    console.log('Error in ActivatePlan request');
                    return false; // Activation failed
                }
            } catch (error) {
                console.log('Error in ActivatePlan:', error);
                toast('Something Wrong Message Not Sent');
                if (error.response && error.response.status === 401) {
                    const refresh = await handleRefreshToken();
                    if (refresh) {
                        SendContactMessage();
                    }
                } else {
                    console.error('Error:', error);
                }
                return false; // Error occurred during activation
            }
        }
    };


    return (
        <>
            <div className="grid grid-cols-3 gap-8 px-5">
                {listCard?.map((ele, index) => {
                    return (
                        <div className="group bg-white w-full md:h-instance-card-height rounded-3xl shadow relative"
                            // onMouseEnter={() => setIsHovered(ele.id)}
                            // onMouseLeave={() => setIsHovered('')}
                            key={ele.id}
                        >
                            <div className='py-8 text-center'>
                                <span className="font-semibold text-lg">{ele.name}</span>
                            </div>

                            <div className={`group-hover:bg-[#8624C505] group-hover:text-[#8F27C1] bg-[#F8F9FD] py-5 mb-5 flex text-center justify-center`}>
                                <div className='relative w-fit'>
                                    <p className='absolute top-1 text-2xl font-semibold'>$</p>
                                    <p className='text-[42px] px-5 font-bold'>{ele.price}</p>
                                    <p className='text-[16px] -mt-2 font-semibold'>Per Hour</p>
                                </div>
                            </div>




                            <ul className="space-y-3 px-6 my-2 text-card-li-color">
                                <li className="flex space-x-3 items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                        <circle cx="3" cy="3" r="3" fill="#2E1F15" />
                                    </svg>
                                    <span className="text-sm font-normal">{ele.hardware.GPUs}</span>
                                </li>
                                <li className="flex space-x-3 items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                        <circle cx="3" cy="3" r="3" fill="#2E1F15" />
                                    </svg>
                                    <span className="text-sm font-normal">{ele.hardware.CPUs}</span>
                                </li>
                                <li className="flex space-x-3 items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                        <circle cx="3" cy="3" r="3" fill="#2E1F15" />
                                    </svg>
                                    <span className="text-sm font-normal">{ele.hardware.RAM}</span>
                                </li>
                                <li className="flex space-x-3 items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                        <circle cx="3" cy="3" r="3" fill="#2E1F15" />
                                    </svg>
                                    <span className="text-sm font-normal">{ele.hardware.Storage}</span>
                                </li>

                                <li className="flex space-x-3 items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                        <circle cx="3" cy="3" r="3" fill="#2E1F15" />
                                    </svg>
                                    <span className="text-sm font-normal">{ele.hardware.Network}</span>
                                </li>
                            </ul>
                            {/* <div className='flex justify-center items-center'>
                                {ele.available === 'yes' ? (
                                    <>
                                        {ele.notebook_url === 'stop' ? (
                                            <button className={`${isHovered === ele.id ? "bg-gradient-pink-purple text-white" : "bg-page-bg-color"
                                                } absolute bottom-0 my-5 text-base font-semibold px-16 py-5 rounded-full`} onClick={() => handleStart(ele)}>
                                                <span>START</span>
                                            </button>
                                        ) : (
                                            ele.notebook_url ? (
                                                <button className={`${isHovered === ele.id ? "bg-gradient-pink-purple text-white" : "bg-page-bg-color"
                                                    } absolute bottom-0 my-5 text-base font-semibold px-16 py-5 rounded-full`} onClick={() => handleOpen(ele)}>
                                                    <span>OPEN</span>
                                                </button>
                                            ) : (
                                                <button className={`${isHovered === ele.id ? "bg-gradient-pink-purple text-white" : "bg-page-bg-color"
                                                    } absolute bottom-0 my-5 text-base font-semibold px-16 py-5 rounded-full`} onClick={() => handleCPU(ele)}>
                                                    <span>DEPLOY</span>
                                                </button>
                                            )
                                        )}
                                    </>
                                ) : (
                                    <button className={`${isHovered === ele.id ? "bg-gradient-pink-purple text-white" : "bg-page-bg-color"
                                        } absolute bottom-0 my-5 text-base font-semibold px-16 py-5 rounded-full`} onClick={() => handleContactUs(ele)}>
                                        RESERVE
                                    </button>
                                )}
                            </div> */}

                            <div className='flex justify-center items-center'>
                                <>
                                    {ele.available === 'yes' ?
                                        <>
                                            {ele.notebook_url === 'stop' ?
                                                (
                                                    <button
                                                        className='group-hover:bg-[#8F27C11A] group-hover:border-[#8F27C10D] group-hover:text-[#8F27C1]  text-[#10001ACC] absolute bottom-0 my-5 rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-[40px] py-[18px] '
                                                        onClick={() => handleStart(ele)}>
                                                        <span>START</span>
                                                    </button>
                                                )
                                                :
                                                <>
                                                    {ele.notebook_url ?
                                                        (
                                                            <button
                                                                className='group-hover:bg-[#8F27C11A] group-hover:border-[#8F27C10D]   group-hover:text-[#8F27C1] text-[#10001ACC] absolute bottom-0 my-5 rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-[40px] py-[18px] '
                                                                onClick={() => handleOpen(ele)}>
                                                                <span>Open</span>
                                                            </button>
                                                        )
                                                        :
                                                        <>
                                                            {ele.id === parseInt(sessionStorage.getItem('QPU_id')) ?
                                                                (
                                                                    <button
                                                                        className='bg-[#8F27C11A] border-[#8F27C10D] text-[#8F27C1]  absolute bottom-0 my-5 rounded-[12px] text-lg font-semibold px-[40px] py-[18px] '
                                                                        onClick={() => handleCPU(ele)}
                                                                        disabled={sessionStorage.getItem('QPU_id') ? true : false}>
                                                                        <div className="flex items-center justify-center ">
                                                                            <svg className="animate-spin h-5 w-5 mr-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.96 7.96 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zm5.195 2.647A8.034 8.034 0 0112 20c4.418 0 8-3.582 8-8h-4c0 2.761-2.239 5-5 5a4.998 4.998 0 01-4.805-3.653z"></path></svg>
                                                                            {sessionStorage.getItem('QPU_id') && <span>Launching...</span>}
                                                                        </div>
                                                                    </button>
                                                                )
                                                                :
                                                                <>
                                                                    {
                                                                        <button
                                                                            className={`${!sessionStorage.getItem('QPU_id') ? "group-hover:bg-[#8F27C11A] group-hover:border-[#8F27C10D] group-hover:text-[#8F27C1]" : ""}   text-[#10001ACC] absolute bottom-0 my-5 rounded-[12px]  border border-[#10001ACC] text-lg font-semibold px-[40px] py-[18px] `}
                                                                            onClick={() => handleCPU(ele)}
                                                                            disabled={sessionStorage.getItem('QPU_id') ? true : false}>
                                                                            <span>Launch Notebook</span>
                                                                        </button>
                                                                    }
                                                                </>


                                                            }
                                                        </>
                                                    }
                                                </>
                                            }
                                        </>
                                        :
                                        (
                                            <button
                                                className='group-hover:bg-[#8F27C11A] group-hover:border-[#8F27C10D] group-hover:text-[#8F27C1]  text-[#10001ACC] absolute bottom-0 my-5 rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-[40px] py-[18px] '
                                                onClick={() => handleContactUs(ele)}>
                                                <span>Reserve</span>
                                            </button>
                                        )
                                    }
                                </>
                            </div>
                        </div>
                    )
                })}
            </div>

            {
                freeShowModal ? (
                    <>
                        <div
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                        >
                            <div className="relative my-6 mx-auto">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                        <div>
                                            <h5 className="text-xl font-semibold">
                                                NVIDIA CuQuantum GPU Simulator
                                            </h5>
                                        </div>

                                        <button
                                            className=" background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => setFreeShowModal(false)}
                                            disabled={isLoading}
                                        >
                                            <MdClose className='w-5 h-5' />
                                        </button>

                                    </div>
                                    {/*body*/}
                                    <div className="relative py-6 flex-auto">
                                        <ModalTab />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null
            }

            {contactModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative my-6 mx-auto">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between px-6 py-4 border-b border-solid border-slate-200 rounded-t">
                                    <div>
                                        <h5 className="text-xl font-semibold">
                                            Reserve GPU Simulater Instance
                                        </h5>
                                    </div>

                                    <button
                                        className=" background-transparent font-bold uppercase pt-1.5 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setContactModal(false)}
                                    >
                                        <MdClose className='w-5 h-5' />
                                    </button>

                                </div>
                                {/*body*/}
                                <div className="relative px-6 py-4 flex-auto">
                                    <form>
                                        <div className="grid gap-4 mb-4 md:grid-cols-2 font-bold">
                                            <div>
                                                <label for="startDate" className="block mb-2 text-sm text-gray-900">Instance Start Date</label>
                                                <input type="date" id="startDate" value={formData.startDate} onChange={handleInputChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="flowbite.com" required />
                                            </div>
                                            <div>
                                                <label for="endDate" className="block mb-2 text-sm text-gray-900">Instance End Date</label>
                                                <input type="date" id="endDate" value={formData.endDate} onChange={handleInputChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="" required />
                                            </div>
                                            <div>
                                                <label for="firstName" className="block mb-2 text-sm text-gray-900">First name</label>
                                                <input type="text" id="firstName" value={formData.firstName} onChange={handleInputChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="John" required />
                                            </div>
                                            <div>
                                                <label for="lastName" className="block mb-2 text-sm text-gray-900">Last name</label>
                                                <input type="text" id="lastName" value={formData.lastName} onChange={handleInputChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Doe" required />
                                            </div>
                                            <div>
                                                <label for="company" className="block mb-2 text-sm text-gray-900">Company</label>
                                                <input type="text" id="company" value={formData.company} onChange={handleInputChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Flowbite" required />
                                            </div>
                                            <div>
                                                <label for="phone" className="block mb-2 text-sm text-gray-900">Phone number</label>
                                                <input type="tel" id="phone" value={formData.phone} onChange={handleInputChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="123-45-678" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required />
                                            </div>
                                        </div>
                                        <div className='font-bold mb-4'>
                                            <label for="companyAddress" className="block mb-2 text-sm text-gray-900">Company address</label>
                                            <input type="text" id="companyAddress" value={formData.companyAddress} onChange={handleInputChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Mclean, United States" required />
                                        </div>
                                        <div className='font-bold'>
                                            <label for="email" className="block mb-2 text-sm text-gray-900">Email address</label>
                                            <input type="email" id="email" value={formData.email} onChange={handleInputChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="john.doe@company.com" required />
                                        </div>
                                        {
                                            checkError ? <div className='mt-1'><span className='text-red-600'>Please Fill {error}</span></div> : ''
                                        }
                                    </form>
                                </div>
                                {/* Footer */}
                                <div className="flex items-center justify-end px-6 py-4 border-t border-solid border-slate-200 rounded-b gap-x-3">
                                    <button
                                        type="button"
                                        className={`bg-page-bg-color text-tab-active-color  text-base font-semibold px-12 py-3 rounded-full`}
                                        onClick={() => setContactModal(false)}
                                    >
                                        Cancel
                                    </button>

                                    <button
                                        type="button"
                                        className={`bg-gradient-pink-purple text-white text-base font-semibold px-12 py-3 rounded-full`}
                                        onClick={SendContactMessage}
                                    >
                                        Send
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    )
}

export default NvidiaGpuSimulater
