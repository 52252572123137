import React, { useContext } from 'react'
import Sidebar from '../component/Sidebar';
import Navbar from '../component/Navbar';
import Footer from '../component/Footer/Footer';
import { Context } from '../Context';
import Index from '../component/AIHub/Index';


function HomePage() {
  const { open } = useContext(Context);
  return (
    <div className='flex w-full min-h-screen'>
      <Sidebar className='flex-shrink-0 h-full fixed' />
      <div className='flex flex-col w-full'>
        <div className={`${open ? "ml-[228px]" : "ml-[90px]"}`}>
          <Navbar className='flex-shrink-0 fixed w-full' />
        </div>
        {/* page content */}
        <div className={`${open ? "ml-[228px]" : "ml-[90px]"} px-8 pt-16 mb-24`}>
          <Index />
        </div>
        <div className={`${open ? "ml-[228px]" : "ml-[90px]"}`}>
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default HomePage