import './Model.css'
import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import Footer from '../Footer/Footer'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Container } from '@mui/material';
import { MdKeyboardArrowRight } from 'react-icons/md';
// import { LiaSpinnerSolid } from "react-icons/lia";
import { getModelCategoryCards, getModelHomeCards } from '../../services/api';
import Loader from '../../assets/aiModels/QLoader.svg'



const ModelCategory = () => {
    const navigate = useNavigate();
    const { category } = useParams();
    const [cardData, setCardData] = useState([])

    useEffect(() => {
        // Scroll to the top of the page when the component is mounted
        window.scrollTo(0, 0);

        (async function populateModelHomeCardList() {
            try {
                const response = await getModelCategoryCards(category)
                const data = response.data.data;
                console.log(data)
                // console.log(data.features)
                const processedData = data.map(plan => {
                    const features = plan.features;
                    console.log(features);
                    const processedPlan = {
                        id: plan.id,
                        name: plan.model_name,
                        price: plan.credits,
                        images: features["Image"],
                        description: features["Description"],
                        instance: features["Instance"],
                        instancetype: features["InstanceType"],
                        path: features["path"],
                        isActive: features['isActive'],
                    };
                    return processedPlan;
                });
                console.log(processedData)
                setCardData(processedData.reverse());
            } catch (error) {
                console.log(error)
            }
        })()

        async function getValidCategoryList() {
            try {
                const response = await getModelHomeCards()
                let validCategories = response.data.data.map(item => item.categories)
                if (validCategories.includes(category) === false) {
                    navigate('/modelStudio')
                }
            } catch (error) {
                console.log(error)
            }
        }
        getValidCategoryList()

    }, [category, navigate])

    useEffect(() => {
        console.log(cardData)
    }, [cardData])

    function addEllipsis(text, maxLength) {
        // Split the text into an array of words
        const words = text.split(' ');
        // If the text length is less than or equal to the maximum length,
        // return the original text
        if (words.length <= maxLength) {
            return text;
        }

        // Otherwise, join the first maxLength words with a space and add ellipsis
        return words.slice(0, maxLength).join(' ') + '...';
    }


    return (
        <div className='flex w-full min-h-screen'>
            <Sidebar className='flex-shrink-0 h-full fixed' />
            <div className='flex flex-col w-full'>
                <div className="ml-[90px]"><Navbar className='flex-shrink-0 fixed w-full' /></div>

                {/* page content */}
                <div className="ml-[90px] px-2 pt-[84px] mb-24">
                    {/* Your main content goes here */}
                    <Container sx={{ maxWidth: { xs: '100%', sm: '1252px' } }}>

                        {/* Breadcrumbs */}
                        <div className='flex justify-between py-4'>
                            <div>
                                <div className="flex items-center">
                                    <Link to="/modelStudio">
                                        <h2 className="text-2xl font-bold text-heading-black hover:text-blue-500">Model Studio</h2>
                                    </Link>
                                    <MdKeyboardArrowRight className='text-2xl' />
                                    <h2 className="text-2xl font-bold text-heading-black">{category}</h2>
                                </div>
                                <p className="text-base font-normal text-gray-70 pt-1">Train, Tune , Deploy AI models.</p>
                            </div>
                        </div>

                        {/* Card Wrapper */}
                        {cardData.length === 0 &&
                            <div className="min-h-[770px] w-full bg-white px-8 pb-8 shadow-md flex justify-center">
                                {/* <LiaSpinnerSolid className='animate-spin text-gen-color text-5xl duration-[10000ms] mt-40' /> */}
                                <img src={Loader} className='h-fit animate-spin text-gen-color text-5xl duration-[100000ms] mt-40' alt="" />
                            </div>
                        }
                        {cardData.length > 0 &&
                            <div className=" w-full bg-white p-8 shadow-md">
                                <div className='flex flex-wrap gap-5'>
                                    {cardData.length > 0 &&
                                        cardData.map((item, key) => {
                                            return (
                                                <>
                                                    {/* Conditional rendering to disable Link in cards which are not active */}
                                                    {item.isActive ?
                                                        <Link key={key} to={`/modelStudio/${category}/${item.name}`}>
                                                            <div key={key}
                                                                className='shadow h-[292px] w-[270px] rounded-lg p-2'
                                                            >
                                                                <img src={item.images} className='h-[160px] w-[250px]  rounded-md mx-auto' alt="" />
                                                                <div>
                                                                    <p className=' text-[14px] font-[600] mt-2 mb-1'>{item.name}</p>
                                                                    <span className='bg-[#F4F5F8] border rounded py-1 px-2  text-[11px] font-[500]'>{category}</span>
                                                                    <p className=' text-[12px] font-[400] text-[#10001AB2] mt-2 text-justify h-[55px] overflow-hidden'>{addEllipsis(item.description, 16)}</p>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                        :
                                                        <div key={key}
                                                            className='shadow h-[292px] w-[270px] rounded-lg p-2 bg-gray-200 opacity-50'
                                                        >
                                                            <img src={item.images} className='h-[160px] w-[250px] rounded-md mx-auto' alt="" />
                                                            <div>
                                                                <p className=' text-[14px] font-[600] mt-2 mb-1'>{item.name}</p>
                                                                <span className='bg-[#F4F5F8] border rounded py-1 px-2  text-[11px] font-[500]'>{category}</span>
                                                                <p className=' text-[12px] font-[400] text-[#10001AB2]  mt-2 text-justify h-[55px] overflow-hidden'>{addEllipsis(item.description, 16)}</p>
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        }


                    </Container>
                </div>
                <div className={`ml-[90px]`}><Footer /></div>
            </div >
        </div >
    );
};

export default ModelCategory;
