import React, { useContext } from 'react';
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Context } from '../../Context';
import Footer from '../Footer/Footer';
// import LaunchCpuGpu from '../RunAIMlCpuGpu/LaunchCpuGpu';
// import LaunchSimulater from '../SimulateQuantumGpu.jsx/LaunchSimulater';
import { Link } from 'react-router-dom';
import { Container } from '@mui/material';
// import TryQuantum from '../QuantumInfra/QuantumInfra';
// import LaunchAi from '../AiModal/LaunchAi';
// import HybridLaunch from '../HybridQuantum/HybridLaunch';
// import ApplicationLaunch from '../QuantumApplications/ApplicationLaunch';

function HelpInfo() {
    const { open } = useContext(Context);
    return (
        <>
            <div className='flex w-full min-h-screen'>
                <Sidebar className='flex-shrink-0 h-full fixed' />
                <div className='flex flex-col w-full'>
                    <div className={`${open ? "ml-[228px]" : "ml-[90px]"}`}>
                        <Navbar />
                    </div>
                    {/* Page Content */}
                    <div className={`${open ? "ml-[228px]" : "ml-[90px]"} px-8 pt-24`}>
                        {/* Your main content goes here */}
                        <Container>
                            <div className='flex justify-between py-4'>
                                <div>
                                    <div className='flex'>
                                        <Link to='/'>
                                            <h2 className="text-2xl font-bold text-heading-black">Help</h2>
                                        </Link>

                                        <NavigateNextIcon className='mt-2' />
                                        <span className='mt-2 font-medium'>TQI QuickBits: BMW, Airbus and Quantinuum Form Quantum Dream Team, Room ...</span>

                                        {/* {
                                        isSelectHome === 1 ? <span className='mt-2 font-medium'>Run AI / ML Program on CPU and GPU</span> : ''
                                    }
                                    {
                                        isSelectHome === 2 ? <span className='mt-2 font-medium'>Run Quantum Program on QPU</span> : ''
                                    }
                                    {
                                        isSelectHome === 3 ? <span className='mt-2 font-medium'>Simulate Quantum Program on GPU</span> : ''
                                    }
                                    {
                                        isSelectHome === 4 ? <span className='mt-2 font-medium'>Improve Your AI Model with MLOPs</span> : ''
                                    }
                                    {
                                        isSelectHome === 5 ? <span className='mt-2 font-medium'>Hybrid Quantum-Classical Infrastructure: Run Now</span> : ''
                                    }
                                    {
                                        isSelectHome === 6 ? <span className='mt-2 font-medium'>Get a Quick Start with Quantum Applications</span> : ''
                                    } */}
                                    </div>
                                </div>
                            </div>
                            {/* {isSelectHome === 1 ? <LaunchCpuGpu /> : ''}
                            {isSelectHome === 2 ? <TryQuantum /> : ''}
                            {isSelectHome === 3 ? <LaunchSimulater /> : ''}
                            {isSelectHome === 4 ? <LaunchAi /> : ''}
                            {isSelectHome === 5 ? <HybridLaunch /> : ''}
                            {isSelectHome === 6 ? <ApplicationLaunch /> : ''} */}
                            <div className='text-center items-center bg-gradient-pink-purple rounded-lg mb-3'>
                                <h4 className='font-bold text-2xl text-white py-11'>Help Center</h4>
                                {/* <img src='' alt='helpImg'/> */}
                            </div>

                            <div className='space-y-3 py-3'>
                                <p>Keep an eye on the collaboration between Quantinuum, Airbus and BMW Group - it is nothing short of groundbreaking. This partnership holds huge significance for many reasons. We are now seeing quantum leaders - Quantinuum - moving alongside established industry powerhouses - Airbus, BMW Group, etc. and this shows the quick ascension of quantum and quantum leadership in just a few short years.</p>
                                <p>Also. The ongoing debate and replication efforts surrounding the recent room-temperature superconducting study has captured my attention -- and, apparently, the attention of the whole dang scientific community.</p>
                                <p>Observing this process is a bit like witnessing real-time science in action, and it has been truly amazing to witness the progress.</p>
                                <p>While the jury is still deliberating on the verification and scalability of this technology, we are undoubtedly gaining invaluable insights no matter what the outcome. The learning curve is steep, but the potential impact is immeasurable.</p>
                                <p>If you're interested in learning something about the incredible progress in quantum, I recommend a review of Citi's aptly titled Quantum Computing: Moving From Theory to Reality report.</p>
                                <p>Also - Our CEO Alex Challans has been shortlisted as Applied Intelligence's Technology Leader of The Year. In TQI's time-honored fashion please consider the "grip it and rip it" approach to sending a vote his way.</p>
                                <p>Have a great weekend - And don't forget to subscribe to TQI's official weekly newsletter for a complete wrap up of the news in quantum!</p>
                                <p><b>Quantum Quotes</b></p>
                                <p>Quantum computing offers the computational power to open up new avenues of research to produce changes that we need for a sustainable society and a adaptable economy. Airbus, BMW Group and Quantinuum are working together to use quantum computing to improve transportation. The stakes and the payoff are huge.</p>
                                <p>“Circularity and sustainable mobility are putting us on a quest for new materials, to create more efficient products and shape the future premium user experience. Being able to simulate material properties to relevant chemical accuracy with the benefits from the accelerating quantum computing hardware is giving us just the ...</p>
                            </div>
                        </Container>
                    </div>

                    <div className={`${open ? "ml-[228px]" : "ml-[90px]"}`}>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    )
}

export default HelpInfo;
