import React, { useContext, useEffect, useState } from 'react'
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../Footer/Footer';
import { Context } from '../../Context';
import { CloseOutlined, DescriptionOutlined } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { MdClose, MdKeyboardArrowRight } from 'react-icons/md';
import loader from "../../assets/aiModels/QLoader.svg";
import { GoCheckCircle } from "react-icons/go";
import { Container } from '@mui/material';
import { MultiSelect } from 'react-multi-select-component';
import { ImSpinner3 } from 'react-icons/im';
import axiosInstance from '../../api/axios';

function UploadFile() {
    const { open, setPreditionRes, filesDataRes, setFileDataRes } = useContext(Context);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [activeTab, setActiveTab] = useState(parseInt(localStorage.getItem('activeTab')) || 1);
    const [confrimationModal, setConfrimationModal] = useState(false);
    const [selected, setSelected] = useState([]);
    const [colInfo, setColInfo] = useState(null)
    const [selectedFile, setSelectedFile] = useState(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [initLoading, setInitLoading] = useState(false);
    const [initialising, setInitialising] = useState(false);
    useEffect(() => {
        localStorage.removeItem('activeTab');
    }, [])

    const handleFileUpload = (event) => {
        const fileInput = event.target;
        const file = fileInput.files[0];
        if (uploadedFiles.length !== 0) {
            toast("Can Not Upload Multiple File");
            event.preventDefault();
            return;
        }
        if (file) {
            if (file.type !== 'text/csv') {
                toast('Please select a CSV file.');
                fileInput.value = '';
                event.preventDefault();
                return;
            }
            const fileSizeInGB = file.size / (1024 * 1024 * 1024);
            if (fileSizeInGB > 1) {
                toast("File size exceeds 1GB limit.");
                fileInput.value = '';
                event.preventDefault();
                return;
            }
        }
        const files = event.target.files;
        handleFiles(files);
        setSelectedFile(event.target.files[0]);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        handleFiles(files);
        setSelectedFile(files[0]);
    };

    const handleFiles = (files) => {
        if (uploadedFiles.length !== 0) {
            toast("Can Not Upload Multiple File");
            return;
        }
        const newFiles = Array.from(files);
        setUploadedFiles([...uploadedFiles, ...newFiles]);
    };

    const removeFile = (index) => {
        const updatedFiles = [...uploadedFiles];
        updatedFiles.splice(index, 1);
        setUploadedFiles(updatedFiles);
    };

    const checkInstance = () => {
        setLoading(true); // Set loading to true when generating response
        axiosInstance.post(`/manage/instance`, { instance_id: 'i-0dcea0e6c1f7e610b' }, {
            headers: {
                'Content-Type': 'application/json' // Changed content type to application/json
            }
        })
            .then(res => {
                if (res.status === 200) {
                    uploadBtn();
                    setInitLoading(true)
                    setInitialising(false)
                }
            })
            .catch(err => {
                console.log(err)
                setInitLoading(false)
                setInitialising(false)
            })
    }

    const uploadBtn = async () => {
        setLoading(true)
        const formData = new FormData();
        if (!selectedFile) {
            toast("Upload CSV File")
            setLoading(false)
            return
        }
        formData.append("df", selectedFile);
        try {
            const response = await axiosInstance.post(`/synth/synth_show_data`, formData);
            if (response?.data?.error) {
                toast(response?.data?.error)
                return
            }
            setFileDataRes(response.data.df);
            setColInfo(response.data)
            setLoading(false)
            setActiveTab(2);
            // Save the active tab to localStorage
            localStorage.setItem('activeTab', 2);
        } catch (error) {
            toast(error.response.data.error);
            setLoading(false)
        }
    }

    const options = colInfo?.columns_info
        ?.filter(column => column.data_type !== "object") // Filter out columns with data_type "object"
        .map(column => ({
            label: column.column_name,
            value: column.column_name,
            disabled: false // Assuming you want disabled to be always false
        })) ?? [];

    const checkInstance2 = () => {
        setLoading(true); // Set loading to true when generating response
        axiosInstance.post(`/manage/instance`, { instance_id: 'i-0dcea0e6c1f7e610b' })
            .then(res => {
                if (res.status === 200) {
                    preditModel();
                }
            })
            .catch(err => {
                console.log(err)
                setLoading(false);
            })
    }

    const preditModel = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("df", selectedFile);
        // formData.append("new_row", columnNumber);
        if (selected.length === 0) {
            toast("Select a numerical column")
            setLoading(false)
            return
        }

        formData.append("target_columns", JSON.stringify(selected.map(option => option.value)));

        const config = {
            method: "post",
            url: `/synth/synth_predict_data`,
            data: formData,
        };

        axiosInstance(config)
            .then(function (response) {
                setPreditionRes(response.data)
                setLoading(false)
                navigate('/ApplicationResult')
                localStorage.removeItem('activeTab');
            })
            .catch(function (error) {
                console.error(error);
                setLoading(false)
                toast(error.response.data.error);
            })
            .finally(() => {
                axiosInstance.post(`/manage/instance_stopper`, { instance_id: 'i-0dcea0e6c1f7e610b' })
                .catch(function (postError) {
                    console.error("Error stopping instance:", postError);
                });
            });
    }

    if (uploadedFiles.length === 0) {
        localStorage.removeItem('activeTab');
        navigate("/")
    }

    return (
        <>

            <div className='flex w-full min-h-screen'>
                <Sidebar className='flex-shrink-0 h-full fixed' />
                <div className='flex flex-col w-full'>
                    <div className={`${open ? "ml-[228px]" : "ml-[90px]"}`}>
                        <Navbar />
                    </div>
                    {/* Page Content */}
                    <div className={`${open ? "ml-[228px]" : "ml-[90px]"} px-1 pt-24 mb-24`}>
                        {/* Your main content goes here */}
                        <Container>
                            <div className='flex justify-between py-6'>
                                <div>
                                    <div className="flex items-center">
                                        <Link to={'/'}>
                                            <h2 className="text-2xl font-bold text-heading-black">AI Hub</h2>
                                        </Link>
                                        <MdKeyboardArrowRight className='text-2xl' />
                                        <Link to='/uploadfiles'>
                                            <h2 className="text-2xl font-bold text-heading-black">Synthetic Data Generation</h2>
                                        </Link >
                                    </div>
                                    <span className="text-base font-normal text-gray-70">Mimicking real-world data patterns and structures, for effective training of AI models.</span>
                                </div>
                            </div>
                            {/* <Tabs /> */}
                            <div className='w-full bg-white'>
                                <div className='flex text-center justify-center border-b py-6'>
                                    <div>
                                        <div
                                            className={
                                                "rounded-full w-10 h-10 flex items-center justify-center m-auto ml-1.5" +
                                                (activeTab === 1 ? 'active bg-tab-active-color bg-opacity-5 text-tab-active-color' : 'text-tab-active-color')
                                            }
                                        >
                                            {activeTab === 2 ?
                                                <GoCheckCircle className='w-10 h-10  text-tab-active-color bg-tab-active-color bg-opacity-5 rounded-full' /> : <span>1</span>}
                                        </div>
                                        <span className='text-base font-medium'>Upload File</span>
                                    </div>
                                    <div className='w-32 border-t-2 border-dashed mt-5'></div>
                                    <div>
                                        <div
                                            className={
                                                "rounded-full w-10 h-10 flex items-center justify-center m-auto ml-1.5" +
                                                (activeTab === 2 ? 'active bg-tab-active-color bg-opacity-5 text-tab-active-color' : 'text-gray-60 bg-tab-not-active-color')
                                            }
                                        // onClick={() => handleTabClick(2)}
                                        >
                                            <span>2</span>
                                        </div>
                                        <span className='text-base font-medium'>Select Data</span>
                                    </div>
                                </div>
                                {/* Tabs upload files  */}
                                <div className={`tab-pane ${activeTab !== 2 ? 'active' : ''}`}>
                                    <div className='px-8 pt-6'>
                                        <span className='text-base font-medium text-secondry-clr'>
                                            Upload your CSV file to generate high quality synthetic data for your projects in minutes!
                                        </span>

                                        <div
                                            className='border-2 border-dashed rounded py-14 mt-6 mb-3 text-center'
                                            onDragOver={handleDragOver}
                                            onDrop={handleDrop}
                                        >
                                            <div className='text-secondry-clr font-medium text-base'>
                                                <label htmlFor="file-upload" className="cursor-pointer rounded-md py-2 px-4 inline-block underline">
                                                    Click to upload
                                                </label>
                                                <input id="file-upload" type="file" onChange={handleFileUpload} style={{ display: 'none' }} /><span className='-ml-3'>or drag & drop</span>
                                            </div>
                                            <span className='text-sm font-normal text-secondry-clr text-opacity-50'>Upload CSV file here or click inside the box</span>
                                            <p className='text-sm font-normal text-secondry-clr text-opacity-50'>Maximum file size 10MB</p>
                                        </div>

                                        {uploadedFiles.length > 0 && (
                                            <div className='grid grid-cols-4 gap-4'>
                                                {uploadedFiles.map((file, index) => (
                                                    <div className='p-4 w-96 rounded bg-page-bg-color' key={file}>
                                                        <div className='flex justify-between'>
                                                            <div className='flex gap-2'>
                                                                <DescriptionOutlined />
                                                                <span>{file.name.length > 20 ? file.name.slice(0, 17) + file.name.slice(-4) : file.name}</span>
                                                            </div>
                                                            <CloseOutlined className='cursor-pointer' onClick={() => removeFile(index)} />
                                                        </div>
                                                        <div className='flex py-1'>
                                                            <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2 dark:bg-gray-700">
                                                                <div className="bg-blue-600 h-2.5 rounded-full"></div>
                                                            </div>
                                                            <span>100%</span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                        <span className='text-base font-medium text-btn-not-hover-color'>
                                            We value your privacy. Uploaded data will be used solely for the purpose of generating synthetic data.
                                        </span>
                                    </div>
                                    <div className='text-center py-10'>
                                        {initLoading ?
                                            (<button className='loading-button w-[136px] h-[52px] mx-auto'>
                                                <ImSpinner3 className='text-xl mr-1 animate-spin' />
                                                Running
                                            </button>)
                                            : initialising ?
                                                (<button className='loading-button w-[136px] h-[52px] mx-auto'>
                                                    <ImSpinner3 className='text-xl mr-1 animate-spin' />
                                                    Initializing
                                                </button>)
                                                :
                                                (<button className={`classic-button w-[136px] h-[52px] mx-auto `} onClick={() => checkInstance()}>
                                                    Next
                                                </button>)
                                        }
                                        {
                                            initialising &&
                                            <p className='text-gen-color mt-4 text-[14px] font-[600]'>The Model instance is initializing, This will take 1 to 2 mins. Once the instance is active we will process your request</p>
                                        }
                                    </div>
                                </div>
                                {/* Tabs SelectData */}
                                <div className={`tab-pane ${activeTab === 2 ? 'active' : ''} px-8 pt-6`}>
                                    <div className='flex justify-between'>
                                        <div className='flex justify-between py-3'>
                                            <div className='text-base font-semibold'>
                                                Select columns of numerical data for generation.
                                            </div>
                                        </div>
                                        <MultiSelect
                                            options={options}
                                            value={selected}
                                            onChange={setSelected}
                                            labelledBy="Select"
                                            className='w-80'
                                            disabled={!options.length}
                                        />
                                        {/* <FormControl sx={{ my: 4, minWidth: 330 }} >
                                            <InputLabel id="demo-select-small-label">Select a column</InputLabel>
                                            <Select
                                                labelId="demo-select-small-label"
                                                id="demo-select-small"
                                                value={selected}
                                                label="Select a column"
                                                onChange={handleTargetColumn}
                                            >

                                                {options?.map((ele, index) => {
                                                    return (
                                                        <MenuItem key={index} value={ele.value}>{ele.label}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl> */}
                                    </div>

                                    {
                                        filesDataRes &&
                                        (
                                            <div className="overflow-x-auto">
                                                <table className="min-w-full">
                                                    <thead className='bg-table-bg-head-clr'>
                                                        <tr>
                                                            {Object.keys(filesDataRes).map((key) => (
                                                                <th className="py-2 px-4 text-left text-sm font-semibold text-secondry-clr" key={key}>{key}</th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {[...Array(10).keys()].map((index) => (
                                                            <tr key={index} className={index % 2 !== 0 && 'bg-page-bg-color bg-opacity-50'}>
                                                                {Object.keys(filesDataRes).map((column) => (
                                                                    <td className="py-2 px-4  text-sm font-normal text-secondry-clr" key={column}>{filesDataRes[column][index]}</td>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )
                                    }
                                    <div className='text-center py-10'>
                                        {/* <button
                                        className='py-4 px-6 text-secondry-clr text-opacity-80 border border-secondry-clr border-opacity-80 rounded-xl font-semibold text-lg'
                                        onClick={preditModel}
                                    >
                                        Proceed
                                    </button> */}
                                        <button
                                            className='hover:bg-[#8F27C11A] hover:border-[#8F27C10D] hover:text-[#8F27C1]  text-[#10001ACC]  rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-[30px] py-[10px]'
                                            onClick={() => checkInstance2()}
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                    <div className={`${open ? "ml-[228px]" : "ml-[90px]"}`}>
                        <Footer />
                    </div>
                </div>
            </div >
            {
                confrimationModal ? (
                    <>
                        <div
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                        >
                            <div className="relative my-6 mx-auto">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-6 border-b border-solid border-slate-200 rounded-t">
                                        <div>
                                            <h5 className="text-xl font-semibold">
                                                Confirmation
                                            </h5>
                                        </div>

                                        <button
                                            className=" background-transparent font-bold uppercase pt-1.5 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => setConfrimationModal(false)}
                                        >
                                            <MdClose className='w-5 h-5' />
                                        </button>

                                    </div>
                                    {/*body*/}
                                    <div className="relative p-6 flex-auto">
                                        <div className="text-xl font-medium text-black">
                                            <span>Are you sure you wanted to select "Amount" for data generation.</span>
                                        </div>
                                    </div>
                                    {/* Footer */}
                                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b gap-x-3">
                                        <button
                                            type="button"
                                            className={`bg-page-bg-color text-tab-active-color  text-base font-semibold px-12 py-3 rounded-full`}
                                            onClick={() => setConfrimationModal(false)}
                                        >
                                            No
                                        </button>
                                        <button
                                            type="button"
                                            className={`bg-gradient-pink-purple text-white text-base font-semibold px-12 py-3 rounded-full`}
                                        >
                                            Yes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null
            }

            {loading && (
                <div>
                    <div className="absolute inset-0 flex flex-col items-center justify-center z-[200]">
                        <img src={loader} alt="img_loader" className="spinner" />
                        <p className='font-medium text-lg text-white block'>Please wait while processing...</p>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </div>
            )}
        </>
    )
}
export default UploadFile
