export const config = {
    firebaseConfig: {
        apiKey: "AIzaSyAZBQoKKjBxlRFZ6rxjPdNGM8EduV7KkwI",
        authDomain: "qubrid-eb848.firebaseapp.com",
        projectId: "qubrid-eb848",
        storageBucket: "qubrid-eb848.appspot.com",
        messagingSenderId: "452064525463",
        appId: "1:452064525463:web:7662d8f0844186885b3dd2",
        measurementId: "G-8E2EYGE1M7"
    }
};