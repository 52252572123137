import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { FiEdit2 } from 'react-icons/fi'
import { MdClose } from 'react-icons/md'
import { toast } from 'react-toastify'

function Credit() {
    const BussinessapiURL = process.env.REACT_APP_BUSINESS_SERVICE_URL;
    const [editTeam, setEditTeam] = useState(false)
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        role: '',
        credit: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const [getTeamMemberRes, setGetTeamMemberRes] = useState();

    const hash = window.location.hash;
    useEffect(() => {
        const getTeamMember = () => {
            const token = localStorage.getItem("token");
            const headers = {
                'x-access-token': token,
                'Content-Type': 'application/json',
            };
            axios.get(`${BussinessapiURL}/get-team-members`, { headers })
                .then(response => {
                    console.log(response)
                    setGetTeamMemberRes(response?.data)
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
        if (hash === '#tab3') {
            // Call the API function
            getTeamMember();
        }
        getTeamMember();
    }, [BussinessapiURL, hash]);

    const editTeamMember = () => {
        const token = localStorage.getItem("token");
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        }

        const data = {
            team_member_email: formData.email,
            credits: formData.credit
        };

        axios.post(`${BussinessapiURL}/add_credits`, data, { headers })
            .then((response) => {
                setEditTeam(false)
                toast(response.data.message)
            })
            .catch((error) => {
                console.error(error);
                toast(error.response.data.message)
            });
    }

    const knowUser = (ele) => {
        setEditTeam(true)
        const preFilledData = {
            name: ele.username,
            email: ele.email,
            role: ele.role
        };
        setFormData(prevFormData => ({
            ...prevFormData,
            ...preFilledData
        }));
    }

    return (
        <>
            <div>
                <div className="flex space-x-3">
                    <div className="p-5 bg-page-bg-color bg-opacity-60 w-60">
                        <h2 className="text-sm font-normal text-heading-black text-opacity-80">Total Organization Budget</h2>
                        <span className="text-sm font-bold text-heading-black">$60,000</span>
                    </div>
                    <div className="p-5 bg-page-bg-color bg-opacity-60 w-60">
                        <h2 className="text-sm font-normal text-heading-black text-opacity-80">Remaining Budget</h2>
                        <span className="text-sm font-bold text-heading-black">$10,200</span>
                    </div>
                </div>

                <div className="my-6">
                    <div className='pb-3 pl-6'>
                        <span className='text-base font-semibold text-third-clr'>Team Member Payment ({getTeamMemberRes?.total_team_members})</span>
                    </div>

                    <table className="min-w-full">
                        <thead className='bg-table-bg-head-clr'>
                            <tr>
                                <th className="py-2 px-6 text-left text-sm font-semibold text-secondry-clr">Name</th>
                                <th className="py-2 px-4 text-left text-sm font-semibold text-secondry-clr">Email Id</th>
                                <th className="py-2 px-4 text-left text-sm font-semibold text-secondry-clr">Credit Assigned</th>
                                <th className="py-2 px-4 text-left text-sm font-semibold text-secondry-clr">Used Amount</th>
                                <th className="py-2 px-4 text-left text-sm font-semibold text-secondry-clr">Remaining Amount</th>
                                <th className="py-2 px-4 text-left text-sm font-semibold text-secondry-clr">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                getTeamMemberRes?.team_members?.map((ele, i) =>
                                    <tr key={i} className={i % 2 !== 0 && "bg-page-bg-color bg-opacity-50"}>
                                        <td className="py-2 px-4  text-sm font-normal text-secondry-clr">{ele?.username}</td>
                                        <td className="py-2 px-4  text-sm font-normal text-secondry-clr">{ele?.email}</td>
                                        <td className="py-2 px-4  text-sm font-normal text-secondry-clr">{ele?.total_credits}</td>
                                        <td className="py-2 px-4  text-sm font-normal text-secondry-clr">${ele?.user_credit}</td>
                                        <td className="py-2 px-4  text-sm font-normal text-secondry-clr">${ele?.credits_left}</td>
                                        <td className="py-2 px-4  text-sm font-normal text-secondry-clr">
                                            <button onClick={() => knowUser(ele)}>
                                                <FiEdit2 className='h-5 w-5' />
                                            </button>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            {
                editTeam ? (
                    <>
                        <div
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                        >
                            <div className="relative my-6 mx-auto w-1/2">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                        <div>
                                            <h5 className="text-xl font-semibold">
                                                Edit
                                            </h5>
                                        </div>

                                        <button
                                            className=" background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => setEditTeam(false)}
                                        >
                                            <MdClose className='w-5 h-5' />
                                        </button>

                                    </div>
                                    {/*body*/}
                                    <div className="relative p-6 flex-auto">
                                        <div className='flex justify-between gap-10'>
                                            <TextField
                                                fullWidth
                                                id="outlined-basic"
                                                label="Name"
                                                variant="outlined"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                disabled={formData.name !== ''}
                                            />
                                            <TextField
                                                fullWidth
                                                id="outlined-basic"
                                                label="Email"
                                                variant="outlined"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                disabled={formData.email !== ''}
                                            />
                                        </div>
                                        <div className='flex justify-between gap-10 mt-8'>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={formData.role}
                                                    label="Role"
                                                    name="role"
                                                    onChange={handleChange}
                                                    disabled={formData.role !== ''}
                                                >
                                                    <MenuItem value="Developer">Developer</MenuItem>
                                                    <MenuItem value="Data Manager">Data Manager</MenuItem>
                                                    <MenuItem value="AI ML Developer">AI ML Developer</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <TextField fullWidth
                                                id="outlined-basic"
                                                label="Credit"
                                                variant="outlined"
                                                name="credit"
                                                value={formData.credit}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    {/* Footer */}
                                    <div className="flex items-center justify-end p-6 rounded-b gap-x-5">
                                        <button onClick={() => setEditTeam(false)} className='py-4 px-6 text-secondry-clr text-opacity-80 border border-secondry-clr border-opacity-80 rounded-xl font-semibold text-lg'>
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className={`text-tab-active-color bg-tab-active-color bg-opacity-20 border-opacity-10 text-lg font-semibold  px-12 py-4 rounded-xl`}
                                            onClick={editTeamMember}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null
            }
        </>
    )
}

export default Credit